import './index.css';

import React, {useState} from 'react';

import axios from 'axios';

interface IFormData {
  fName: string;
  lName: string;
  patr: string;
  workPlace: string;
  phone: string;
  email: string;
}

const setExpert = async (data:any) => {
  try {
    const res = await axios.post('http://admin.cme-cpd.com.ua:1337/admin/api/setExpert',data);
  } catch (error) {
    console.log(error,'modal be expert error')
  }
}


function ExpertModal({setIsOpen}: {setIsOpen: any}) {
  const [formData, setFormData] = useState<IFormData>({
    fName: '',
    lName: '',
    patr: '',
    workPlace: '',
    phone: '',
    email: '',
  });

  const [isSent, setIsSent] = useState<boolean>(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSent(true);
    console.log(formData);
    setExpert(formData);
  };

  const handleChange = (e: any) => setFormData({...formData, [e.target.name]: e.target.value});

  return (
    <div className='exprtContainer'>
      <div className='exprt-bg' onClick={() => setIsOpen(false)}></div>
      <div className='exprtModal'>
        <button className='exprtClose' type='button' onClick={() => setIsOpen(false)}>
          &times;
        </button>
        {isSent ? (
          <div className='exprt-finish'>
            <h2>Ми з Вами зв’яжемось в найближчий час.</h2>
          </div>
        ) : (
          <form className='exprtForm' onSubmit={onSubmit}>
            <fieldset className='exprtField'>
              <label>Ім'я</label>
              <input name='fName' onChange={handleChange} value={formData.fName} type='text' required />
            </fieldset>
            <fieldset className='exprtField'>
              <label>Прізвище</label>
              <input name='lName' onChange={handleChange} value={formData.lName} type='text' required />
            </fieldset>
            <fieldset className='exprtField'>
              <label>По батькові</label>
              <input name='patr' onChange={handleChange} value={formData.patr} type='text' required />
            </fieldset>
            <fieldset className='exprtField'>
              <label>Місце роботи</label>
              <input
                name='workPlace'
                onChange={handleChange}
                value={formData.workPlace}
                type='text'
                required
              />
            </fieldset>
            <fieldset className='exprtField'>
              <label>Телефон</label>
              <input name='phone' onChange={handleChange} value={formData.phone} type='text' required/>
            </fieldset>
            <fieldset className='exprtField'>
              <label>Email</label>
              <input name='email' onChange={handleChange} value={formData.email} type='email' required/>
            </fieldset>
            <button className='exprtSubmit' type='submit'>
              Надіслати
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ExpertModal;
