import React, {useContext, useEffect, useState} from 'react';

import CourseDocs from 'components/pages/Courses/CourseLections/blocks/CourseDocs';
import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import CoursePlayer from 'components/pages/Courses/CourseLections/blocks/CoursePlayer';
import axios from 'axios';

const CourseContent: React.FC = () => {
  const [file, setFile] = useState(null);

  const {currentLection, handleContentWatched} = useContext(
    CourseLectionsContext
  );
  const {contentUrl, contentType} = currentLection!;
  const player = contentType === 'video' || contentType === 'audio';

  useEffect(() => {
    if (!player) {
      handleContentWatched();
    }
  }, []);

  useEffect(() => {
    async function getFile() {
      try {
        const res = await axios.post(
          `http://cme-cpd.com.ua:1337/admin/api/getDoc`,
          {
            //@ts-ignore
            id: currentLection.docs_id,
          }
        );
        //@ts-ignore
        console.log(res.data);
        //@ts-ignore
        setFile(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (currentLection !== null) getFile();
  }, [currentLection]);

  return player ? (
    <CoursePlayer
      url={contentUrl}
      onFinish={handleContentWatched}
      contentType={contentType}
    />
  ) : (
    //@ts-ignore
    <CourseDocs url={contentUrl === '' ? `${file}` : contentUrl} />
  );
};

export default CourseContent;
