import { injectable } from 'inversify';
import IAuthProvider from './IAuthProvider';

@injectable()
class MockAuthProvider implements IAuthProvider {
  public on(event: 'change', callback: (status: boolean) => void): void {
    return;
  }
  public async isAuthenticated(): Promise<boolean> {
    return false;
  }
}
export default MockAuthProvider;
