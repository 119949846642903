import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import FaqDto from '../../models/faq/FaqDto';
import Feedback from '../../models/faq/Feedback';
import IFaqProvider from './IFaqProvider';

@injectable()
export default class ApiFaqProvider implements IFaqProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  public async getAll(): Promise<FaqDto[]> {
    return this.apiClient.request('faq', 'getAll', null);
  }

  public async makeFeedback(feedback: Feedback): Promise<void> {
    const model = {
      ...feedback,
      message: feedback.question,
    };
    delete model.question;
    await this.apiClient.request('feedbacks', 'create', model);
  }
}
