import React, { useEffect, useRef, useState } from 'react';

import styles from './CoursePlayer.module.sass';

interface CoursePlayerProgressProps {
  duration: number;
  current: number;
  onSeek: (amount: number) => void;
}

const CoursePlayerProgress: React.FC<CoursePlayerProgressProps> = ({
  duration,
  current,
  onSeek,
}) => {
  const progressBlock = useRef<null | HTMLDivElement>(null);
  const wrapper = useRef<null | HTMLDivElement>(null);
  const [allowed, setAllowed] = useState<number>(current);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [seekPosition, setSeekPosition] = useState<number>(0);
  const currentPercentage = (current * 100) / duration;
  const allowedPercentage = (allowed * 100) / duration;

  const progressPosition = seeking ? seekPosition : currentPercentage;

  useEffect(() => {
    if (current > allowed) {
      setAllowed(current);
    }
  }, [current]);

  useEffect(() => {
    if (progressBlock.current && wrapper.current) {
      const leftEdge = wrapper.current.getBoundingClientRect().x;
      const wrapperWidth = wrapper.current.offsetWidth;
      const calculateSeekPosition = (e: MouseEvent) =>
        ((e.pageX - leftEdge) * 100) / wrapperWidth;

      const handleMouseDown = (e: MouseEvent) => {
        setSeeking(true);
        setSeekPosition(calculateSeekPosition(e));
      };
      const handleMouseMove = (e: MouseEvent) => {
        const amount = calculateSeekPosition(e);
        const currentSeekPosition =
          amount > allowedPercentage ? allowedPercentage : amount;
        setSeekPosition(currentSeekPosition);
      };
      const handleMouseUp = (e: MouseEvent) => {
        const amount = calculateSeekPosition(e);
        setSeekPosition(amount);
        onSeek((amount * duration) / 100);
        setSeeking(false);
      };

      progressBlock.current.addEventListener('mousedown', handleMouseDown);

      if (seeking) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      }

      return () => {
        progressBlock.current!.removeEventListener(
          'mousedown',
          handleMouseDown
        );
        if (seeking) {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        }
      };
    }
  }, [progressBlock, wrapper, seeking, allowedPercentage, onSeek]);

  return (
    <div className={styles.progress} ref={wrapper}>
      <div
        className={styles.allowed}
        style={{ width: `${allowedPercentage}%` }}
      />
      <div className={styles.played} style={{ width: `${progressPosition}%` }}>
        <div className={styles.playedCircle} ref={progressBlock} />
      </div>
    </div>
  );
};

export default CoursePlayerProgress;
