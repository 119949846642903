import IEventsProvider, {
  SIEventsProvider,
} from 'infrastructure/providers/EventsProvider/IEventsProvider';
import ItemInfo, {ItemInfoLayoutType} from 'components/shared/ItemInfo';
import React, {useContext, useEffect, useState} from 'react';

import ContentBlock from 'components/shared/ContentBlock';
import ContentPage from 'components/shared/ContentPage';
import EventDto from 'infrastructure/models/events/EventDto';
import EventItem from 'components/shared/EventItem';
import HTMLContent from 'components/shared/HTMLContent';
import LoadingContext from 'contexts/LoadingContext';
import MoreBlock from 'components/shared/MoreBlock';
import RegisterForm from 'components/pages/Events/EventsInner/RegisterForm';
import SeoContext from 'contexts/SeoContext';
import ShareButton from 'components/shared/ShareButton';
import TopBlock from 'components/shared/TopBlock';
import axios from 'axios';
import container from 'ioc';
import {match} from 'react-router';
import moment from 'moment';
import styles from './EventsInner.module.sass';
import useGetBySlug from 'hooks/useGetBySlug';

const provider = () => container.get<IEventsProvider>(SIEventsProvider);

const pickerLayout: ItemInfoLayoutType = {
  place: {label: 'Місце', editable: true},
  start: {label: 'Дата та час'},
  price: {label: 'Ціна'},
};

interface EventsProps {
  match: match<{slug: string}>;
}

const completeCheckout = async (courseId: any, userId: any, date: string) => {
  try {
    const res = await axios.post('http://admin.cme-cpd.com.ua:1337/admin/api/checkout', {
      courseId,
      userId,
      date,
    });

    console.log(res.status, 'checkout status');
  } catch (error) {
    console.log('error');
  }
};

const startCheckout = async (courseId: any, userId: any, date: string) => {
  try {
    const res = await axios.post('http://admin.cme-cpd.com.ua:1337/admin/api/startcheckout', {
      courseId,
      userId,
      date,
    });

    if (res.status === 200) completeCheckout(courseId, userId, date);

    console.log(res.status, 'checkout status');
  } catch (error) {
    console.log('error');
  }
};

const EventsInner: React.FC<EventsProps> = ({
  match: {
    params: {slug},
  },
}) => {
  const {value, loading} = useGetBySlug<EventDto>(SIEventsProvider, slug);
  const [registered, setRegistered] = useState<boolean>(false);
  const {setSeo} = useContext(SeoContext);

  const [registeredUser, setRegisteredUser] = useState([]);
  const [user, setUser] = useState({email: ''});

  const [isReg, setisReg] = useState(false);

  const [localLiked, setLocalLiked] = useState(
    //@ts-ignore
    value === null ? false : value.liked
  );

  const submit = () => {
    provider()
      //@ts-ignore
      .like(value.id, !localLiked)
      //@ts-ignore
      .then(() => setLocalLiked(!localLiked));
    provider()
      //@ts-ignore
      .register(value.id, {
        //@ts-ignore
        cityId: value.dates.map((date) => [date.place_id, date.place]),
        email: user.email,
        id: Math.floor(Math.random() * 9000),
        //@ts-ignore
        name: user.firstName,
        //@ts-ignore
        phone: user.phone,
        //@ts-ignore
        surname: user.lastName,
      })
      .then(() => setRegistered(true));
  };

  useEffect(() => {
    async function getRegistr() {
      try {
        const res = await axios.get('http://admin.cme-cpd.com.ua:1337/admin/api/getRegistered');

        setRegisteredUser(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    getRegistr();
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        const res = await axios.post('http://admin.cme-cpd.com.ua:1337/admin/api/getUser', {
          // @ts-ignore
          id: JSON.parse(localStorage.getItem('userId')),
        });

        setUser(res.data);
      } catch (error) {
        //@ts-ignore
        setUser({email: ''});
        console.log(error);
      }
    }

    getUser();
  }, []);

  useEffect(() => {
    if (value) {
      setSeo({
        title: value.seo?.title || value.title,
        description: value.seo?.description || value.description,
        image: value.image,
        type: 'event',
      });
    }
  }, [value]);

  return (
    <LoadingContext.Provider value={{loading}}>
      <ContentPage className={styles.eventsInner} backLink={{link: '/events', name: 'Події'}}>
        <TopBlock headline={value?.title} image={value?.image} className={styles.eventsInner__top}>
          {value?.dates && (
            <ItemInfo
              data={value.dates.map(({start, price = 'Безкоштовно', ...rest}) => ({
                ...rest,
                price,
                start: moment(start).format('DD-MM-YYYY'),
              }))}
              //@ts-ignore
              startCheckout={startCheckout}
              //@ts-ignore
              courseId={value.id}
              layout={pickerLayout}
            />
          )}
        </TopBlock>
        <div className={styles.eventsInner__about}>Про подію</div>
        <HTMLContent content={value?.description!} />
        {value?.lecturers && (
          <ContentBlock headline={'Лектори'} className={styles.eventsInner__lecturers}>
            <div className={styles.eventsInner__lecturersList}>
              {value.lecturers.map((lecturer) => {
                console.log(lecturer);
                return (
                  <div key={lecturer.id} className={styles.eventsInner__lecturer}>
                    <img
                      src={lecturer.image}
                      alt={'lecturer'}
                      className={styles.eventsInner__lecturerImg}
                    />
                    <div className={styles.eventsInner__lecturerName}>{lecturer.name} {lecturer.lastName}</div>
                    <div className={styles.eventsInner__lecturerDescription}>{lecturer.rank}</div>
                  </div>
                );
              })}
            </div>
          </ContentBlock>
        )}
        {value &&
          !registeredUser?.some(
            (reg: any) => reg.email === user.email && reg.eventId === value.id
          ) &&
          (user.email.length < 1 ? (
            <ContentBlock
              headline={'Зареєструватися на подію'}
              className={styles.eventsInner__form}
              text={registered ? 'Ви успішно зареєструвалися на подію' : undefined}
              textFull={true}
            >
              {!registered && (
                <RegisterForm id={value.id} dates={value.dates} handleRegistered={setRegistered} />
              )}
            </ContentBlock>
          ) : (
            <button
              onClick={() => {
                setisReg(true);
                submit();
              }}
              style={{
                padding: '10px 30px',
                color: '#fff',
                background: '#1E78ED',
                outline: 'none',
                borderRadius: '10px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {isReg ? 'Ви зареєстровані' : 'Зареєструватися на подію'}
            </button>
          ))}
        <ShareButton />
        <MoreBlock headline={'Куди ще сходити'}>
          {value?.relatedEvents.map((item) => (
            <EventItem event={item} key={item.id} />
          ))}
        </MoreBlock>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default EventsInner;
