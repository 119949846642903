import AlertsProvider from 'components/providers/AlertsProvider';
import SeoProvider from 'components/providers/SeoProvider';
import React from 'react';

const ContextProviders: React.FC = ({ children }) => {
  return (
    <SeoProvider>
      <AlertsProvider>{children}</AlertsProvider>
    </SeoProvider>
  );
};

export default ContextProviders;
