import { PayloadAction } from 'store/types';
import AuthActionTypes from './constants';

export interface AuthState {
  authorized: boolean;
}

const initialState = { authorized: false };

export default function auth(
  state: AuthState = initialState,
  action: PayloadAction
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOG_IN_DONE:
      return { authorized: true };
    case AuthActionTypes.LOG_OUT:
      return { authorized: false };
    default:
      return state;
  }
}
