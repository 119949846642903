import { injectable } from 'inversify';
import CourseListItemDto from '../../models/courses/CourseListItemDto';
import EventListItemDto from '../../models/events/EventListItemDto';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import ProfileDto from '../../models/profile/ProfileDto';
import IProfileProvider, {
  AvatarResponseDto,
  AvatarUploadDto,
  CreateCertificateDto,
  FileUploadDto,
  FileUploadResponseDto,
  SertificateDto,
  StandartDto,
  UserSettingsDto,
} from './IProfileProvider';

@injectable()
export default class MockProfileProvider implements IProfileProvider {
  public uploadCertificate(
    file: FileUploadDto
  ): Promise<FileUploadResponseDto> {
    throw new Error('Method not implemented.');
  }
  public saveCertificate(data: CreateCertificateDto): Promise<void> {
    throw new Error('Method not implemented.');
  }
  public async *getCourses(): AsyncGenerator<CourseListItemDto[]> {
    yield [];
    return;
  }

  public async *getNews(): AsyncGenerator<NewsListItemDto[]> {
    yield [];
    return;
  }

  public async *getSertificates(): AsyncGenerator<SertificateDto[]> {
    yield [];
    return;
  }

  public async *getStandarts(): AsyncGenerator<StandartDto[]> {
    yield [];
    return;
  }

  public async getUserInfo(): Promise<ProfileDto> {
    return {} as ProfileDto;
  }

  public async *getEvents(): AsyncGenerator<EventListItemDto[]> {
    yield [];
    return undefined;
  }

  public async getSettings(): Promise<UserSettingsDto> {
    return { main: {} };
  }

  public async saveSettings(
    category: string,
    data: Record<string, any>
  ): Promise<void> {
    return;
  }

  public async uploadAvatar(data: AvatarUploadDto): Promise<AvatarResponseDto> {
    return { avatarUrl: '' };
  }
}
