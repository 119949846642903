import Filters from 'components/pages/Courses/CoursesList/Filters';
import ContentPage from 'components/shared/ContentPage';
import CourseItem from 'components/shared/CourseItem';
import MultiPageList from 'components/shared/MultiPageList';
import LoadingContext from 'contexts/LoadingContext';
import SeoContext from 'contexts/SeoContext';
import useList, { ListModules } from 'hooks/useList';
import React, { useContext, useEffect } from 'react';

const CoursesList = () => {
  const {
    list: courses,
    goToPage,
    setFilters,
    filters,
    pages,
    page,
    loading,
  } = useList(ListModules.COURSES);
  const { setSeo } = useContext(SeoContext);
  useEffect(() => {
    setSeo({ title: 'Курси' });
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ContentPage
        headline={'Курси'}
        placeholder={false}
        filters={<Filters filters={filters} setFilters={setFilters} />}
      >
        <MultiPageList pages={pages} page={page} goToPage={goToPage}>
          {courses.map(course => (
            <CourseItem training={course} key={course.id} />
          ))}
        </MultiPageList>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default CoursesList;
