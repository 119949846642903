import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
} from 'react-share';

import { clns } from 'helpers/strings';

import styles from './ShareButton.module.sass';

interface ShareButtonProps {
  className?: string;
}

const buttons = [
  { button: FacebookShareButton, icon: FacebookIcon },
  { button: TwitterShareButton, icon: TwitterIcon },
  { button: TelegramShareButton, icon: TelegramIcon },
  { button: ViberShareButton, icon: ViberIcon },
  { button: EmailShareButton, icon: EmailIcon },
];

const ShareButton: React.FC<ShareButtonProps> = ({ className }) => {
  const extra = {
    size: 32,
    round: true,
  };

  return (
    <div className={clns([styles.share, className])}>
      {buttons.map(({ button: Button, icon: Icon }, i) => (
        <Button
          url={window.location.href}
          key={i}
          className={styles.share__item}
        >
          <Icon {...extra} />
        </Button>
      ))}
    </div>
  );
};

export default ShareButton;
