import { clns } from 'helpers/strings';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from 'store/modules/ui/actions';
import Burger from '../Burger';

import styles from './Modal.module.sass';

export interface ModalConsumerProps {
  visible: boolean;
}

interface ModalProps extends ModalConsumerProps {
  name: string;
  children?: any;
  image?: string;
}

const Modal: React.FC<ModalProps> = ({ image, children, name, visible }) => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(setModal(name, false));
  }, [name]);

  return (
    <div className={clns([styles.modal, visible && styles.modal_visible])}>
      <div className={styles.modal__bg} />
      <div className={styles.modal__body}>
        <Burger
          opened={visible}
          className={styles.modal__burger}
          onClick={handleClose}
        />
        <div className={styles.modal__left} onClick={handleClose}>
          {image && (
            <img src={image} alt={''} className={styles.modal__image} />
          )}
        </div>
        <div className={styles.modal__right}>
          <div className={styles.modal__content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
