import React from 'react';
import { Link } from 'react-router-dom';

import withPlaceholder from 'components/hocs/withPlaceholder';
import { clns } from 'helpers/strings';

import heart from 'assets/images/heart.svg';
import heartFull from 'assets/images/heart_full.svg';
import styles from './ListItem.module.sass';

interface ListItemProps {
  link: string;
  className?: string;
  placeholder?: boolean;
  action?: () => void;
  icons?: { empty: string; full: string };
  filled?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  className,
  children,
  link,
  placeholder,
  action,
  filled,
  icons = { empty: heart, full: heartFull },
}) => {
  return (
    <Link
      to={link}
      className={clns([
        styles.listItem,
        className,
        placeholder && styles.listItem_placeholder,
      ])}
    >
      {action && (
        <div
          className={styles.listItem__action}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            action();
          }}
        >
          <img src={filled ? icons?.full : icons?.empty} alt={''} />
        </div>
      )}
      {children}
    </Link>
  );
};

const Placeholder: React.FC<ListItemProps> = props => (
  <ListItem link={props.link} placeholder />
);

export default withPlaceholder(Placeholder, ListItem);
