import {
  EducationSignUpSchema,
  EducationSignUpValueModel,
} from 'schemas/SignUpSchema';
import React, {useEffect, useState} from 'react';

import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import {Link} from 'react-router-dom';
import Select from 'components/forms/Widgets/Select';
import {StepProps} from '../StepProps';
import {getSignUpData} from 'store/modules/signUp/selectors';
import pick from 'lodash/pick';
import {useSelector} from 'react-redux';

const Third: React.FC<StepProps> = ({onSubmit, onChange}) => {
  const [{value, errors}, setData] = useState(
    EducationSignUpSchema.toInternal(
      pick(
        useSelector(getSignUpData),
        Object.keys(EducationSignUpSchema.fields)
      ) as EducationSignUpValueModel
    )
  );

  useEffect(() => {
    onChange({value, errors});
  }, [value, errors]);

  return (
    <Form
      schema={EducationSignUpSchema}
      value={value}
      errors={errors}
      onChange={setData}
      onSubmit={onSubmit}
    >
      <Form.Row>
        <Select.Connected name={'occupation'} label={'Основна спеціалізація'} />
      </Form.Row>
      <Form.Row>
        <Select.Connected name={'university'} label={'Навчальний заклад'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'diploma'} label={'Номер диплома'} />
      </Form.Row>
      <Form.Row>
        <Select.Connected name={'endYear'} label={'Рік випуску з ВНЗ'} />
      </Form.Row>
      <div style={{marginTop: '10px', textAlign: 'center'}}>
        Натискаючи на кнопку "Зареєструватись", Ви погоджуєтесь з{' '}
        <Link to='/agreement.pdf' target='_blank' style={{color: '#3FA9F5'}}>
          угодою користувача
        </Link>
      </div>
      <Form.Button>Зареєструватися</Form.Button>
    </Form>
  );
};

export default Third;
