import {
  honorTitles,
  organizationsTypes,
  patentType,
  recommendationTypes,
  studiedLanguages,
  textbookTypes,
  year,
} from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const HonoraryTitleSchema = Schema.create({
  name: Fields.choice(dataJSON.honorTitles || honorTitles).required(),
  date: Fields.date().required(),
});

const PatentSchema = Schema.create({
  name: Fields.any().required(),
  authors: Fields.any().required(),
  type: Fields.choice(dataJSON.patentType || patentType).required(),
  number: Fields.any().required(),
  bulletinNumber: Fields.any().required(),
  date: Fields.date().required(),
});

export const ProfessionalTextbookSchema = Schema.create({
  name: Fields.any().required(),
  type: Fields.choice(dataJSON.textbookTypes || textbookTypes).required(),
  language: Fields.choice(dataJSON.studiedLanguages || studiedLanguages).required(),
});

export const MethodologicalRecommendationsSchema = Schema.create({
  name: Fields.any().required(),
  type: Fields.choice(dataJSON.recommendationTypes || recommendationTypes).required(),
  language: Fields.choice(dataJSON.studiedLanguages || studiedLanguages).required(),
});

export const OrganizationsSchema = Schema.create({
  type: Fields.choice(dataJSON.organizationsTypes || organizationsTypes).required(),
  name: Fields.any().required(),
  startYear: Fields.choice(year()).required(),
});

const ScientificAndInnovativeWorkSchema = Schema.create({
  honoraryTitles: Fields.array(HonoraryTitleSchema),
  patents: Fields.array(PatentSchema),
  textbooks: Fields.array(ProfessionalTextbookSchema),
  recommendations: Fields.array(MethodologicalRecommendationsSchema),
  organizations: Fields.array(OrganizationsSchema),
});

export default ScientificAndInnovativeWorkSchema;
