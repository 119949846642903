import About from './components/pages/About';
import Account from './components/pages/Account';
import AgreementPage from './components/AgreementPage';
import {ComponentType} from 'react';
import Contacts from './components/pages/Contacts';
import CourseLections from './components/pages/Courses/CourseLections';
import CoursePreview from './components/pages/Courses/CoursePreview';
import CoursesList from './components/pages/Courses/CoursesList';
import EventsInner from './components/pages/Events/EventsInner';
import EventsList from './components/pages/Events/EventsList';
import FAQ from './components/pages/FAQ';
import Home from './components/pages/Home';
import NewsInner from './components/pages/News/NewsInner';
import NewsList from './components/pages/News/NewsList';
import RegistPage from './components/RegistPage';
import Search from './components/pages/Search';

interface Route {
  url: string;
  exact: boolean;
  component: ComponentType<any>;
}

const ROUTES: Route[] = [
  {
    url: '/',
    exact: true,
    component: Home,
  },
  {
    url: '/confirm',
    exact: true,
    component: RegistPage,
  },
  {
    url: '/agreement',
    exact: true,
    component: AgreementPage,
  },
  {
    url: '/account',
    exact: false,
    component: Account,
  },
  {
    url: '/news',
    exact: true,
    component: NewsList,
  },
  {
    url: '/news/:slug',
    exact: true,
    component: NewsInner,
  },
  {
    url: '/events',
    exact: true,
    component: EventsList,
  },
  {
    url: '/events/:slug',
    exact: true,
    component: EventsInner,
  },
  {
    url: '/about',
    exact: true,
    component: About,
  },
  {
    url: '/courses',
    exact: true,
    component: CoursesList,
  },
  {
    url: '/courses/:slug',
    exact: true,
    component: CoursePreview,
  },
  {
    url: '/courses/:slug/testing',
    exact: true,
    component: CourseLections,
  },
  {
    url: '/faq',
    exact: true,
    component: FAQ,
  },
  {
    url: '/contacts',
    exact: true,
    component: Contacts,
  },
  {
    url: '/search',
    exact: true,
    component: Search,
  },
];

export default ROUTES;
