import useCourse, {
  StepTypes,
} from 'components/pages/Courses/CourseLections/state';

import React from 'react';

const CourseLectionsContext = React.createContext<any>({
  course: null,
  currentLection: null,
  setLection: () => {},
  handleContentWatched: () => {},
  handleTestPassed: () => {},
  handleStepChange: () => {},
  step: StepTypes.WATCHING,
  handleNext: () => {},
  finished: false,
  last: false,
});

export default CourseLectionsContext;
