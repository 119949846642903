import ForgotPassword from 'components/base/Modals/ForgotPassword';
import UpdatePassword from 'components/base/Modals/UpdatePassword';
import React, { ComponentType, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getModals } from 'store/modules/ui/selectors';

import AddCertificate from './AddCertificate';
import Login from './Login';
import Menu from './Menu';
import SignUp from './SignUp';

const modalsList: Record<string, ComponentType<any>> = {
  menu: Menu,
  login: Login,
  signUp: SignUp,
  addCertificate: AddCertificate,
  forgotPassword: ForgotPassword,
  updatePassword: UpdatePassword,
};

const Modals: React.FC = () => {
  const modals = useSelector(getModals);
  const parsed = useMemo(
    () =>
      Object.entries(modals).filter(
        ([name, { opened }]) => name in modalsList && opened
      ),
    [modals]
  );

  return (
    <>
      {parsed.map(([name, { visible }]) => {
        const Modal = modalsList[name];
        return <Modal visible={visible} key={name} />;
      })}
    </>
  );
};

export default Modals;
