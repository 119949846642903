import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-router-dom';

import { clns } from 'helpers/strings';

import arrow_back from '../../../assets/images/arrow_back.png';
import withPlaceholder from '../../hocs/withPlaceholder';
import styles from './ContentPage.module.sass';

interface ContentPageProps {
  headline?: string;
  backLink?: {
    link: string;
    name: string;
  };
  className?: string;
  fullWidthElements?: JSX.Element[];
  fullWidth?: boolean;
  placeholder?: boolean;
  filters?: JSX.Element;
}

const ContentPage: React.FC<ContentPageProps> = ({
  headline,
  className,
  children,
  backLink,
  fullWidthElements,
  fullWidth,
  placeholder,
  filters,
}) => {
  return (
    <div
      className={clns([
        styles.contentPage,
        placeholder && styles.contentPage_placeholder,
      ])}
    >
      <div className={styles.contentPage__head}>
        <Link
          to={get(backLink, 'link', '/')}
          className={styles.contentPage__link}
        >
          {!placeholder && (
            <>
              <img
                src={arrow_back}
                alt={'arrow back'}
                className={styles.contentPage__link_icon}
              />
              <div className={styles.contentPage__link_text}>
                {get(backLink, 'name', 'Головна')}
              </div>
            </>
          )}
        </Link>
        {(headline || placeholder) && (
          <div className={styles.contentPage__top}>
            <div className={styles.contentPage__headline}>
              {placeholder || headline}
            </div>
            <div className={styles.contentPage__filters}>{filters}</div>
          </div>
        )}
      </div>
      {fullWidthElements && (
        <div className={styles.contentPage__fullWidth}>{fullWidthElements}</div>
      )}
      <div
        className={clns([
          styles.contentPage__content,
          fullWidth && styles.contentPage__content_full,
          className,
        ])}
      >
        {children}
      </div>
    </div>
  );
};

const Placeholder: React.FC<ContentPageProps> = props => (
  <ContentPage placeholder {...props} />
);

export default withPlaceholder<ContentPageProps>(Placeholder, ContentPage);
