import IOptionsProvider, {
  SIOptionsProvider,
} from 'infrastructure/providers/OptionsProvider/IOptionsProvider';
import { universities, year } from 'constants/data';

import { DependingTypes } from '@project-m/schemas/dist/types';
import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';
import container from 'ioc';

const provider = () => container.get<IOptionsProvider>(SIOptionsProvider);

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

export interface MainSignUpValueModel {
  name: string;
  surname: string;
  patronymic: string;
  gender: string;
}

export const MainSignUpSignSchema = Schema.create<MainSignUpValueModel>({
  name: Fields.text().required(),
  surname: Fields.text().required(),
  patronymic: Fields.text().required(),
  gender: Fields.choice([
    ['male', 'Чоловік'],
    ['female', 'Жінка'],
  ]).required(),
});

export interface SecondarySignUpValueModel {
  city: string;
  email: string;
  password: string;
  phone:string;
  repeatPassword: string;
}

export const SecondarySignUpSchema = Schema.create<SecondarySignUpValueModel>({
  city: Fields.choice([], () => provider().getCities()),
  email: Fields.email().required(),
  phone:Fields.phone().required(),
  password: Fields.password().required(),
  repeatPassword: Fields.password()
    .required()
    .depending('password', DependingTypes.EQUALS, 'Паролі не співпадають'),
});

export interface EducationSignUpValueModel {
  occupation: string;
  university: string;
  diploma: string;
  endYear: number;
}

export const EducationSignUpSchema = Schema.create<EducationSignUpValueModel>({
  occupation: Fields.choice([], () => provider().getSpecialities()).required(),
  university: Fields.choice(dataJSON.universities || universities),
  diploma: Fields.any(),
  endYear: Fields.choice(year(true)),
});

export type SignUpValueModel = MainSignUpValueModel &
  SecondarySignUpValueModel &
  EducationSignUpValueModel;
