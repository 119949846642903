import React from 'react';
import LoadingContext from '../../../../../contexts/LoadingContext';
import useLoadMore from '../../../../../hooks/useLoadMore';
import EventListItemDto from '../../../../../infrastructure/models/events/EventListItemDto';
import IProfileProvider, {
  SIProfileProvider,
} from '../../../../../infrastructure/providers/ProfileProvider/IProfileProvider';
import container from '../../../../../ioc';

import EventItem from '../../../../shared/EventItem';
import ListView from '../../../../shared/ListView';

import styles from './Later.module.sass';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const Later = () => {
  const { data: events, canLoadMore, loading, loadMore, empty } = useLoadMore<
    EventListItemDto
  >(() => provider().getEvents());

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ListView
        className={styles.later}
        canLoadMore={canLoadMore}
        loading={loading}
        onMore={loadMore}
        empty={empty}
      >
        {events.map(event => (
          <EventItem event={event} key={event.id} />
        ))}
      </ListView>
    </LoadingContext.Provider>
  );
};

export default Later;
