import { EffectCallback, useEffect, useRef } from 'react';

export default function useDidUpdate(fn: EffectCallback, deps: any[]) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      return fn();
    }

    didMount.current = true;
  }, deps);
}
