import {CourseFilters, LectionDto} from '../../models/courses/common';
import IApiClient, {SIApiClient} from '../../ApiClient/IApiClient';
import ICourseProvider, {
  AddResult,
  PreparedCoursesFilters,
} from './ICourseProvider';
import {inject, injectable} from 'inversify';

import CourseListItemDto from '../../models/courses/CourseListItemDto';
import CoursePreviewDto from '../../models/courses/CoursePreviewDto';
import CourseTrainingDto from '../../models/courses/CourseTrainingDto';
import {Id} from '@project-m/core';
import ListResponse from 'infrastructure/models/common/ListResponse';

@injectable()
export default class ApiCourseProvider implements ICourseProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  public async answer(
    courseId: string,
    lectionId: string,
    questionId: string,
    answerId: string
  ): Promise<boolean> {
    return this.apiClient.request('courses', 'answer', {
      courseId,
      lectionId,
      questionId,
      answerId,
    });
  }

  public async getList(
    filters: PreparedCoursesFilters
  ): Promise<ListResponse<CourseListItemDto>> {
    const {page, count, ...realFilters} = filters;
    return this.apiClient.request('courses', 'getList', {
      ...realFilters,
      page,
      count: filters.count || 9,
    });
  }

  public getPreview(slug: string): Promise<CoursePreviewDto> {
    return this.apiClient.request('courses', 'getPreview', slug);
  }

  public async getTraining(slug: string): Promise<any> {
    return this.apiClient.request('courses', 'getTraining', slug);
  }

  public async getLection(courseId: string, id: string): Promise<any> {
    return this.apiClient.request('courses', 'getLecture', {
      courseId,
      lectionId: id,
    });
  }

  public add(id: Id): Promise<AddResult> {
    return this.apiClient.request('courses', 'apply', id);
  }

  public testComplete(courseId: string, lectionId: string): Promise<void> {
    return this.apiClient.request('courses', 'completeTest', {
      courseId,
      lectionId,
    });
  }

  public videoWatched(courseId: string, lectionId: string): Promise<void> {
    return this.apiClient.request('courses', 'lectureWatched', {
      courseId,
      lectionId,
    });
  }
}
