import Schema from '@project-m/schemas';
import { BaseField } from '@project-m/schemas/dist/fields';
import { SchemaDataType, SchemaErrorType } from '@project-m/schemas/dist/types';
import React from 'react';

interface FormContextModel {
  value: Record<string, any>;
  errors: SchemaErrorType;
  onChange: (name: string, value: any) => void;
  onUpdate: (name: string, value: SchemaDataType<any>) => void;
  onSubmit: () => void;
  fields: Record<string, BaseField | Schema>;
}

const FormContext = React.createContext<FormContextModel>({
  value: {},
  errors: null,
  onChange: () => {},
  onUpdate: () => {},
  onSubmit: () => {},
  fields: {},
});

export default FormContext;
