import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../../../../store/modules/profile/selectors';

import styles from './Dashboard.module.sass';

const Dashboard = () => {
  const user = useSelector(profileSelector);

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboard__column}>
        <div className={styles.dashboard__value}>{user && user.balance}</div>
        <div className={styles.dashboard__label}>Баланс</div>
      </div>
      <div className={styles.dashboard__column}>
        <div className={styles.dashboard__value}>{user && user.points}</div>
        <div className={styles.dashboard__label}>Бали</div>
      </div>
      <div className={styles.dashboard__column}>
        <div className={styles.dashboard__value}>{user && user.pointsLeft}</div>
        <div className={styles.dashboard__label}>Залишилось</div>
      </div>
    </div>
  );
};

export default Dashboard;
