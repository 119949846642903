import 'moment/locale/ru'

import * as staticData from 'constants/data';

import IProfileProvider, {
  SIProfileProvider,
  SertificateDto,
} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import React,{useEffect, useState} from 'react'

import container from 'ioc';
import { getValueByKeyFromTuple } from 'helpers/arrays';
import moment from 'moment'

// бля сука ебаный в рот я не знаю как это еще описать, ты будешь делать правки которых в тз нет
// Она будет из пальца выдавливать и искать место куда можно помеж строк запихунть
// Что то новое чего нет, просто потому что она хочет, и не будет доплаты
// Удачи братан если собираешься делать :))))) 
// Keep it up,pal !! It's all gonna be alright :D

//@ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const generateAttestatCUKAKAKSHEDOEBALA = () => {
  let objFra = document.createElement('iframe');
  objFra.style.visibility = 'hidden';
  objFra.src = 'http://cme-cpd.com.ua/pdf/attest.pdf';
  document.body.appendChild(objFra);
  //@ts-ignore
  objFra.contentWindow.focus();    
  //@ts-ignore
  objFra.contentWindow.print();      
}

const certificate = () => {
  let objFra = document.createElement('iframe');
  objFra.style.visibility = 'hidden';
  objFra.src = 'http://cme-cpd.com.ua/pdf/certificate.pdf';
  document.body.appendChild(objFra);
  //@ts-ignore
  objFra.contentWindow.focus();    
  //@ts-ignore
  objFra.contentWindow.print();      
}

const posvi = () => {
  let objFra = document.createElement('iframe');
  objFra.src = 'http://cme-cpd.com.ua/pdf/pocvid.pdf';
  objFra.style.visibility = 'hidden';
  document.body.appendChild(objFra);
  //@ts-ignore
  objFra.contentWindow.focus();    
  //@ts-ignore
  objFra.contentWindow.print();
}
const provider = () => container.get<IProfileProvider>(SIProfileProvider);

// @ts-ignore
function Modal({toPrint,degree,education,onClose,startYear,placeOfWork,courses}) {
    const [course,setCourse] = useState({
        // num: '',
        model:'',
        place: '',
        date:'',
        points:''
    })
    const [certificates,setCertificates] = useState([]);
    const [formData, setFormData] = useState({
        name: `${toPrint.surname} ${toPrint.name} ${toPrint.patronymic}`,
        workPlace: placeOfWork || 'Не вказано',
        boss:'',
        rank: `${toPrint.placesOfWork[0] !== undefined ? toPrint.placesOfWork[0].position : ''} ${toPrint.specialization[0] !== undefined ? toPrint.specialization[0].position : 'Не вказано'}`,
        courses: courses.length !== 0 ? courses.map((c:any) => ({model: c.title,date: c.date, place:'Онлайн',points: c.points === null ? '-' : c.points})) : [],
        info: `
        ${getValueByKeyFromTuple(degree, dataJSON.scienceDegree || staticData.scienceDegree) || 'Не вказано'}

        Рік початку лікарської кар'єри: ${startYear || 'Не вказано'}

        ${education.languages
          ?.map(
            (lang: any) =>`${getValueByKeyFromTuple(lang.language,dataJSON.studiedLanguages || staticData.studiedLanguages)}: ${getValueByKeyFromTuple(lang.level, dataJSON.languageLevel || staticData.languageLevel)}`
        )
        .join(', ') || 'Не вказано' }

        ${toPrint.conductedLecturesForMedics.map((d:any) => `${d.name},${d.place}`)},

        ${toPrint.conductedLecturesForMedics.length !== 0 ? toPrint.conductedLecturesForMedics.map((d:any) => `${d.name},${d.place}`) : ''}

        ${toPrint.conductedLecturesForPeople.length !== 0 ? toPrint.conductedLecturesForPeople.map((d:any)=> `${d.name},${d.place},${d.hours} годин`) : ''} 

        ${toPrint.organizations.length !== 0 ? toPrint.organizations.map((d:any)=> `${d.name},${d.startYear},${d.type}`) : ''}

        ${toPrint.conference.length !== 0 ? toPrint.conference.map((d:any)=> `${d.category},${d.city},${d.hours} годин,${d.name},${d.reportTitle},Бали: ${d.points}`) : ''}

        ${toPrint.publications.length !== 0 ? toPrint.publications.map((d:any)=> `${d.description},${d.year} рiк,мова: ${d.language},${d.type} `) : ''} 
        
        ${toPrint.remote.length !== 0 ? toPrint.remote.map((d:any)=> `${d.name},${d.resourceName},мова: ${d.language}, ${d.hours} годин, Бали: ${d.points}`) : ''} 
        
        ${toPrint.scienceDegree.length !== 0 ? toPrint.scienceDegree.map((d:any)=> `${d.degree}, ${d.thesisTopic}, ${d.protectionPlace}, ${d.specialization}, Номер диплома: ${d.diploma}`) : ''} 
        
        ${toPrint.trainings.length !== 0 ? toPrint.trainings.map((d:any)=> `${d.type}, ${d.country},Днiв: ${d.dayCount}, ${d.name}, ${d.place}`) : ''} 
        `,
      });
    
    const handleChange = (e: any) => setFormData({...formData, [e.target.name]: e.target.value});
    
    const handleCourseChange = (e: any) => setCourse({...course, [e.target.name]: e.target.value});

    useEffect(() => {
      provider()
        .getSertificates()
        .next()
        .then((x) => setCertificates(x.value || []));
    }, []);
    console.log(certificates)

    return (
        <>
        <div className='exprtContainer'>
        <div className='exprt-bg' onClick={() => onClose(false)}></div>
        <div className='exprtModal' style={{width:'85vw',maxWidth: 'none'}}>
          <button className='exprtClose' type='button' style={{background:'#fff'}} onClick={() => onClose(false)}>
            &times;
          </button>
            <div className='exprtForm' style={{width: '100%',padding:"5px",overflowY:'auto',height: '100%'}}>
              <fieldset className='exprtField'>
                <label>Прізвище, ім'я, по батькові:</label>
                <input name='name' onChange={handleChange} value={formData.name} type='text'  />
              </fieldset>
              <fieldset className='exprtField'>
                <label>Місце роботи:</label>
                <input name='workPlace' onChange={handleChange} value={formData.workPlace} type='text'  />
              </fieldset>
              <fieldset className='exprtField'>
                <label>Займана посада</label>
                <input name='rank' onChange={handleChange} value={formData.rank.trim() == "undefined" ? 'Не вказано' : formData.rank}  type='text'  />
              </fieldset>
              <fieldset className='exprtField'>
                <label>Керівнік закладу охорони здоров'я</label>
                <input name='boss' onChange={handleChange} value={formData.boss}  type='text'  />
              </fieldset>
              <div style={{margin:'20px 0'}}>
              <h3>Додати інформацію про пройдені курси:</h3>
              <fieldset className='exprtField' style={{display:'flex',flexFlow:"row wrap"}}>
                {/* <label>З/П</label>
                <input name='num' onChange={handleCourseChange} value={course.num} type='text'  /> */}
                <label>Вид діяльності</label>
                <input name='model' onChange={handleCourseChange} value={course.model} type='text'  />
                <label>Дата/тривалість</label>
                <input name='date' onChange={handleCourseChange} value={course.date} type='text'  />
                <label>Місце проведення</label>
                <input name='place' onChange={handleCourseChange} value={course.place} type='text'  />
                <label>Кількість балів</label>
                <input name='points' onChange={handleCourseChange} value={course.points} type='text'  />
              </fieldset>
            
              <button type='button' style={{
                borderRadius: "30px",
                cursor: "pointer",
                fontFamily: "inherit",
                transition: "all .5s",
                padding: "10px 32px",
                fontSize: "14px",
                background: "#1e78ed",
                color: "#fff",
                marginLeft: "30px",
                border:'none'
              }} onClick={() => {
                    {/* @ts-ignore */}
                  setFormData({...formData,courses: [...formData.courses,course] })
                  setCourse({
                    // num: '',
                    model:'',
                    place: '',
                    date:'',
                    points:''
                })
              }}>Додати курс</button>
              </div>
              <fieldset className='exprtField'>
                <label>Відомості щодо персональних даних:</label>
                <textarea
                  style={{width: '100%',padding: '5px',height: '300px'}}
                  name='info'
                  onChange={handleChange}
                  value={formData.info}
                />
              </fieldset>
              <button className='exprtSubmit' type='submit' onClick={() => window.print()}>
                Згенерувати портфолію
              </button>
              <button className='exprtSubmit' type='submit' onClick={() => certificate()}>
               Згенерувати СЕРТИФІКАТ ЛІКАРЯ-СПЕЦІАЛІСТА
              </button>
              <button className='exprtSubmit' type='submit' onClick={() => generateAttestatCUKAKAKSHEDOEBALA()}>
                Згенерувати АТЕСТАЦІЙНИЙ ЛИСТОК
              </button>
              <button className='exprtSubmit' type='submit' onClick={() => posvi()}>
                Згенерувати ПОСВІДЧЕННЯ 
              </button>
            </div>
        </div>
      </div>
        <article className='portfolio'>
        <div className='portfolio__title' >ОСОБИСТЕ ОСВІТНЄ ПОРТФОЛІО З РЕЗУЛЬТАТАМИ ПРОХОДЖЕННЯ БЕЗПЕРЕРВНОГО ПРОФЕСІЙНОГО РОЗВИТКУ</div>
        <div className='portfolio__year' style={{margin:'20px 0'}}>за <span>2020</span> рік </div>
        {/* @ts-ignore */}
        <div className='portfolio__name' style={{marginBottom:'20px'}}>Призвіще, ім'я, по батькові: {formData.name}</div>
        {/* @ts-ignore */}
        <div className='portfolio__workPlace' style={{marginBottom:'20px'}}>Місце роботи: {formData.workPlace}</div>
        {/* <div className='portfolio__work' style={{marginBottom:'5px'}}>{formData.workPlace}</div> */}
         {/* @ts-ignore */}
        <div className='portfolio__workTitle'>Займана посада: {formData.rank.trim() == "undefined" ? 'Не вказано' : formData.rank}</div>
        {/* <div className='portfolio__work' style={{marginBottom:'20px'}}></div> */}
        <table>
            <tr>
                {/* <th>№<br/>З/П</th> */}
                <th>Вид діяльності</th>
                <th>Дата/тривалість</th>
                <th>Місце проведення</th>
                <th>Кількість балів</th>
            </tr>

                {formData.courses.map((d:any,i:number) => {
                     {/* @ts-ignore */}
                return <tr key={i}>{Object.values(d).map((v,i) => <td key={i}>{v}</td> )}</tr>
                })}

              {certificates.map((d:any,i:number) => {
                console.log(moment(d.createdAt).format('LL'))
                     {/* @ts-ignore */}
                return <tr key={i}>
                          <td key={i}>{d.name}</td> 
                          <td key={i}>{moment(d.createdAt).format('LL')}</td>
                          <td key={i}>Онлайн</td>
                          <td key={i}>{d.points}</td>
                        </tr>
                })}
         
        </table>
        <div className='portfolio__info' style={{margin:'20px 0'}}>Відомості відносно персональних досягнень: </div>
        <div className='portfolio__infoRow'>
            {formData.info}
        </div>
        <div className='portfolio__name'><span>{formData.name}</span> <span className='pidpic'>_____</span></div>
        <div className='portfolio__name' style={{marginBottom:'10px'}}>(Призвіще, ім'я, по батькові) </div>
        {/* <div className='portfolio__name'>Директор ДУ «ННЦ «Інститут кардіології ім. акад. М.Д. Стражеска» НАМН, академік НАМН України, професор </div> */}
              <div className='portfolio__name'><span>{formData.boss.length !== 0? formData.boss : '_________'}{/*В.М.Коваленко________*/}</span> <span className='pidpic'>_____</span></div>
        <div className='portfolio__name' style={{marginBottom:'5px'}}>(керівнік закладу охорони здоров'я) </div>
        <div className='portfolio__footer'>М. П.</div>
    </article>
    </>
    )
}

export default Modal
