import IProfileProvider, {
  CreateCertificateDto,
  SIProfileProvider,
} from '../../../../infrastructure/providers/ProfileProvider/IProfileProvider';
import Modal, { ModalConsumerProps } from 'components/shared/Modal';
import React, { useCallback, useState } from 'react';

import AddCertificateSchema from 'schemas/AddCertificateSchema';
import DateInput from 'components/forms/Widgets/DateInput';
import FileField from 'components/forms/Widgets/FileField';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import { SchemaDataType } from '@project-m/schemas/dist/types';
import container from '../../../../ioc';
import moment from 'moment'
import styles from './AddCertificate.module.sass';

type UploaderType = (file: File) => Promise<number>;

const provider = () => container.get<IProfileProvider>(SIProfileProvider);
const uploader: UploaderType = (file: File) =>
  provider()
    .uploadCertificate({ file })
    .then(x => (x.id as unknown) as number);

const mapCertificateDto = (data: any): CreateCertificateDto => ({
  certificate: data.certificate[0],
  date: data.date,
  name: data.name,
  points: Number(data.points),
});

const AddCertificate: React.FC<ModalConsumerProps> = ({ visible }) => {
  const [{ value, errors }, setData] = useState<SchemaDataType<any>>(
    AddCertificateSchema.generateEmpty()
  );
  const [saved, setSaved] = useState<boolean>(false);
  const [wrongDate,setWrongDate] = useState(false);

  const handleSubmit = useCallback(() => {

    if(new Date(value.date).getFullYear() != new Date().getFullYear()) {
      setWrongDate(true);
      setTimeout(() => setWrongDate(false), 3000);
    } else {
      provider()
        .saveCertificate(mapCertificateDto(value))
        .then(x => setSaved(true)); // probably you want to update current certificates after it
    }
  }, [value]);

  return (
    <Modal name={'addCertificate'} visible={visible}>
      <div className={styles.wrapper}>
        <div className={styles.headline}>
          {saved ? 'Сертифікат успішно збережено' : 'Додати сертифікат'}
        </div>
        {!saved && (
          <Form
            schema={AddCertificateSchema}
            value={value}
            errors={errors}
            onChange={setData}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Input.Connected name={'name'} label={'Назва'} />
            </Form.Row>
            <Form.Row>
              <DateInput.Connected name={'date'} label={'Дата отримання'} />
            </Form.Row>
            <Form.Row>
              <Input.Connected name={'points'} label={'Нараховані бали'} />
            </Form.Row>
            <Form.Row>
              <FileField.Connected
                name={'certificate'}
                uploader={uploader}
                label={'Сертифікат'}
              />
            </Form.Row>
            <Form.Button />
          </Form>
        )}
                {wrongDate && <div style={{color:'#FC6668',textAlign:'center',marginTop:'40px',fontSize:'22px'}}>
        Сертифікат повинен бути цього року
          </div>}
      </div>
    </Modal>
  );
};

export default AddCertificate;
