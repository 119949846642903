import IPageDataProvider, {
  SIPageDataProvider,
} from 'infrastructure/providers/PageDataProvider/IPageDataProvider';
import { PageBlocks, TopPageBlock } from 'infrastructure/models/pages/PageData';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActionsBlock from './blocks/ActionsBlock';
import CountBlock from '../../shared/CountBlock';
import EventsBlock from './blocks/EventsBlock';
import HomeTopBlock from './blocks/HomeTopBlock';
import NewsBlock from './blocks/NewsBlock';
import { Redirect } from 'react-router';
import SeoContext from 'contexts/SeoContext';
import VideoBlock from './blocks/VideoBlock';
import container from 'ioc';
import { isAuthorized } from 'store/modules/auth/selectors';
import { setLoader } from 'store/modules/ui/actions';

const blocksComponents: Record<string, any> = {
  video: VideoBlock,
  news: NewsBlock,
  events: EventsBlock,
  register: ActionsBlock,
};

const parseBlock = (
  block: PageBlocks,
  currentNumber: number,
  quantity: number
) => {
  if (block.type === 'top') {
    return <HomeTopBlock {...(block as TopPageBlock)} key={currentNumber} />;
  }

  if (block.type === 'count') {
    return (
      <CountBlock
        currentNumber={currentNumber}
        quantity={quantity - 1}
        key={currentNumber}
        padding
        {...block}
      />
    );
  }

  const CurrentBlock = blocksComponents[block.type];

  return (
    <CountBlock
      currentNumber={currentNumber}
      quantity={quantity - 1}
      key={currentNumber}
      padding
      {...block}
    >
      <CurrentBlock {...block} />
    </CountBlock>
  );
};

const provider = () => container.get<IPageDataProvider>(SIPageDataProvider);

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const authorized = useSelector(isAuthorized);
  const [blocks, setBlocks] = useState<any[]>([]);
  const { setSeo } = useContext(SeoContext);

  useEffect(() => {
    dispatch(setLoader('global', true));
    provider()
    .get('/')
    .then(data => {
      setSeo({ title: 'Головна' });
      setBlocks(data.blocks);
    })
    .finally(() => dispatch(setLoader('global', false)));
    //                 if (!authorized) {
    // }
  }, []);

  // if (authorized) {
  //   return <Redirect to={'/account'} />;
  // }

  return <>{blocks.map((block, i) => parseBlock(block, i, blocks.length))}</>;
};

export default Home;
