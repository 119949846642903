import { inject, injectable } from 'inversify';
import IAuthNotifiable, {
  SIAuthNotifiable,
} from '../UserProvider/IAuthNotifiable';
import IInitializationProvider from './IInitializationProvider';

@injectable()
export class ApiInitializationProvider implements IInitializationProvider {
  @inject(SIAuthNotifiable) private authObserver!: IAuthNotifiable;

  public async init(): Promise<void> {
    setTimeout(() => this.checkAuth(), 20); // bad hack
    // setTimeout is needed as auth listener will apply only after initialization
  }

  private checkAuth() {
    const tokens = window.localStorage.getItem('auth');
    if (tokens && tokens !== '') {
      this.authObserver.notify(true);
    }
  }
}
