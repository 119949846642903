import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';
import range from 'lodash/range';

export const standarts = [
  {
    name: 'Стандарты IBM Agile Explorer',
    description: 'Описание стандартов IBM',
    date: '20.12.2018',
    tags: ['Педиатрия', 'Терапия'],
  },
  {
    name: 'Стандарты IBM Agile Explorer',
    description: 'Описание стандартов IBM',
    date: '20.12.2018',
    tags: ['Педиатрия', 'Терапия'],
  },
  {
    name: 'Стандарты IBM Agile Explorer',
    description: 'Описание стандартов IBM',
    date: '20.12.2018',
    tags: ['Педиатрия', 'Терапия'],
  },
];


export const region:ChoicesType =  [
  [1,'Киевська Область']
]

export const district:ChoicesType =  [
  [1,'Печерский']
]

export const city:ChoicesType =  [
  [1,'Киев']
]

export const universities: ChoicesType = [
  [5, 'Буковинський державний медичний університет (БДМУ)'],
  [6, 'Вінницький національний медичний університет ім. М. І. Пирогова (ВНМУ)'],
  [7, 'Дніпровський національний університет імені Олеся Гончара (ДНУ)'],
  [8, 'Дніпропетровська державна медична академія (ДДМА)'],
  [9, 'Дніпропетровський державний аграрно-економічний університет (ДДАЕУ)'],
  [10, 'Донецький національний медичний університет ім. М. Горького'],
  [11, 'Запорізький державний медичний університет (ЗДМУ)'],
  [12, 'Івано-Франківський національний медичний університет (ІФДМУ)'],
  [1, 'Київський медичний університет'],
  [3, 'Київський міжнародний університет (КиМУ) (Київ)'],
  [
    13,
    'Кримський державний медичний університет ім. С. І. Георгіївського (КДМУ)',
  ],
  [14, 'Луганський державний медичний університет (ЛДМУ)'],
  [
    15,
    'Львівський інститут медсестринства та лабораторної медицини ім. Андрея Крупинського',
  ],
  [16, 'Львівський медичний інститут (ЛМІ)'],
  [
    17,
    'Львівський національний медичний університет імені Данила Галицького (ЛНМУ)',
  ],
  [18, 'Львівський національний університет імені Івана Франка (ЛНУ)'],
  [19, 'Медико-Природничій Університет'],
  [40, 'Медичний інститут Сумського державного університету'],
  [20, 'Міжнародна академія екології та медицини (МАЕМ)'],
  [21, 'Міжнародний гуманітарний університет'],
  [2, 'Міжнародний класичний університет ім. Пилипа Орлика'],
  [4, 'Міжрегіональна Академія управління персоналом (МАУП)'],
  [22, 'Національна медична академія післядипломної освіти імені П.Л. Шупика'],
  [23, 'Національний медичний університет імені О.О. Богомольця (НМУ)'],
  [24, 'Національний університет «Острозька академія»'],
  [25, 'Національний фармацевтичний університет (НФаУ)'],
  [26, 'Одеський національний медичний університет (ОНМедУ)'],
  [27, 'Одеський регіональний інститут державного управління (ОРІДУ)'],
  [28, 'Подільський державний аграрно-технічний університет (ПДАТУ)'],
  [29, 'Сумський національний аграрний університет (СНАУ)'],
  [
    30,
    'Тернопільський державний медичний університет ім. І. Я. Горбачевського (ТДМУ)',
  ],
  [31, 'Ужгородський національний університет (УжНУ)'],
  [32, 'Українська медична стоматологічна академія'],
  [33, 'Харківський національний медичний університет (ХНМУ)'],
  [34, 'Харківський національний університет імені В.Н. Каразіна'],
  [35, 'Хмельницький національний університет (ХНУ)'],
  [36, 'Хортицька національна навчально-реабілітаційна академія'],
  [37, 'Чернігівський національний технологічний університет'],
  [38, 'Чорноморський національний університет імені Петра Могили'],
  [39, 'Інший заклад'],
];

export const countries: ChoicesType = [
  ['ukraine', 'в Україні'],
  ['other', 'в інших країнах'],
];

export const year = (now: boolean = false): ChoicesType => {
  const currentYear = new Date(new Date().setFullYear(2040)).getFullYear();
  const years = range(140).map(item => [
    currentYear - item,
    String(currentYear - item),
  ]);

  return (now ? [['now', 'Поточний час'], ...years] : years) as ChoicesType;
};

export const educationType: ChoicesType = [
  [1, 'магістратура'],
  [2, 'інтернатура'],
  [3, 'клінічна ординатура'],
  [4, 'аспірантура'],
  [5, 'докторантура'],
  [6, 'спеціалізація'],
];

export const scienceDegree: ChoicesType = [
  [1, 'Доктор філософії (кандидат наук)'],
  [2, 'Доктор наук'],
  [3, 'Не маю'],
];

export const degreeSpecialization: ChoicesType = [
  [67,'Лікувальна справа'],
  [68,'Медико-профілактична справа'],
  [69,'Стоматологія'],
  [70,'Фармація'],
  [71,'Технологія Парфумерно-Косметичних Засобів'],
  [72,'Медична психологія'],
  [73,'Лабораторна Діагностика'],
  [1, '03.00.01 Радіобіологія'],
  [2, '03.00.02 Біофізика'],
  [3, '03.00.04 Біохімія'],
  [4, '03.00.06 Вірусологія'],
  [5, '03.00.07 Мікробіологія'],
  [6, '03.00.14 Біологія розвитку'],
  [7, '03.00.15 Генетика'],
  [8, '03.00.16 Екологія'],
  [9, '03.00.21 Мікологія'],
  [10, '14.01.01 Акушерство та гінекологія'],
  [11, '14.01.02 Внутрішні хвороби'],
  [12, '14.01.03 Хірургія'],
  [13, '14.01.04 Серцевосудинна хірургія'],
  [14, '14.01.05 Нейрохірургія'],
  [15, '14.01.06 Урологія'],
  [16, '14.01.07 Онкологія'],
  [17, '14.01.08 Трасплантологія та штучні органи'],
  [18, '14.01.09 Дитяча хірургія'],
  [19, '14.01.10 Педіатрія'],
  [20, '14.01.11 Кардіологія'],
  [21, '14.01.12 Ревматологія'],
  [22, '14.01.13 Інфекційні хвороби'],
  [23, '14.01.14 Ендокринологія'],
  [24, '14.01.15 Нервові хвороби'],
  [25, '14.01.16 Психіатрія'],
  [26, '14.01.17 Наркологія'],
  [27, '14.01.18 Офтальмологія'],
  [28, '14.01.19 Оториноларингологія'],
  [29, '14.01.20 Шкірні та венеричні хвороби'],
  [30, '14.01.21 Травматологія та ортопедія'],
  [31, '14.01.22 Стоматологія'],
  [32, '14.01.23 Променева діагностика та променева терапія'],
  [33, '14.01.24 Лікувальна фізкультура та спортивна медицина'],
  [34, '14.01.25 Судова медицина'],
  [35, '14.01.26 Фтизіатрія'],
  [36, '14.01.27 Пульмонологія'],
  [37, '14.01.28 Клінічна фармакологія'],
  [38, '14.01.29 Клінічна алергологія'],
  [39, '14.01.30 Анестезіологія та інтенсивна терапія'],
  [40, '14.01.31 Гематологія та трансфузіологія'],
  [41, '14.01.32 Медична біохімія'],
  [42, '14.01.33 Медична реабілітація, фізіотерапія та курортологія'],
  [43, '14.01.34 Космічна медицина'],
  [44, '14.01.35 Кріомедицина'],
  [45, '14.01.36 Гастроентерологія'],
  [46, '14.01.37 Нефрологія'],
  [47, '14.01.38 Загальна практика – сімейна медицина'],
  [48, '14.02.01 Гігієна та професійна патологія'],
  [49, '14.02.02 Епідеміологія'],
  [50, '14.02.03 Соціальна медицина'],
  [51, '14.02.04 Історія медицини'],
  [52, '14.03.01 Нормальна анатомія'],
  [53, '14.03.02 Патологічна анатомія'],
  [54, '14.03.03 Нормальна фізіологія'],
  [55, '14.03.04 Патологічна фізіологія'],
  [56, '14.03.05 Фармакологія'],
  [57, '14.03.06 Токсикологія'],
  [58, '14.03.07 Фізіологічно активні сполуки'],
  [59, '14.03.08 Імунологія та алергологія'],
  [60, '14.03.09 Гістологія, цитологія, ембріологія'],
  [61, '14.03.10 Біомеханіка'],
  [62, '14.03.11 Медична та біологічна інформатика і кібернетика'],
  [63, '16.00.11 Паразитологія'],
  [64, '19.00.04 Медична психологія'],
  [65, '20.02.23 Засоби захисту від зброї масового ураження'],
  [66, '21.02.03 Цивільна оборона'],
];

export const academicStatus: ChoicesType = [
  [1, 'Доцент'],
  [2, 'Старший науковий співробітник'],
  [3, 'Професор'],
  [4, 'Не маю'],
];

export const studiedLanguages: ChoicesType = [
  [1, 'англійська'],
  [2, 'німецька'],
  [3, 'французька'],
  [4, 'польська'],
  [5, 'італійська'],
  [6, 'іспанська'],
  [7, 'російська'],
  [8, 'інша мова'],
];

export const languageLevel: ChoicesType = [
  [1, 'початковий'],
  [2, 'нижче середнього'],
  [3, 'середній'],
  [4, 'вище середнього'],
  [5, 'високий'],
  [6, 'професійний'],
];

export const postgraduateType: ChoicesType = [
  [1, 'підвищення кваліфікації на циклах тематичного удосконалення'],
  [2, 'підвищення кваліфікації на курсах стажування'],
  [3, 'навчання в закладі вищої освіти / закладі охорони здоров’я'],
  [4, 'медичне стажування в закладі вищої освіти / закладі охорони здоров’я'],
];

export const postgraduateCategory: ChoicesType = [
  [1, 'без категорії'],
  [2, 'акредитовано EACCME/ACCME/RCPSC'],
  [3, 'сертифіковано ERC/ILCOR/AHA'],
];

export const trainingsTypes: ChoicesType = [
  [5, 'навчання на симуляційних тренінгах'],
  [6, 'навчання на тренінгах з оволодіння практичними навичками'],
  [7, 'тематичне навчання (фахові школи, семінари, майстер-класи тощо)'],
];

export const participationTypes: ChoicesType = [
  [1, 'слухач'],
  [2, 'стендова доповідь'],
  [3, 'усна доповідь'],
];

export const conferenceType: ChoicesType = [
  [1, 'закордонний'],
  [2, 'міжнародний в Україні'],
  [3, 'всеукраїнський'],
];

export const publicationTypes: ChoicesType = [
  [1, 'фахове в Україні'],
  [2, 'закордонне'],
];

export const qualificationCategories: ChoicesType = [
  [4, 'Немає категорії'],
  [1, 'Перша'],
  [2, 'Друга'],
  [3, 'Вища'],
];

export const jobPositions: ChoicesType = [
  [1, 'Генеральний директор'],
  [2, 'Директор'],
  [3, 'Головний лікар'],
  [4, 'Головний державний санітарний лікар - головний лікар'],
  [5, 'Начальник'],
  [6, 'Завідувач'],
  [7, 'Заступник керівника'],
  [8, 'Лікар з авіаційної медицини'],
  [9, 'Лікар-акушер-гінеколог'],
  [10, 'Лікар-алерголог'],
  [11, 'Лікар-алерголог дитячий'],
  [12, 'Лікар-анестезіолог'],
  [13, 'Лікар-анестезіолог дитячий'],
  [14, 'Лікар-бактеріолог'],
  [15, 'Лікар-вірусолог'],
  [16, 'Лікар-гастроентеролог'],
  [17, 'Лікар-гастроентеролог дитячий'],
  [18, 'Лікар-гематолог'],
  [19, 'Лікар-гематолог дитячий'],
  [20, 'Лікар-генетик'],
  [21, 'Лікар-геріатр'],
  [22, 'Лікар-гінеколог дитячого та підліткового віку'],
  [23, 'Лікар-гінеколог-онколог'],
  [24, 'Лікар з гігієни дітей та підлітків'],
  [25, 'Лікар з гігієни праці'],
  [26, 'Лікар з гігієни харчування'],
  [27, 'Лікар-дезінфекціоніст'],
  [28, 'Лікар-дерматовенеролог'],
  [29, 'Лікар-дерматовенеролог дитячий'],
  [30, 'Лікар-дієтолог'],
  [31, 'Лікар-ендокринолог'],
  [32, 'Лікар-ендокринолог дитячий'],
  [33, 'Лікар-ендоскопіст'],
  [34, 'Лікар-епідеміолог'],
  [35, 'Лікар загальної практики - сімейний лікар'],
  [36, 'Лікар із загальної гігієни'],
  [37, 'Лікар-імунолог'],
  [38, 'Лікар-імунолог клінічний'],
  [39, 'Лікар-імунолог дитячий'],
  [40, 'Лікар-інфекціоніст'],
  [41, 'Лікар-інфекціоніст дитячий'],
  [42, 'Лікар-інтерн'],
  [43, 'Лікар-кардіолог'],
  [44, 'Лікар-кардіоревматолог дитячий'],
  [45, 'Лікар-комбустіолог'],
  [46, 'Лікар з комунальної гігієни'],
  [47, 'Лікар-лаборант'],
  [48, 'Лікар-лаборант-генетик'],
  [49, 'Лікар-лаборант-гігієніст'],
  [50, 'Лікар-лаборант-імунолог'],
  [51, 'Лікар-лаборант з клінічної біохімії'],
  [52, 'Лікар з лікувальної фізкультури та спортивної медицини'],
  [53, 'Лікар з лікувальної фізкультури'],
  [54, 'Лікар-методист'],
  [55, 'Лікар-мікробіолог-вірусолог'],
  [56, 'Лікар з народної та нетрадиційної медицини'],
  [57, 'Лікар-нарколог'],
  [58, 'Лікар-нарколог дільничний'],
  [59, 'Лікар-невропатолог'],
  [60, 'Лікар-невролог дитячий'],
  [61, 'Лікар-нефролог'],
  [62, 'Лікар-нефролог дитячий'],
  [63, 'Лікар-нейрохірург'],
  [64, 'Лікар-нейрохірург дитячий'],
  [65, 'Лікар-онколог'],
  [66, 'Лікар-онколог дитячий'],
  [67, 'Лікар-ортопед-травматолог'],
  [68, 'Лікар-ортопед-травматолог дитячий'],
  [69, 'Лікар-отоларинголог'],
  [70, 'Лікар-отоларинголог дитячий'],
  [71, 'Лікар-отоларинголог-онколог'],
  [72, 'Лікар-офтальмолог'],
  [73, 'Лікар-офтальмолог дитячий'],
  [74, 'Лікар-паразитолог'],
  [75, 'Лікар-патологоанатом'],
  [76, 'Лікар-патологоанатом дитячий'],
  [77, 'Лікар-педіатр'],
  [78, 'Лікар-педіатр дільничний'],
  [79, 'Лікар-педіатр-неонатолог'],
  [80, 'Лікар приймальної палати (відділення)'],
  [81, 'Лікар з променевої терапії'],
  [82, 'Лікар-профпатолог'],
  [83, 'Лікар-психіатр'],
  [84, 'Лікар-психіатр дільничний'],
  [85, 'Лікар-психіатр дитячий'],
  [86, 'Лікар-психіатр дитячий дільничний'],
  [87, 'Лікар-психіатр підлітковий'],
  [88, 'Лікар-психіатр підлітковий дільничний'],
  [89, 'Лікар-психолог'],
  [90, 'Лікар-психотерапевт'],
  [91, 'Лікар-психофізіолог'],
  [92, 'Лікар-пульмонолог'],
  [93, 'Лікар-пульмонолог дитячий'],
  [94, "Лікар пункту охорони здоров'я"],
  [95, 'Лікар з радіаційної гігієни'],
  [96, 'Лікар-радіолог'],
  [97, 'Лікар з радіонуклідної діагностики'],
  [98, 'Лікар-рентгенолог'],
  [99, 'Лікар-ревматолог'],
  [100, 'Лікар-рефлексотерапевт'],
  [101, 'Лікар-санолог'],
  [102, 'Лікар із спортивної медицини'],
  [103, 'Лікар-сексопатолог'],
  [104, 'Лікар-статистик'],
  [105, 'Лікар-стажист'],
  [106, 'Лікар станції (відділення) швидкої та невідкладної медичної допомоги'],
  [107, 'Лікар-стоматолог'],
  [108, 'Лікар-стоматолог дитячий'],
  [109, 'Лікар-стоматолог-ортодонт'],
  [110, 'Лікар-стоматолог-ортопед'],
  [111, 'Лікар-стоматолог-терапевт'],
  [112, 'Лікар-стоматолог-хірург'],
  [113, 'Лікар судновий'],
  [114, 'Лікар-судово-медичний експерт'],
  [115, 'Лікар-судово-медичний експерт гістолог'],
  [116, 'Лікар-судово-медичний експерт імунолог'],
  [117, 'Лікар-судово-медичний експерт криміналіст'],
  [118, 'Лікар-судово-медичний експерт цитолог'],
  [119, 'Лікар-судово-медичний експерт токсиколог'],
  [120, 'Лікар-судово-психіатричний експерт'],
  [121, 'Лікар-сурдолог'],
  [122, 'Лікар-терапевт'],
  [123, 'Лікар-терапевт дільничний'],
  [124, 'Лікар-терапевт цехової лікарської дільниці'],
  [125, 'Лікар-терапевт підлітковий'],
  [126, 'Лікар-токсиколог'],
  [127, 'Лікар-трансплантолог'],
  [128, 'Лікар-трансфузіолог'],
  [129, 'Лікар з ультразвукової діагностики'],
  [130, 'Лікар-уролог'],
  [131, 'Лікар-уролог дитячий'],
  [132, 'Лікар-фізіотерапевт'],
  [133, 'Лікар-фтизіатр'],
  [134, 'Лікар-фтизіатр дільничний'],
  [135, 'Лікар-фтизіатр дитячий'],
  [136, 'Лікар з функціональної діагностики'],
  [137, 'Лікар-хірург'],
  [138, 'Лікар-хірург дитячий'],
  [139, 'Лікар-хірург-онколог'],
  [140, 'Лікар-хірург судинний'],
  [141, 'Лікар-хірург серцево-судинний'],
  [142, 'Лікар-хірург торакальний'],
  [143, 'Лікар-хірург проктолог'],
  [144, 'Інша посада'],
];

export const jobSpecializations: ChoicesType = [
  [144,'Медичний факультет'],
  [133,'Стоматологічний факультет'],
  [134,'Фармацевтичний факультет'],
  [135,'Медико-психологічний факультет'],
  [136,'Медико-психологічний факультет'],
  [137,'Військових сил України за спеціальністю медицина факультет'],
  [138,'Медичний коледж Університету за спеціальністю Стоматологія факультет'],
  [1, 'Авіаційна та космічна медицина'],
  [145,'Медичний факультет'],
  [2, 'Акушерство і гінекологія'],
  [3, 'Алергологія'],
  [4, 'Анестезіологія'],
  [5, 'Бактеріологія'],
  [6, 'Вірусологія'],
  [7, 'Гастроентерологія'],
  [8, 'Гематологія'],
  [9, 'Генетика лабораторна'],
  [10, 'Генетика медична'],
  [11, 'Геріатрія'],
  [12, 'Гігієна дітей та підлітків'],
  [13, 'Гігієна праці'],
  [14, 'Гігієна харчування'],
  [15, 'Дезінфекційна справа'],
  [16, 'Дерматовенерологія'],
  [17, 'Дитяча алергологія'],
  [18, 'Дитяча анестезіологія'],
  [19, 'Дитяча гастроентерологія'],
  [20, 'Дитяча гематологія'],
  [21, 'Дитяча гінекологія'],
  [22, 'Дитяча дерматовенерологія'],
  [23, 'Дитяча ендокринологія'],
  [24, 'Дитяча імунологія'],
  [25, 'Дитяча кардіоревматологія'],
  [26, 'Дитяча неврологія'],
  [27, 'Дитяча нефрологія'],
  [28, 'Дитяча онкологія'],
  [29, 'Дитяча ортопедія і травматологія'],
  [30, 'Дитяча отоларингологія'],
  [31, 'Дитяча офтальмологія'],
  [32, 'Дитяча патологічна анатомія'],
  [33, 'Дитяча психіатрія'],
  [34, 'Дитяча пульмонологія'],
  [35, 'Дитяча стоматологія'],
  [36, 'Дитяча урологія'],
  [37, 'Дитяча фтизіатрія'],
  [38, 'Дитяча хірургія'],
  [39, 'Дитячі інфекційні хвороби'],
  [40, 'Дієтологія'],
  [41, 'Ендокринологія'],
  [42, 'Ендоскопія'],
  [43, 'Епідеміологія'],
  [44, 'Загальна гігієна'],
  [45, 'Загальна практика - сімейна медицина'],
  [46, 'Імунологія'],
  [47, 'Інфекційні хвороби'],
  [48, 'Кардіологія'],
  [49, 'Клінічна біохімія'],
  [50, 'Клінічна імунологія'],
  [51, 'Клінічна лабораторна діагностика'],
  [52, 'Комбустіологія'],
  [53, 'Комунальна гігієна'],
  [54, 'Лабораторна імунологія'],
  [55, 'Лабораторні дослідження факторів навколишнього середовища'],
  [56, 'Лабораторні дослідження фізичних факторів навколишнього середовища'],
  [57, 'Лабораторні дослідження хімічних факторів навколишнього середовища'],
  [58, 'Лікувальна фізкультура'],
  [59, 'Лікувальна фізкультура і спортивна медицина'],
  [60, 'Медицина невідкладних станів'],
  [61, 'Медична психологія'],
  [62, 'Мікробіологія і вірусологія'],
  [63, 'Наркологія'],
  [64, 'Народна та нетрадиційна медицина'],
  [65, 'Неврологія'],
  [66, 'Нейрохірургія'],
  [67, 'Неонатологія'],
  [68, 'Нефрологія'],
  [69, 'Онкогінекологія'],
  [70, 'Онкологія'],
  [71, 'Онкоотоларингологія'],
  [72, 'Онкохірургія'],
  [73, "Організація і управління охороною здоров'я"],
  [74, 'Ортодонтія'],
  [75, 'Ортопедична стоматологія'],
  [76, 'Ортопедія і травматологія'],
  [77, 'Отоларингологія'],
  [78, 'Офтальмологія'],
  [79, 'Паразитологія'],
  [80, 'Патологічна анатомія'],
  [81, 'Педіатрія'],
  [82, 'Підліткова терапія'],
  [83, 'Проктологія'],
  [84, 'Променева терапія'],
  [85, 'Професійна патологія'],
  [86, 'Психіатрія'],
  [87, 'Психотерапія'],
  [88, 'Психофізіологія'],
  [89, 'Пульмонологія'],
  [90, 'Радіаційна гігієна'],
  [91, 'Радіологія'],
  [92, 'Радіонуклідна діагностика'],
  [93, 'Ревматологія'],
  [94, 'Рентгенологія'],
  [95, 'Рефлексотерапія'],
  [96, 'Санологія'],
  [97, 'Сексопатологія'],
  [98, 'Спортивна медицина'],
  [99, 'Стоматологія'],
  [100, 'Судинна хірургія'],
  [101, 'Суднова медицина'],
  [102, 'Судово-медична гістологія'],
  [103, 'Судово-медична експертиза'],
  [104, 'Судово-медична імунологія'],
  [105, 'Судово-медична криміналістика'],
  [106, 'Судово-медична токсикологія'],
  [107, 'Судово-медична цитологія'],
  [108, 'Судово-психіатрична експертиза'],
  [109, 'Сурдологія'],
  [110, 'Терапевтична стоматологія'],
  [111, 'Терапія'],
  [112, 'Токсикологія'],
  [113, 'Торакальна хірургія'],
  [114, 'Трансплантологія'],
  [115, 'Трансфузіологія'],
  [116, 'Ультразвукова діагностика'],
  [117, 'Урологія'],
  [118, 'Фізіотерапія'],
  [119, 'Фтизіатрія'],
  [120, 'Функціональна діагностика'],
  [121, 'Хірургічна стоматологія'],
  [122, 'Хірургія'],
  [123, 'Лікувальна Справа'],
  [124, 'Педіатрія'],
  [125, 'Стоматологія'],
  [126, 'Фізична терапія'],
  [127, 'Ерготерапія'],
  [128, 'Фармація'],
  [129, 'Промислова Фармація'],
  [130, 'Медична Психологія'],
  [131, 'Стоматологі Ортопедична '],
  [132, 'Інша спеціальність'],
];

export const MISTypes: ChoicesType = [
  [26, 'Не застосовуємо'],
  [1, 'Askep.net'],
  [2, 'Binomed'],
  [3, 'DocHelper'],
  [4, 'E-life'],
  [5, 'Emcimed'],
  [6, 'Health24'],
  [7, 'helsi.me'],
  [8, 'IgiMED'],
  [9, 'MedCard Plus'],
  [10, 'Medialog'],
  [11, 'Medics'],
  [12, 'Meditex Регистратура'],
  [13, 'MediUm'],
  [14, 'MEDSTAR'],
  [15, 'nHealth'],
  [16, 'Reestratura'],
  [17, 'Selenium'],
  [18, 'SimplexMed'],
  [19, 'TherDep'],
  [20, 'UASMART'],
  [21, 'Доктор Елекс'],
  [22, 'Ескулап'],
  [23, 'МедЕйр'],
  [24, 'Мій Мед Кабінет'],
  [25, 'Поліклініка без черг'],
  [27, 'МедІнфоСервіс'],
  [28, 'Інші'],
];

export const universitiesPositions: ChoicesType = [
  [1, 'асистент'],
  [2, 'викладач'],
  [3, 'старший викладач'],
  [4, 'доцент'],
  [5, 'професор'],
  [6, 'завідувач кафедрою'],
  [7, 'декан'],
];

export const honorTitles: ChoicesType = [
  [1, 'Заслужений лікар України'],
  [2, 'Заслужений діяч науки і техніки України'],
  [3, 'Заслужений працівник охорони здоров’я України'],
  [4, 'Заслужений працівник освіти'],
  [5, 'Грамотою Верховної Ради України'],
  [6, 'Почесна грамота Кабінету Міністрів України'],
  [7, 'Почесна грамота Міністерства охорони здоров’я України'],
  [
    8,
    'Почесна грамота Міністерства охорони здоров’я Автономної Республіки Крим',
  ],
  [9, 'Грамотами облдержадміністрації'],
  [
    10,
    'Відомча заохочувальна відзнака Міністерства охорони здоров’я України — нагрудний знак «Хрест Пантелеймона Цілителя»',
  ],
];

export const patentType: ChoicesType = [
  [1, 'на фаховий винахід'],
  [2, 'на корисну модель'],
];

export const textbookTypes: ChoicesType = [
  [1, 'фаховий підручник'],
  [2, 'навчальний посібник'],
  [3, 'монографія'],
];

export const recommendationTypes: ChoicesType = [
  [1, 'галузеві методичні рекомендації'],
  [2, 'інформаційний листок'],
  [3, 'раціоналізаторські пропозиції'],
];

export const organizationsTypes: ChoicesType = [
  [1, 'Національна асоціація'],
  [2, 'Міжнародна асоціація'],
  [3, 'Національна академія медичних наук України'],
  [4, 'Професійна спілка'],
  [5, 'Інша організація'],
];

export const genderChoices: ChoicesType = [
  ['male', 'Чоловіча'],
  ['female', 'Жіноча'],
];
