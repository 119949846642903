import { sleep } from 'helpers/aio';
import { setModal } from 'store/modules/ui/actions';
import UiTypes from 'store/modules/ui/constants';
import { InjectMiddlewareCallback } from 'store/types';

const modalHandler: InjectMiddlewareCallback = async (store, action, next) => {
  const { name, opened, visible } = action.payload;

  if (typeof visible === 'undefined') {
    if (opened) {
      next(action);
      await sleep(100);
      store.dispatch(setModal(name, opened, true));
    } else {
      next({
        ...action,
        payload: { ...action.payload, opened: true, visible: false },
      });
      await sleep(500);
      store.dispatch(setModal(name, opened, false));
    }
  } else {
    next(action);
  }
};

const handlers = {
  [UiTypes.SET_MODAL]: modalHandler,
};

export default handlers;
