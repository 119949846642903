import { UiState } from 'store/modules/ui/types';
import { PayloadAction } from 'store/types';
import UiTypes from './constants';

const initialState: UiState = {
  errors: [],
  loaders: [],
  modals: {},
};

export default function ui(
  state: UiState = initialState,
  action: PayloadAction
): UiState {
  const { type, payload } = action;

  switch (type) {
    case UiTypes.SET_MODAL:
      const { name, opened, visible } = payload;
      return {
        ...state,
        modals: { ...state.modals, [name]: { opened, visible } },
      };
    case UiTypes.SET_LOADER:
      return {
        ...state,
        loaders: payload.state
          ? [...state.loaders, payload.name]
          : state.loaders.filter(loader => loader !== payload.name),
      };
  }
  return state;
}
