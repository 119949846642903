import IProfileProvider, {
  SIProfileProvider,
} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import container from 'ioc';
import { setProfile } from 'store/modules/profile/actions';
import UserActionTypes from 'store/modules/user/constants';
import { AfterMiddlewareCallback } from 'store/types';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const getUserInfo: AfterMiddlewareCallback = store => {
  provider()
    .getUserInfo()
    .then(data => store.dispatch(setProfile(data)))
    // tslint:disable-next-line:no-console
    .catch(e => console.log(e.message));
};

const handlers = {
  [UserActionTypes.SET_DATA]: getUserInfo,
};

export default handlers;
