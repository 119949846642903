const MAIN_LINKS = [
  ['/news', 'Новини'],
  ['/events', 'Події'],
  ['/courses', 'Курси'],
  ['/about', 'Про  нас'],
  ['/faq', 'FAQ'],
  ['/contacts', 'Контакти'],
];

export default MAIN_LINKS;
