import { AbstractBlock } from './AbstractBlock';
import { DefaultBlock } from './DefaultBlock';
import { NewsBlock } from './NewsBlock';

const blockTypes: Record<string, any> = {
  news: NewsBlock,
};

export class Page extends AbstractBlock {
  public serialize(data: Record<string, any>): Record<string, any> {
    return {
      ...data,
      blocks: data.blocks.map((x: any) => {
        const Block = blockTypes[x.type] ? blockTypes[x.type] : DefaultBlock;
        return new Block().serialize(x);
      }),
    };
  }
}
