import * as staticData from '../../../constants/data'

import {Link, Route, Switch} from 'react-router-dom';
import React, {useContext, useEffect} from 'react';

import ACCOUNT_LINKS from 'constants/links/profileLinks';
import ACCOUNT_ROUTES from './routes';
import AccountNav from 'components/pages/Account/blocks/AccountNav';
import {AppState} from 'store/reducer';
import Dashboard from './blocks/Dashboard';
import EDIT_LINKS from 'constants/links/editLinks';
import EducationalActivitiesSchema from '../../../schemas/EducationalActivitiesSchema'
import {HigherEducationSchema} from '../../../schemas/EducationSchemas'
import InformalEducationSchema from '../../../schemas/InformalEducationSchema'
import {Location} from 'history';
import MainSettingsSchema from '../../../schemas/MainSettingsSchema'
import NotAuthorized from 'components/errors/NotAuthorized';
import ProfessionalActivitySchema from '../../../schemas/ProfessionalActivitySchema'
import ScientificAndInnovativeWorkSchema from '../../../schemas/ScientificAndInnovativeWorkSchema'
import SeoContext from 'contexts/SeoContext';
import User from './blocks/User';
import arrow_back from 'assets/images/arrow_back.png';
import certificates from 'assets/images/certificates.png';
import {clns} from 'helpers/strings';
import {getLocation} from 'connected-react-router';
import {getValueByKeyFromTuple} from '../../../helpers/arrays'
import {isAuthorized} from 'store/modules/auth/selectors';
import later from 'assets/images/later.png';
import liked from 'assets/images/liked.png';
import portfolio from 'assets/images/portfolio.png';
import purchases from 'assets/images/purchases.png';
import standarts from 'assets/images/standarts.png';
import styles from './Profile.module.sass';
import {useSelector} from 'react-redux';
import useUserSettings from '../../../hooks/useUserSettings'

const icons = [portfolio, purchases, later, liked, certificates, standarts];

const Account = () => {
  const authorized = useSelector(isAuthorized);
  const location = useSelector<AppState, Location>(getLocation);
  const isEdit = location.pathname.includes('edit');
  
  const {setSeo} = useContext(SeoContext);
  useEffect(() => {
    setSeo({title: 'Кабінет'});
  }, []);

  if (!authorized) {
    return <NotAuthorized />;
  }
  
  //@ts-ignore
  if (JSON.parse(localStorage.getItem('isBanned')) === 'true')
    return (
      <div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100%',
          top: 0,
          left: 0,
          background: '#f2f2f2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
        }}
      >
        <h2 style={{color: '#D62915'}}>Ви були забанені</h2>
      </div>
    );

  return (
    <div className={styles.profile}>
      <div className={styles.profile__main}>
        {isEdit && (
          <Link to={'/account'} className={styles.backLink}>
            <img
              src={arrow_back}
              alt={'arrow back'}
              className={styles.backLink__icon}
            />
            <div className={styles.backLink__text}>До кабінету</div>
          </Link>
        )}
        {!isEdit && (
          <div className={styles.profile__top}>
            <div
              className={clns([
                styles.profile__topBlock,
                styles.profile__topBlock_main,
              ])}
            >
              <User />
            </div>
            <div
              className={clns([
                styles.profile__topBlock,
                styles.profile__topBlock_dashboard,
              ])}
            >
              <Dashboard />
            </div>
          </div>
        )}
        <div className={styles.profile__body}>
          <AccountNav
            icons={isEdit ? undefined : icons}
            links={isEdit ? EDIT_LINKS : ACCOUNT_LINKS}
          />
          <div className={styles.profile__content}>
            <Switch>
              {ACCOUNT_ROUTES.map((route) => (
                <Route
                  path={`/account${route.url}`}
                  exact={route.exact}
                  component={route.component}
                  key={route.url}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
