import { SchemaDataType } from '@project-m/schemas/dist/types';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Modal, { ModalConsumerProps } from 'components/shared/Modal';
import React, { useCallback, useState } from 'react';
import ForgotPasswordSchema from 'schemas/ForgotPasswrodSchema';
import IUserProvider, {
  SIUserProvider,
} from '../../../../infrastructure/providers/UserProvider/IUserProvider';
import container from '../../../../ioc';

import styles from './ForgotPassword.module.sass';

const provider = () => container.get<IUserProvider>(SIUserProvider);

const ForgotPassword: React.FC<ModalConsumerProps> = ({ visible }) => {
  const [approved, setApproved] = useState<boolean>(false);
  const [{ value, errors }, setData] = useState<SchemaDataType<any>>(
    ForgotPasswordSchema.generateEmpty()
  );

  const handleSubmit = useCallback(() => {
    // value.email for provider
    provider()
      .resetPassword(value.email)
      .then(() => setApproved(true));
  }, [value]);

  return (
    <Modal name={'forgotPassword'} visible={visible}>
      <div className={styles.wrapper}>
        <div className={styles.headline}>
          {approved
            ? 'На вашу пошту надіслано посилання для оновлення паролю'
            : 'Оновити пароль'}
        </div>
        {approved ? (
          <div className={styles.text}>
            Якщо ви не бачите листа в повідомленнях, перевірте папку "спам"
          </div>
        ) : (
          <Form
            schema={ForgotPasswordSchema}
            value={value}
            errors={errors}
            onChange={setData}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Input.Connected name={'email'} label={'Email'} />
            </Form.Row>
            <Form.Button>Оновити</Form.Button>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default ForgotPassword;
