import React, {useCallback} from 'react';

import RoundButton from 'components/shared/RoundButton';
import {SertificateDto} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import download from 'assets/images/download.png';
import moment from 'moment';
import styles from 'components/pages/Account/pages/Certificates/Certificates.module.sass';
import watch from 'assets/images/watch.png';

interface CertificateProps {
  certificate: SertificateDto;
}
//@ts-ignore
const Certificate = ({cert}) => {
  console.log(cert, 'cert from client/src/pages/certificates');
  const handleOpen = useCallback(() => {
    const win = window.open(`/images/${cert.link}`, '_blank');
    if (win) {
      win.focus();
    }
  }, []);

  return (
    <div className={styles.certificates__certificate}>
      <div className={styles.certificates__logo}>
        <img
          className={styles.certificates__image}
          src={`/images/${cert.link}`}
          alt={''}
        />
      </div>
      <div className={styles.certificates__left}>
        <div className={styles.certificates__info}>
          <div className={styles.certificates__name}>
            Сертификат за курс {cert.title}
          </div>
          {cert.points && (
            <div className={styles.certificates__description}>
              Кількість балів за сертифікат: {cert.points}
            </div>
          )}
        </div>
        <div className={styles.certificates__date}>Додано: {cert.date}</div>
      </div>
      <div className={styles.certificates__right}>
        <div className={styles.certificates__buttons}>
          <RoundButton
            icon={watch}
            className={styles.certificates__button}
            onClick={handleOpen}
          />
          <RoundButton
            icon={download}
            className={styles.certificates__button}
            onClick={handleOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Certificate;
