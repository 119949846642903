import React from 'react';
import NewsListItemDto from '../../../../../infrastructure/models/news/NewsListItemDto';

import ListView from '../../../../shared/ListView';
import NewsItem from '../../../../shared/NewsItem';

interface NewsBlockProps {
  news: NewsListItemDto[];
}

const NewsBlock = React.memo<NewsBlockProps>(({ news }) => {
  return (
    <ListView scrollable>
      {news.map(item => (
        <NewsItem newsItem={item} key={item.id} />
      ))}
    </ListView>
  );
});

export default NewsBlock;
