import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';

export interface GoToEventValueModel {
  name: string;
  surname: string;
  email: string;
  phone: string;
  id: number;
}

const GoToEventSchema = Schema.create<GoToEventValueModel>({
  name: Fields.text().required(),
  surname: Fields.text().required(),
  email: Fields.email().required(),
  phone: Fields.phone().required(),
  id: Fields.any().required(),
});

export default GoToEventSchema;
