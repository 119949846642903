import './port.css';

import * as staticData from 'constants/data';

import React,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../shared/Button';
import EducationalActivitiesSchema from '../../../../../schemas/EducationalActivitiesSchema'
import {HigherEducationSchema} from '../../../../../schemas/EducationSchemas'
import InfoBlock from './blocks/InfoBlock';
import InformalEducationSchema from '../../../../../schemas/InformalEducationSchema'
import MainSettingsSchema from '../../../../../schemas/MainSettingsSchema'
import Modal from './Modal'
import ProfessionalActivitySchema from '../../../../../schemas/ProfessionalActivitySchema'
import ScientificAndInnovativeWorkSchema from '../../../../../schemas/ScientificAndInnovativeWorkSchema'
import User from '../../blocks/User';
import { UserSelectors } from 'store/modules/user';
import axios from 'axios';
import { getValueByKeyFromTuple } from 'helpers/arrays';
import {profileSelector} from 'store/modules/profile/selectors';
import { push } from 'connected-react-router';
import styles from './Portfolio.module.sass';
import useUserSettings from '../../../../../hooks/useUserSettings'

//@ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const Portfolio = () => {
  const user = useSelector(profileSelector);
  const dispatch = useDispatch();
  const education = useSelector(UserSelectors.getEducationData);
  const experience = useSelector(UserSelectors.getExperienceData);
  const [isOpen, setIsOpen] = useState(false)
  const [custom, setCustom] = useState([]);

  const {value:main} = useUserSettings('main', MainSettingsSchema);
  const {value:experienceToPrint} = useUserSettings('experience', ProfessionalActivitySchema);
  const {value:educationToPrint} = useUserSettings('education', HigherEducationSchema);
  const {value:teaching} = useUserSettings('teaching', EducationalActivitiesSchema);
  const {value:postgraduate} = useUserSettings('postgraduate', InformalEducationSchema);
  const {value:innovative} = useUserSettings('innovative', ScientificAndInnovativeWorkSchema);

  const university = education.universities?.[0]?.university;
  const degree = education.scienceDegree?.[0]?.degree;
  const status = education.academicStatus?.[0]?.status;

  const startYear = experience.startYear;
  const qualificationCategory = experience.qualificationCategory;
  const placeOfWork = experience.placesOfWork?.[0]?.name;

  useEffect(() => {
    const getCertificates = async () => {
      try {
        const res = await axios.get(
          `http://cme-cpd.com.ua:1337/admin/api/getCustomCert`
        );
        setCustom(
          res.data.filter((cert: any) => {
            const id = cert.link.slice(0, 2);
            //@ts-ignore
            if (id == user.userId) return cert;
          })
        );
      } catch (error) {
        console.log(error, 'certificates');
      }
    };
    if(user !== undefined) {
      getCertificates();
    }
    
  }, [user]);

  const data = {...main,...experienceToPrint,...educationToPrint,...teaching,...postgraduate,...innovative};
        // @ts-ignore
        const conference = data.conference.map(v => ({
          ...v,
          participationType: getValueByKeyFromTuple(v.participationType, dataJSON.participationTypes || staticData.participationTypes),
          type: getValueByKeyFromTuple(v.type, dataJSON.conferenceType || staticData.conferenceType)
        }))
  
       // @ts-ignore
        const freelance = data.freelance.map(v => ({
        ...v,
        specialization:  getValueByKeyFromTuple(v.specialization, dataJSON.trainingsTypes || staticData.trainingsTypes)
        }))
        // @ts-ignore
        const honoraryTitles = data.honoraryTitles.map(v => ({
          ...v,
          name:  getValueByKeyFromTuple(v.name, dataJSON.honorTitles || staticData.honorTitles)
        }))
  
        // @ts-ignore
        const misUsage = data.misUsage.map(v => ({
          ...v,
          name:  getValueByKeyFromTuple(v.name, dataJSON.MISTypes || staticData.MISTypes)
        }))
  
        // @ts-ignore
        const organizations = data.organizations.map(v => ({
          ...v,
          type:  getValueByKeyFromTuple(v.type, dataJSON.organizationsTypes || staticData.organizationsTypes)
        }))
  
        // @ts-ignore
        const patents = data.patents.map(v => ({
          ...v,
          type: getValueByKeyFromTuple(v.type, dataJSON.patentType || staticData.patentType)
        }))
  
        // @ts-ignore
        const placesOfWork = data.placesOfWork.map(v => ({
          ...v,
          position: getValueByKeyFromTuple(v.position, dataJSON.jobPositions || staticData.jobPositions),
          specialization: getValueByKeyFromTuple(v.specialization, dataJSON.jobSpecializations || staticData.jobSpecializations)
        }))
  
        // @ts-ignore
        const postgraduateToPrint = data.postgraduate.map(v => ({
          ...v,
          category: getValueByKeyFromTuple(v.category, dataJSON.postgraduateCategory || staticData.postgraduateCategory),
          type: getValueByKeyFromTuple(v.type, dataJSON.postgraduateType || staticData.postgraduateType)
        }))
  
        // @ts-ignore
        const publications = data.publications.map(v => ({
          ...v,
          language: getValueByKeyFromTuple(v.language, dataJSON.studiedLanguages || staticData.studiedLanguages),
          type: getValueByKeyFromTuple(v.type, dataJSON.publicationTypes || staticData.publicationTypes)
        }))
  
        // @ts-ignore
        const recommendations = data.recommendations.map(v => ({
          ...v,
          language: getValueByKeyFromTuple(v.language, dataJSON.studiedLanguages || staticData.studiedLanguages),
          type: getValueByKeyFromTuple(v.type, dataJSON.recommendationTypes || staticData.recommendationTypes)
        }))

        // @ts-ignore
        const remote = data.remote.map(v => ({
          ...v,
          language: getValueByKeyFromTuple(v.language, dataJSON.studiedLanguages || staticData.studiedLanguages),
        }))
  
        // @ts-ignore
        const scienceDegree = data.scienceDegree.map(v => ({
          ...v,
          degree: getValueByKeyFromTuple(v.degree, dataJSON.scienceDegree || staticData.scienceDegree),
          specialization: getValueByKeyFromTuple(v.specialization, dataJSON.degreeSpecialization || staticData.degreeSpecialization)
        }))
              
        // @ts-ignore
        const scientificPedagogicalActivity = data.scientificPedagogicalActivity.map(v => ({
          ...v
        }))
  
        // @ts-ignore
        const textbooks = data.textbooks.map(v => ({
          ...v,
          language: getValueByKeyFromTuple(v.language, dataJSON.studiedLanguages || staticData.studiedLanguages)
        }))
  
        // @ts-ignore
        const trainings = data.trainings.map(v => ({
          ...v,
          type: getValueByKeyFromTuple(v.type, dataJSON.trainingsTypes || staticData.trainingsTypes)
        }))
  
        // @ts-ignore
        const universities = data.universities.map(v => ({
          ...v,
          educationType: getValueByKeyFromTuple(v.educationType, dataJSON.educationType || staticData.educationType),
          specialization: getValueByKeyFromTuple(v.specialization, dataJSON.degreeSpecialization || staticData.degreeSpecialization),
          university: getValueByKeyFromTuple(v.university, dataJSON.universities || staticData.universities)
        }))
  
        // @ts-ignore
        const academicStatus = data.academicStatus.map(v => ({
          ...v,
          status: getValueByKeyFromTuple(v.status, dataJSON.academicStatus || staticData.academicStatus),
        }))
  
        // @ts-ignore
        const languages = data.languages.map(v => ({
          ...v,
          language: getValueByKeyFromTuple(v.language, dataJSON.studiedLanguages || staticData.studiedLanguages),
          level: getValueByKeyFromTuple(v.level, dataJSON.languageLevel || staticData.languageLevel)
        }))

        const toPrint = {
          ...data,
          // @ts-ignore
          specialization: getValueByKeyFromTuple(data.specialization, dataJSON.jobSpecializations || staticData.jobSpecializations),
          // @ts-ignore
          qualificationCategory: getValueByKeyFromTuple(data.qualificationCategory, dataJSON.qualificationCategories || staticData.qualificationCategories),
          academicStatus,
          universities,
          languages,
          organizations,
          misUsage,
          honoraryTitles,
          freelance,
          conference,
          patents,
          placesOfWork,
          postgraduate:postgraduateToPrint,
          publications,
          recommendations,
          remote,
          textbooks,
          trainings,
          scienceDegree,
          scientificPedagogicalActivity
        }

  return (
    <>
    {isOpen && <Modal toPrint={toPrint} courses={custom} education={education} degree={degree} onClose={setIsOpen} placeOfWork={placeOfWork} startYear={startYear} />}
    <div id='main_b' className={styles.portfolio}>  
      <div className={styles.portfolio__buttons}>
        <Button color={'blue'} withBorders className={styles.portfolio__button} onClick={() => setIsOpen(true)}>
          Згенерувати портфоліо
        </Button>
        <Button
          color={'red'}
          withBorders
          className={styles.portfolio__button}
          onClick={() => dispatch(push('/account/edit'))}
        >
          Редагувати
        </Button>
      </div>
      <div className={styles.portfolio__content}>
        <User plain className={styles.portfolio__user} />
        <InfoBlock
          headline={'Освіта'}
          list={[
            [
              'ВНЗ',
              getValueByKeyFromTuple(university, dataJSON.universities || staticData.universities) || 'Не вказано',
            ],
            [
              'Наукова ступінь',
              getValueByKeyFromTuple(degree, dataJSON.scienceDegree || staticData.scienceDegree) || 'Не вказано',
            ],
            [
              'Вчене звання',
              getValueByKeyFromTuple(status, dataJSON.academicStatus || staticData.academicStatus) || 'Не вказано',
            ],
            [
              'Рівень володіння іноземними мовами',
              education.languages
                ?.map(
                  (lang: any) =>
                    `${getValueByKeyFromTuple(
                      lang.language,
                      dataJSON.studiedLanguages || staticData.studiedLanguages
                    )}: ${getValueByKeyFromTuple(lang.level, dataJSON.languageLevel || staticData.languageLevel)}`
                )
                .join(', ') || 'Не вказано',
            ],
          ]}
        />
        <InfoBlock
          headline={'Професійна діяльність'}
          list={[
            ["Рік початку лікарської кар'єри", startYear || 'Не вказано'],
            [
              'Категорія',
              getValueByKeyFromTuple(
                qualificationCategory,
                dataJSON.qualificationCategories || staticData.qualificationCategories
              ) || 'Не вказано',
            ],
            ['Місце роботи', placeOfWork || 'Не вказано'],
          ]}
        />
      </div>
    </div>
    </>
 
  );
};

export default Portfolio;
