import { push } from 'connected-react-router';
import { loadProfile, setProfile } from 'store/modules/profile/actions';
import { AfterMiddlewareCallback } from 'store/types';
import IUserProvider, {
  SIUserProvider,
} from '../../../infrastructure/providers/UserProvider/IUserProvider';
import container from '../../../ioc';
import { setLoader, setModal } from '../ui/actions';
import { logInDone } from './actions';
import AuthActionTypes from './constants';
import { LogInAction, LogOutAction } from './types';

const provider = () => container.get<IUserProvider>(SIUserProvider);

const logIn: AfterMiddlewareCallback<LogInAction> = (store, action) => {
  const { email, password } = action.payload;
  store.dispatch(setLoader('global', true));
  provider()
    .login(email, password)
    .then(() => {
      store.dispatch(logInDone());
      store.dispatch(setModal('login', false));
      store.dispatch(push('/'));
      store.dispatch(loadProfile());
    })
    .catch(e => alert(e.message))
    .finally(() => {
      setTimeout(() => store.dispatch(setLoader('global', false)), 0.1);
    });
};

const logOut: AfterMiddlewareCallback<LogOutAction> = store => {
  provider()
    .logOut()
    .then(() => {
      store.dispatch(push('/'));
      store.dispatch(setProfile());
    })
    .catch(e => alert(e.message));
};

const authHandlers = {
  [AuthActionTypes.LOG_IN]: logIn,
  [AuthActionTypes.LOG_OUT]: logOut,
};

export default authHandlers;
