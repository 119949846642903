import EventListItemDto from 'infrastructure/models/events/EventListItemDto';
import IEventsProvider, {
  SIEventsProvider,
} from 'infrastructure/providers/EventsProvider/IEventsProvider';
import container from 'ioc';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isAuthorized } from 'store/modules/auth/selectors';

import ListItem from '../ListItem';

import { clns } from 'helpers/strings';

import styles from './EventItem.module.sass';

export interface EventItemProps {
  event: EventListItemDto;
  className?: string;
}

const provider = () => container.get<IEventsProvider>(SIEventsProvider);

const EventItem: React.FC<EventItemProps> = ({ event, className }) => {
  const { image, title, date, city, price, slug, liked, id } = event;
  const [localLiked, setLocalLiked] = useState(liked);
  const authorized = useSelector(isAuthorized);

  return (
    <ListItem
      link={`/events/${slug}`}
      className={clns([styles.eventItem, className])}
      action={
        authorized
          ? () => {
              provider()
                .like(id, !localLiked)
                .then(() => setLocalLiked(!localLiked));
            }
          : undefined
      }
      filled={localLiked}
    >
      <div className={styles.eventItem__top}>
        <img src={image} alt={title} className={styles.eventItem__image} />
      </div>
      <div className={styles.eventItem__content}>
        <div className={styles.eventItem__headline}>{title}</div>
        <div className={styles.eventItem__separator} />
        <div className={styles.eventItem__bottom}>
          <div className={styles.eventItem__column}>
            <div className={styles.eventItem__label}>Дата</div>
            <div className={styles.eventItem__value}>{date}</div>
          </div>
          <div className={styles.eventItem__column}>
            <div className={styles.eventItem__label}>Місто</div>
            <div className={styles.eventItem__value}>{city}</div>
          </div>
          <div className={styles.eventItem__column}>
            <div className={styles.eventItem__label}>Ціна</div>
            <div className={styles.eventItem__value}>{price}</div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default EventItem;
