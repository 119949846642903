import Id from '@project-m/core/entities/common/Id';
import CourseTestingAnswer, {
  AnswerStatuses,
} from 'components/pages/Courses/CourseLections/blocks/CourseTesting/CourseTestingAnswer';
import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import Button from 'components/shared/Button';

import { QuestionDto } from 'infrastructure/models/courses/common';
import ICourseProvider, {
  SICourseProvider,
} from 'infrastructure/providers/CourseProvider/ICourseProvider';
import container from 'ioc';
import findIndex from 'lodash/findIndex';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import styles from './CourseTesting.module.sass';

export interface AnswersHistoryModel {
  [key: string]: { answerId: Id; status: boolean };
}

interface CourseTestingProgressProps {
  onFinish: (data: AnswersHistoryModel) => void;
}

const CourseTestingProgress: React.FC<CourseTestingProgressProps> = ({
  onFinish,
}) => {
  const { course, currentLection } = useContext(CourseLectionsContext);
  const { questions } = currentLection!;
  const provider = useMemo(
    () => () => container.get<ICourseProvider>(SICourseProvider),
    []
  );
  const [answersHistory, setAnswersHistory] = useState<AnswersHistoryModel>({});
  const [currentQuestion, setCurrentQuestion] = useState<QuestionDto>(
    questions[0]
  );
  const [currentAnswer, setCurrentAnswer] = useState<Id | null>(null);
  const currentQuestionIndex = findIndex(
    questions,
    (o: QuestionDto) => o.id === currentQuestion.id
  );
  const isQuestionInHistory = currentQuestion.id in answersHistory;
  const isLastQuestion = currentQuestionIndex === questions.length - 1;
  const buttonText = isQuestionInHistory
    ? isLastQuestion
      ? 'Закінчити тестування'
      : 'Наступне питання'
    : 'Зарахувати відповідь';

  const handleAnswer = useCallback(() => {
    if (currentAnswer) {
      provider()
        .answer(
          course!.id,
          currentLection!.id,
          currentQuestion.id,
          currentAnswer
        )
        .then(status => {
          setCurrentAnswer(null);
          setAnswersHistory({
            ...answersHistory,
            [currentQuestion.id]: { answerId: currentAnswer, status },
          });
        });
    }
  }, [currentQuestion, currentAnswer, answersHistory]);
  const handleButtonClick = useCallback(() => {
    if (currentAnswer) {
      handleAnswer();
    } else {
      if (isQuestionInHistory) {
        if (questions[currentQuestionIndex + 1]) {
          setCurrentQuestion(questions[currentQuestionIndex + 1]);
        } else {
          onFinish(answersHistory);
        }
      }
    }
  }, [
    handleAnswer,
    currentAnswer,
    currentQuestionIndex,
    isQuestionInHistory,
    answersHistory,
    onFinish,
  ]);

  return (
    <>
      <div className={styles.main}>
        Запитання {currentQuestionIndex + 1} з {questions.length}
      </div>
      <div className={styles.content}>
        <div className={styles.question}>{currentQuestion.text}</div>
        {currentQuestion.answers.map(answer => {
          const checked =
            answer.id ===
            (isQuestionInHistory
              ? answersHistory[currentQuestion.id].answerId
              : currentAnswer);
          const status = isQuestionInHistory
            ? answer.id === answersHistory[currentQuestion.id].answerId
              ? answersHistory[currentQuestion.id].status
                ? AnswerStatuses.RIGHT
                : AnswerStatuses.WRONG
              : AnswerStatuses.UNCHECKED
            : checked
            ? AnswerStatuses.CHECKED
            : AnswerStatuses.UNCHECKED;
          return (
            <CourseTestingAnswer
              onClick={isQuestionInHistory ? noop : setCurrentAnswer}
              key={answer.id}
              checked={checked}
              status={status}
              {...answer}
            />
          );
        })}
      </div>
      <Button filled color={'lightBlue'} onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </>
  );
};

export default CourseTestingProgress;
