import React, { useCallback, useState } from 'react';
import Helmet from 'react-helmet';

import logo from 'assets/images/logo.png';
import SeoContext, { SeoDataModel } from 'contexts/SeoContext';

const defaultSeoData: SeoDataModel = {
  title: 'Українська Академія Сімейного Лікаря',
  description: 'Українська Академія Сімейного Лікаря',
  image: logo,
  type: 'website',
  og: {},
};

const SeoProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<SeoDataModel>(defaultSeoData);

  const setSeo = useCallback((newSeoData: SeoDataModel) => {
    setData({ ...defaultSeoData, ...newSeoData });
  }, []);

  const title =
    data.title === defaultSeoData.title
      ? data.title
      : `${data.title} | ${defaultSeoData.title}`;
  const description = data.description!.replace(/(<[^>]*|&[a-z]+;)>?/gm, '');
  const image = data.image;
  const type = data.type;

  return (
    <SeoContext.Provider value={{ setSeo }}>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description },
          {
            property: 'og:site_name',
            content: 'Українська Академія Сімейного Лікаря',
          },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:image', content: image },
          { property: 'og:type', content: type },
          ...Object.entries(data.og!).map(([property, content]) => ({
            property,
            content,
          })),
        ]}
      />
      {children}
    </SeoContext.Provider>
  );
};

export default SeoProvider;
