import range from 'lodash/range';
import React from 'react';

import { clns } from '../../../helpers/strings';
import withPlaceholder from '../../hocs/withPlaceholder';
import Button from '../Button';
import ListItem from '../ListItem';

import styles from './ListView.module.sass';

interface ListViewProps {
  className?: string;
  scrollable?: boolean;
  canLoadMore?: boolean;
  onMore?: () => void;
  loading?: boolean;
  empty?: boolean;
}

const ListView: React.FC<ListViewProps> = ({
  children,
  scrollable,
  className,
  canLoadMore,
  onMore,
  loading,
  empty,
}) => {
  return (
    <div
      className={clns([
        styles.listView,
        scrollable && styles.listView_scrollable,
        className,
      ])}
    >
      <div
        className={clns([
          styles.listView__wrapper,
          scrollable && styles.listView__wrapper_scrollable,
        ])}
      >
        {typeof empty !== 'boolean' || !empty ? (
          children
        ) : (
          <div className={styles.listView__empty}>Тут поки нічого немає</div>
        )}
      </div>
      {canLoadMore && onMore && !empty && (
        <Button filled onClick={onMore} loading={loading}>
          Заватажити ще
        </Button>
      )}
    </div>
  );
};

const placeholder: React.FC<ListViewProps> = ({ children, ...props }) => (
  <ListView {...props}>
    {range(props.scrollable ? 3 : 9).map(item => (
      <ListItem link={''} key={item} />
    ))}
  </ListView>
);

export default withPlaceholder(placeholder, ListView);
