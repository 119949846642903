import Id from '@project-m/core/entities/common/Id';
import ListResponse from 'infrastructure/models/common/ListResponse';
import NewsFiltersModel from 'infrastructure/models/news/NewsFiltersModel';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import NoveltyDto from '../../models/news/NoveltyDto';

export const SINewsProvider = Symbol.for('INewsProvider');

export default interface INewsProvider {
  getList(filters: NewsFiltersModel): Promise<ListResponse<NewsListItemDto>>;
  getBySlug(slug: string): Promise<NoveltyDto>;
  like(id: Id, status: boolean): Promise<void>;
}
