import { injectable } from 'inversify';
import IAuthNotifiable from '../UserProvider/IAuthNotifiable';
import IAuthProvider from './IAuthProvider';

type ChangeCallback = (status: boolean) => void;

@injectable()
export default class ApiAuthProvider implements IAuthProvider, IAuthNotifiable {
  private callbacks: ChangeCallback[];
  private status: boolean = false;
  constructor() {
    this.callbacks = [];
  }

  public async isAuthenticated(): Promise<boolean> {
    return this.status;
  }

  public on(event: 'change', callback: ChangeCallback): void {
    this.callbacks.push(callback);
  }

  public notify(status: boolean): void {
    this.status = status;
    this.callbacks.forEach(x => x(status));
  }
}
