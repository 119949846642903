import UiTypes from './constants';

const setError = (name: string, state: boolean) => ({
  type: UiTypes.SET_ERROR,
  payload: { name, state },
});

export const setLoader = (name: string, state: boolean) => ({
  type: UiTypes.SET_LOADER,
  payload: { name, state },
});

export const setModal = (name: string, opened: boolean, visible?: boolean) => ({
  type: UiTypes.SET_MODAL,
  payload: { name, opened, visible },
});
