import { injectable } from 'inversify';
import { NotificationDto } from '../../models/notifications/NotificationDto';
import INotificationsProvider from './INotificationsProvider';

@injectable()
export default class MockNotificationsProvider
  implements INotificationsProvider {
  public async *getAll(): AsyncGenerator<NotificationDto[]> {
    return [];
  }

  public async markViewed(id: string): Promise<void> {
    return;
  }

  public async markViewedAll(): Promise<void> {
    return;
  }

  public on(
    event: 'new',
    callback: (notification: NotificationDto) => void
  ): void {}
}
