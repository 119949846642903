import Id from '@project-m/core/entities/common/Id';
import { NotificationDto } from '../../models/notifications/NotificationDto';

export const SINotificationsProvider = Symbol.for('INotificationsProvider');

export default interface INotificationsProvider {
  on(event: 'new', callback: (notification: NotificationDto) => void): void;
  getAll(): AsyncGenerator<NotificationDto[]>;
  markViewed(id: Id): Promise<void>;
  markViewedAll(): Promise<void>;
}
