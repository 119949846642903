import Id from '@project-m/core/entities/common/Id';
import ListResponse from 'infrastructure/models/common/ListResponse';
import EventDto from '../../models/events/EventDto';
import EventFilters from '../../models/events/EventFilters';
import EventListItemDto from '../../models/events/EventListItemDto';

export const SIEventsProvider = Symbol.for('IEventsProvider');

export interface EventRegistrationData {
  name: string;
  surname: string;
  email: string;
  phone: string;
  id: number;
}

export type PreparedFilters = EventFilters & { page: number; count: number };

export default interface IEventsProvider {
  getList(filters: PreparedFilters): Promise<ListResponse<EventListItemDto>>;
  getBySlug(slug: string): Promise<EventDto>;
  like(id: Id, status: boolean): Promise<void>;
  register(eventId: Id, data: EventRegistrationData): Promise<void>;
}
