import RegistrationModel from '@project-m/core/entities/auth/RegistrationModel';
import { injectable } from 'inversify';
import IUserProvider from './IUserProvider';

@injectable()
export default class MockUserProvider implements IUserProvider {
  public async login(email: string, password: string): Promise<void> {
    return;
  }

  public async register(model: RegistrationModel): Promise<void> {
    return;
  }

  public async resetPassword(email: string): Promise<void> {
    return;
  }

  public async logOut(): Promise<void> {
    return;
  }

  public continuePasswordReset(token: string, password: string): Promise<void> {
    return Promise.resolve();
  }
}
