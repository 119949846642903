import React from 'react';
import LoadingContext from '../../../../../contexts/LoadingContext';
import useLoadMore from '../../../../../hooks/useLoadMore';
import CourseListItemDto from '../../../../../infrastructure/models/courses/CourseListItemDto';
import IProfileProvider, {
  SIProfileProvider,
} from '../../../../../infrastructure/providers/ProfileProvider/IProfileProvider';
import container from '../../../../../ioc';

import CourseItem from '../../../../shared/CourseItem';
import ListView from '../../../../shared/ListView';

import styles from './Purchases.module.sass';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const Purchases = () => {
  const {
    data: trainings,
    loading,
    canLoadMore,
    loadMore,
    empty,
  } = useLoadMore<CourseListItemDto>(() => provider().getCourses());

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ListView
        className={styles.purchases}
        loading={loading}
        canLoadMore={canLoadMore}
        onMore={loadMore}
        empty={empty}
      >
        {trainings.map(training => (
          <CourseItem training={training} key={training.id} />
        ))}
      </ListView>
    </LoadingContext.Provider>
  );
};

export default Purchases;
