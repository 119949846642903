import {StoreApi} from 'store/types';
import IAuthProvider, {
  SIAuthProvider,
} from '../../../infrastructure/providers/AuthProvider/IAuthProvider';
import IInitializationProvider, {
  SIInitializationProvider,
} from '../../../infrastructure/providers/InitializationProvider/IInitializationProvider';
import container from '../../../ioc';
import {logInDone, logOut} from '../auth/actions';
import {loadProfile, setProfile} from '../profile/actions';
import {initDone} from './actions';
import {InitActionTypes} from './constants';
import {InitStartAction} from './types';

const init = (store: StoreApi, action: InitStartAction) => {
  container
    .get<IInitializationProvider>(SIInitializationProvider)
    .init()
    .then(() => store.dispatch(initDone()));

  container.get<IAuthProvider>(SIAuthProvider).on('change', (status) => {
    console.log(store.getState());
    if (status === store.getState().auth.authorized) {
      return;
    }
    store.dispatch(status ? logInDone() : logOut());
    store.dispatch(status ? loadProfile() : setProfile());
  });
};

const initHandlers = {
  [InitActionTypes.START]: init,
};

export default initHandlers;
