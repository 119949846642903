import React from 'react';

import styles from './VideoBlock.module.sass';

interface VideoBlockProps {
  video: string;
}

const VideoBlock: React.FC<VideoBlockProps> = ({ video }) => {
  return (
    <div className={styles.videoBlock}>
      <video src={video} controls />
    </div>
  );
};

export default VideoBlock;
