import { serializeListItem } from '../../NewsProvider/ApiNewsProvider';
import { AbstractBlock } from './AbstractBlock';

export class NewsBlock extends AbstractBlock {
  public serialize(data: Record<string, any>): Record<string, any> {
    return {
      ...data,
      news: data.news.map(serializeListItem),
    };
  }
}
