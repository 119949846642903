import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';
import Input from 'components/forms/Widgets/Input';
import Select from 'components/forms/Widgets/Select';
import Button from 'components/shared/Button';
import ContentPage from 'components/shared/ContentPage';
import CourseItem from 'components/shared/CourseItem';
import EventItem from 'components/shared/EventItem';
import ListView from 'components/shared/ListView';
import NewsItem from 'components/shared/NewsItem';
import LoadingContext from 'contexts/LoadingContext';
import { Location } from 'history';
import useLoadMore from 'hooks/useLoadMore';
import ISearchProvider, {
  SISearchProvider,
} from 'infrastructure/providers/SearchProvider/ISearchProvider';
import container from 'ioc';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';

import styles from './Search.module.sass';

interface SearchProps {
  location: Location;
}

type SearchCategories = 'news' | 'events' | 'courses';

interface SearchParams {
  query: string;
  type: SearchCategories;
}

const categoryOptions: ChoicesType = [
  ['news', 'Новини'],
  ['events', 'Події'],
  ['courses', 'Курси'],
];

const provider = () => container.get<ISearchProvider>(SISearchProvider);
let source = provider().getResults({ query: '', type: 'news' });

const Search: React.FC<SearchProps> = ({ location }) => {
  const { search } = location;
  const [filters, setFilters] = useState<SearchParams>({
    query: '',
    type: 'news',
  });
  const [payload, setPayload] = useState<SearchParams>({
    query: '',
    type: 'news',
  });

  const {
    data,
    canLoadMore,
    loadingMore,
    loading,
    loadMore,
    empty,
  } = useLoadMore<any>(() => source, payload);

  const handleTermChange = (value: string) =>
    setFilters({ ...filters, query: value });

  const handleCategoryChange = (value: SearchCategories) =>
    setFilters({ ...filters, type: value });

  const handleSearch = () => {
    source = provider().getResults(filters);
    setPayload(filters);
  };

  useEffect(() => {
    const { query, type } = queryString.parse(search);

    if (query || type) {
      setFilters({
        query: (query as string) || '',
        type: (type as SearchCategories) || 'news',
      });
    }
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ContentPage headline={'Пошук'}>
        <div className={styles.search__top}>
          <Input
            value={filters.query || ''}
            onChange={handleTermChange}
            required={false}
            label={'Що шукаємо?'}
            className={styles.search__input}
            error={null}
          />
          <Select
            value={filters.type}
            choices={categoryOptions}
            onChange={value => handleCategoryChange(value as SearchCategories)}
            required={false}
            label={'Категорія'}
            className={styles.search__select}
            error={null}
          />
          <Button
            filled
            color={'blue'}
            className={styles.search__button}
            onClick={handleSearch}
          >
            Пошук
          </Button>
        </div>
        <ListView
          canLoadMore={canLoadMore}
          loading={loadingMore}
          onMore={loadMore}
          empty={empty}
        >
          {!loading &&
            data.map(item => {
              if (filters.type === 'news') {
                return <NewsItem newsItem={item} />;
              }

              if (filters.type === 'events') {
                return <EventItem event={item} />;
              }

              if (filters.type === 'courses') {
                return <CourseItem training={item} />;
              }

              return false;
            })}
        </ListView>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default Search;
