import React, { useState } from 'react';

import { clns } from '../../../helpers/strings';
import HTMLContent from '../HTMLContent';

import styles from './Question.module.sass';

interface QuestionProps {
  question: string;
  answer: string;
  className?: string;
}

const Question: React.FC<QuestionProps> = ({ question, answer, className }) => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div
      className={clns([
        styles.question,
        opened && styles.question_opened,
        className,
      ])}
    >
      <div className={styles.question__left}>
        <div
          className={styles.question__icon}
          onClick={() => setOpened(!opened)}
        />
      </div>
      <div className={styles.question__content}>
        <div
          className={styles.question__question}
          onClick={() => setOpened(!opened)}
        >
          {question}
        </div>
        <div className={styles.question__answer}>
          <HTMLContent content={answer} />
        </div>
      </div>
    </div>
  );
};

export default Question;
