import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import MAIN_LINKS from 'constants/links/mainLinks';

import Burger from '../../shared/Burger';
import UserActions from '../../shared/UserActions';
import Search from './blocks/Search';

import { setModal } from 'store/modules/ui/actions';

import logo from '../../../assets/images/logo.png';
import styles from './Header.module.sass';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (container.current) {
      let initial = window.scrollY;
      let hidden = false;

      const handler = () => {
        const current = window.scrollY;

        if (current > initial && !hidden) {
          container.current!.style.transform = 'translateY(-100%)';
          hidden = true;
        } else if ((current < initial && hidden) || current <= 0) {
          container.current!.style.transform = 'translateY(0%)';
          hidden = false;
        }

        initial = current;
      };

      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  }, [container.current]);

  return (
    <header className={styles.header}>
      <div className={styles.header__content} ref={container}>
        <div className={styles.header__left}>
          <Link to={'/'} className={styles.header__logo}>
            <img
              src={logo}
              alt={'logo'}
              className={styles.header__logo_image}
            />
            <div className={styles.header__logo_text}>
              Українська Академія <br /> Сімейного Лікаря
            </div>
          </Link>
          {/*<Languages className={styles.header__languages} />*/}
        </div>
        <div className={styles.header__links}>
          {MAIN_LINKS.map(([link, text]) => (
            <NavLink
              to={link}
              className={styles.header__link}
              activeClassName={styles.header__link_active}
              key={link}
            >
              {text}
            </NavLink>
          ))}
        </div>
        <div className={styles.header__actions}>
          <Search className={styles.header__search} />
          <UserActions className={styles.header__userActions} />
          <Burger
            opened={false}
            className={styles.header__burger}
            onClick={() => dispatch(setModal('menu', true))}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
