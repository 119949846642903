import React from 'react';
import { useSelector } from 'react-redux';
import { match } from 'react-router';

import NotAuthorized from 'components/errors/NotAuthorized';
import ContentPage from 'components/shared/ContentPage';
import CourseFinished from './blocks/CourseFinished';
import CourseLection from './blocks/CourseLection';
import CourseLectionsList from './blocks/CourseLectionsList';
import useCourse from './state';

import { isAuthorized } from 'store/modules/auth/selectors';

import styles from './Course.module.sass';
import CourseLectionsContext from './CourseLectionsContext';

interface CourseLectionsProps {
  match: match<{ slug: string }>;
}

const CourseLections: React.FC<CourseLectionsProps> = ({
  match: {
    params: { slug },
  },
}) => {
  const authorized = useSelector(isAuthorized);
  const data = useCourse(slug);
  const { course, currentLection, finished } = data;

  return authorized ? (
    course && (
      <CourseLectionsContext.Provider value={data}>
        <ContentPage
          className={styles.course}
          headline={course?.name}
          backLink={{ link: `/courses/${slug}`, name: 'Про курс' }}
        >
          {finished ? <CourseFinished /> : currentLection && <CourseLection />}
          <CourseLectionsList />
        </ContentPage>
      </CourseLectionsContext.Provider>
    )
  ) : (
    <NotAuthorized />
  );
};

export default CourseLections;
