import CourseListItemDto from '../../models/courses/CourseListItemDto';
import EventListItemDto from '../../models/events/EventListItemDto';
import NewsListItemDto from '../../models/news/NewsListItemDto';

export interface SearchQuery {
  type: 'news' | 'events' | 'courses';
  query: string;
}

export type SearchResult =
  | EventListItemDto[]
  | NewsListItemDto[]
  | CourseListItemDto[];

export const SISearchProvider = Symbol.for('ISearchProvider');

export default interface ISearchProvider {
  getResults(query: SearchQuery): AsyncGenerator<SearchResult>;
}
