import React from 'react';
import { clns } from 'helpers/strings';
import styles from './Button.module.sass';

export interface ButtonProps {
  color?: 'black' | 'red' | 'white' | 'blue' | 'lightBlue';
  withBorders?: boolean;
  filled?: boolean;
  onClick?: () => void;
  children?: any;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button = React.memo<ButtonProps>(
  ({ loading, children, onClick, className, disabled, ...buttonStyles }) => {
    return (
      <div
        className={clns([
          styles.button,
          buttonStyles.withBorders && styles.borders,
          buttonStyles.filled && styles.filled,
          styles[buttonStyles.color || 'red'],
          disabled && styles.disabled,
          loading && styles.loading,
          className,
        ])}
        onClick={() => !disabled && !loading && onClick && onClick()}
        style={{textAlign:"center"}}
      >
        <div className={styles.button__text}>
          {loading ? 'Завантаження...' : children}
        </div>
      </div>
    );
  }
);

export default Button;
