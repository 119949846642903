import React, {useEffect, useState} from 'react';
import {
  SecondarySignUpSchema,
  SecondarySignUpValueModel,
} from 'schemas/SignUpSchema';

import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Select from 'components/forms/Widgets/Select';
import {StepProps} from '../StepProps';
import {getSignUpData} from 'store/modules/signUp/selectors';
import pick from 'lodash/pick';
import {useSelector} from 'react-redux';

const Second: React.FC<any> = ({onChange, onSubmit}) => {
  const [{value, errors}, setData] = useState(
    SecondarySignUpSchema.toInternal(
      pick(
        useSelector(getSignUpData),
        Object.keys(SecondarySignUpSchema.fields)
      ) as SecondarySignUpValueModel
    )
  );

  useEffect(() => {
    console.log(value);
    onChange({value, errors});
  }, [value, errors]);

  return (
    <Form
      schema={SecondarySignUpSchema}
      value={value}
      errors={errors}
      onChange={setData}
      onSubmit={onSubmit}
    >
      <Form.Row>
        <Select.Connected name={'city'} label={'Місто'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'email'} label={'Email'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'phone'} label={'Номер телефона'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'password'} label={'Пароль'} type={'password'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected
          name={'repeatPassword'}
          label={'Повторіть пароль'}
          type={'password'}
        />
      </Form.Row>
      <Form.Button>Далі</Form.Button>
    </Form>
  );
};

export default Second;
