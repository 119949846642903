import { SchemaDataType } from '@project-m/schemas/dist/types';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Button from 'components/shared/Button';
import Modal, { ModalConsumerProps } from 'components/shared/Modal';
import { getLocation, replace } from 'connected-react-router';
import { Location } from 'history';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpdatePasswordSchema from 'schemas/UpdatePasswordSchema';
import { setModal } from 'store/modules/ui/actions';
import { AppState } from 'store/reducer';
import IUserProvider, {
  SIUserProvider,
} from '../../../../infrastructure/providers/UserProvider/IUserProvider';
import container from '../../../../ioc';

import styles from './UpdatePassword.module.sass';

const provider = () => container.get<IUserProvider>(SIUserProvider);

const UpdatePassword: React.FC<ModalConsumerProps> = ({ visible }) => {
  const [updated, setUpdated] = useState<boolean>(false);
  const [{ value, errors }, setData] = useState<SchemaDataType<any>>(
    UpdatePasswordSchema.generateEmpty()
  );
  const dispatch = useDispatch();
  const { search } = useSelector<AppState>(getLocation) as Location;
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);

  const handleSubmit = useCallback(() => {
    if (parsedSearch.updatepassword) {
      provider()
        .continuePasswordReset(
          parsedSearch.updatepassword as string,
          value.password
        )
        .then(() => {
          setUpdated(true);
          dispatch(replace({ search: '' }));
        });
    }
  }, [parsedSearch.updatepassword, dispatch, value]);

  const handleLogIn = useCallback(() => {
    dispatch(setModal('updatePassword', false));
    dispatch(setModal('login', true));
  }, [dispatch]);

  return (
    <Modal name={'updatePassword'} visible={visible}>
      <div className={styles.wrapper}>
        <div className={styles.headline}>
          {updated ? 'Пароль успішно оновлено' : 'Оновлення пароля'}
        </div>
        {updated ? (
          <div>
            <Button filled color={'blue'} onClick={handleLogIn}>
              Увійти
            </Button>
          </div>
        ) : (
          <Form
            schema={UpdatePasswordSchema}
            value={value}
            errors={errors}
            onChange={setData}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Input.Connected
                name={'password'}
                label={'Пароль'}
                type={'password'}
              />
            </Form.Row>
            <Form.Row>
              <Input.Connected
                name={'repeatPassword'}
                label={'Повторіть пароль'}
                type={'password'}
              />
            </Form.Row>
            <Form.Button />
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default UpdatePassword;
