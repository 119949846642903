import Id from '@project-m/core/entities/common/Id';
import { clns } from 'helpers/strings';
import { AnswerDto } from 'infrastructure/models/courses/common';
import React, { useCallback } from 'react';

import styles from './CourseTesting.module.sass';

export enum AnswerStatuses {
  RIGHT,
  WRONG,
  CHECKED,
  UNCHECKED,
}

interface CourseTestingAnswerProps extends AnswerDto {
  onClick: (id: Id) => void;
  checked: boolean;
  status: AnswerStatuses;
}

const CourseTestingAnswer: React.FC<CourseTestingAnswerProps> = ({
  id,
  text,
  onClick,
  status,
}) => {
  const handleClick = useCallback(() => onClick(id), [id]);

  return (
    <div className={styles.answer} onClick={handleClick}>
      <div
        className={clns([
          styles.answerDot,
          status === AnswerStatuses.CHECKED && styles.checked,
          status === AnswerStatuses.WRONG && styles.wrong,
          status === AnswerStatuses.RIGHT && styles.right,
        ])}
      />
      <div className={styles.answerLabel}>{text}</div>
    </div>
  );
};

export default CourseTestingAnswer;
