import React, {useEffect, useState} from 'react';

import PDFViewer from 'pdf-viewer-reactjs';
import axios from 'axios';

function AgreementPage() {
  const [link, setLink] = useState('');

  useEffect(() => {
    async function getAgreement() {
      try {
        const res = await axios.get(
          'http://cme-cpd.com.ua:1337/admin/api/getAgreement'
        );

        setLink(res.data.link);
        console.log(res.data.link);
      } catch (error) {
        console.log(error);
      }
    }

    getAgreement();
  }, []);

  return (
    <PDFViewer
      document={{
        url: link,
      }}
    />
  );
}

export default AgreementPage;
