import Checkbox from 'components/forms/Widgets/Checkbox';
import DateInput from 'components/forms/Widgets/DateInput';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import List from 'components/forms/Widgets/List';
import ProfessionalActivitySchema from 'schemas/ProfessionalActivitySchema';
import React from 'react';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';

const ProfessionalSettings = () => {
  const { value, errors, onChange, onSave } = useUserSettings(
    'experience',
    ProfessionalActivitySchema
  );
  return (
    <Form
      schema={ProfessionalActivitySchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >
      <Form.Row>
        <Form.Col>
          <Select.Connected
            name={'startYear'}
            label={'Рік початку лікарської діяльності'}
          />
        </Form.Col>
        <Form.Col>
          <Select.Connected
            name={'qualificationCategory'}
            label={'Кваліфікаційна категорія'}
          />
        </Form.Col>
      </Form.Row>
      <List.Connected
        name={'placesOfWork'}
        label={'Місця роботи'}
        addMoreText={'Додати місце роботи'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'name'} label={'Місце роботи'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'region'} label={'Регіон'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected name={'district'} label={'Район'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected name={'city'} label={'Населений пункт'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'street'} label={'Вулиця'} />
          </Form.Col>
          <Form.Col>
            <Input.Connected name={'building'} label={'Будинок'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'position'} label={'Посада'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected
              name={'specialization'}
              label={'Спеціальність за якою ви працюєте'}
            />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'startYear'} label={'Рік початку'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'endYear'} label={'Рік закінчення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Checkbox.Connected
              name={'mainJob'}
              label={'Основне місце роботи'}
            />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'misUsage'}
        label={'Застосування МІС на робочому місці'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'name'} label={'Назва МІС'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'freelance'}
        label={'Головний позаштатний спеціаліст'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Select.Connected name={'specialization'} label={'Спеціальність'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дата Закінчення'} />
          </Form.Col>
          <Form.Col>
            <Checkbox.Connected name={'dueNow'} label={'Поточний час'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <Form.Button />
    </Form>
  );
};

export default ProfessionalSettings;
