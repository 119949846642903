import React from 'react';

interface LoadingContextValue {
  loading: boolean;
}

const LoadingContext = React.createContext<LoadingContextValue>({
  loading: false,
});

export default LoadingContext;
