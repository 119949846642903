import React, { useCallback, useEffect, useRef, useState } from 'react';

import CoursePlayerProgress from 'components/pages/Courses/CourseLections/blocks/CoursePlayer/CoursePlayerProgress';
import ReactPlayer from 'react-player';
import { clns } from 'helpers/strings';
import exitFullscreen from 'assets/images/exitscreen.svg';
import fullscreen from 'assets/images/fullscreen.svg';
import pause from 'assets/images/pause.svg';
import play from 'assets/images/play.svg';
import styles from './CoursePlayer.module.sass';

function getTimeString(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const minutesString = String(minutes).padStart(2, '0');
  const secondsString = String(seconds - minutes * 60).padStart(2, '0');
  return `${minutesString}:${secondsString}`;
}

interface ProgressModel {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

interface CoursePlayerProps {
  url: string;
  onFinish: () => void;
  contentType: string;
}

const CoursePlayer: React.FC<CoursePlayerProps> = ({
  url,
  onFinish,
  contentType,
}) => {
  const playerBlock = useRef<null | HTMLDivElement>(null);
  const videoPlayer = useRef<null | ReactPlayer>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [fullscreenStatus, setFullscreenStatus] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);
  const audio = contentType === 'audio';

  const handlePlay = useCallback(() => setPlaying(!playing), [playing]);
  const handleProgress = useCallback(
    (state: ProgressModel) => setProgress(Math.round(state.playedSeconds)),
    []
  );
  const handleDuration = useCallback(
    (videoDuration: number) => setDuration(Math.round(videoDuration)),
    []
  );
  const handleFullscreen = useCallback(() => {
    const block = playerBlock.current;

    if (block) {
      if (fullscreenStatus) {
        document.exitFullscreen().then(() => setFullscreenStatus(false));
      } else {
        block.requestFullscreen().then(() => setFullscreenStatus(true));
      }
    }
  }, [playerBlock.current, fullscreenStatus]);
  const handleSeek = useCallback(
    (amount: number) => {
      if (videoPlayer.current) {
        videoPlayer.current.seekTo(amount);
      }
    },
    [videoPlayer.current]
  );

  useEffect(() => {
    const handler = () => {
      setFullscreenStatus(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handler);
    return () => document.removeEventListener('fullscreenchange', handler);
  }, [fullscreenStatus]);

  useEffect(() => {
    const block = playerBlock.current;

    if (block) {
      let timeout: null | NodeJS.Timeout = null;

      const handler = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        setShowControls(true);
        timeout = setTimeout(() => {
          setShowControls(false);
        }, 2000);
      };

      block.addEventListener('mousemove', handler);
      return () => block.removeEventListener('mousemove', handler);
    }
  }, [playerBlock.current]);

  return (
    <div
      className={clns([
        styles.player,
        (showControls || audio) && styles.showControls,
      ])}
      ref={playerBlock}
    >
      <ReactPlayer
        url={url}
        playing={playing}
        width={'100%'}
        height={audio ? 55 : 550}
        onEnded={onFinish}
        onProgress={handleProgress}
        onDuration={handleDuration}
        ref={videoPlayer}
      />
      <div className={styles.overlay}>
        <div className={styles.controls}>
          <CoursePlayerProgress
            duration={duration}
            current={progress}
            onSeek={handleSeek}
          />
          <div className={styles.actionIcon} onClick={handlePlay}>
            <img src={playing ? pause : play} alt={'play/pause'} />
          </div>
          <div className={styles.timeString}>
            {getTimeString(progress)}/{getTimeString(duration)}
          </div>
          {!audio && (
            <div className={styles.actionIcon} onClick={handleFullscreen}>
              <img
                src={fullscreenStatus ? exitFullscreen : fullscreen}
                alt={'play/pause'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePlayer;
