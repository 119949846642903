import React, { useContext, useEffect } from 'react';
import useList, { ListModules } from 'hooks/useList';

import ContentPage from 'components/shared/ContentPage';
import EventItem from 'components/shared/EventItem';
import Filters from 'components/pages/Events/EventsList/Filters';
import LoadingContext from 'contexts/LoadingContext';
import MultiPageList from 'components/shared/MultiPageList';
import SeoContext from 'contexts/SeoContext';

const EventsList = () => {
  const {
    list: events,
    page,
    pages,
    goToPage,
    filters,
    setFilters,
    loading,
  } = useList(ListModules.EVENTS);

  const { setSeo } = useContext(SeoContext);
  useEffect(() => {
    setSeo({ title: 'Події' });
  }, []);

  console.log(events);

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ContentPage
        headline={'Події'}
        placeholder={false}
        filters={<Filters onChange={setFilters} initial={filters} />}
      >
        <MultiPageList page={page} pages={pages} goToPage={goToPage}>
          {events.map((event, i) => (
            <EventItem event={event} key={i} />
          ))}
        </MultiPageList>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default EventsList;
