import DateInput from 'components/forms/Widgets/DateInput';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import MainSettingsSchema from 'schemas/MainSettingsSchema';
import React from 'react';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';

const MainSettings = () => {
  const {value, errors, onChange, onSave} = useUserSettings('main', MainSettingsSchema);

  return (
    <Form
      schema={MainSettingsSchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >
      <Form.Row>
        <Form.Col>
          <Input.Connected name={'name'} label={"Ім'я"} />
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'surname'} label={'Прізвище'} />
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'email'} label={'Email'} />
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'password'} label={'Пароль'} />
          <span style={{position: 'absolute', bottom: '-13px', fontSize: '10px', color: '#FC6668'}}>
            *Мін 8 символів
          </span>
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'patronymic'} label={'По батькові'} />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Select.Connected name={'gender'} label={'Стать'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'country'} label={'Країна проживання'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'city'} label={'Місто'} />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <DateInput.Connected name={'dateOfBirth'} label={'Дата народження'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'specialization'} label={'Основна спеціалізація'} />
        </Form.Col>
      </Form.Row>
      <Form.Button>Зберегти</Form.Button>
    </Form>
  );
};

export default MainSettings;
