import isObject from 'lodash/isObject';
import queryString from 'query-string';

function isJson(value: string) {
  return /^[\],:{}\s]*$/.test(
    value
      .replace(/\\["\\\/bfnrtu]/g, '@')
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        ']'
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
  );
}

function parseSearchValue(value: string[] | string | null | undefined) {
  if (typeof value === 'string') {
    if (/^[0-9]*$/.test(value)) {
      return Number(value);
    }

    if (isJson(value)) {
      return JSON.parse(value);
    }
  }
  return value;
}

export const parseSearch = (search: string) => {
  const parsed = queryString.parse(search);

  return Object.fromEntries(
    Object.entries(parsed).map(([key, value]) => [key, parseSearchValue(value)])
  );
};

function proceedSearchValue(value: any): any {
  if (isObject(value)) {
    return JSON.stringify(value);
  }

  return value;
}

export const makeSearch = (obj: Record<string, any>): string => {
  return Object.entries(obj)
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${proceedSearchValue(value)}`)
    .join('&');
};
