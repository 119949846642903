import 'moment/locale/uk';

import React, {useContext, useEffect, useState} from 'react';

import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import {Link} from 'react-router-dom';
import MainSettingsSchema from 'schemas/MainSettingsSchema';
import axios from 'axios';
import moment from 'moment';
import styles from './CourseFinished.module.sass';
import useUserSettings from 'hooks/useUserSettings';

const getPoints = async (userId: any, points: any) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/getPointsForLink',
      {userId, points}
    );
  } catch (error) {
    console.log(error);
  }
};

const CourseFinished = () => {
  const {course} = useContext(CourseLectionsContext);

  const [lecture, setLink] = useState('');
  const [isExists, setExist] = useState(false);
  const {value} = useUserSettings('main', MainSettingsSchema);

  useEffect(() => {
    async function getLink() {
      try {
        const res = await axios.post(
          `http://cme-cpd.com.ua:1337/admin/api/getLink`,
          {id: course?.id}
        );

        setLink(res.data);
        getCertificate(
          res.data.certificate,
          moment(Date.now()).format('LL'),
          res.data.reward,
          //@ts-ignore
          `${value?.name} ${value?.surname}`,
          // @ts-ignore
          JSON.parse(localStorage.getItem('userId')),
          // @ts-ignore
          value?.city,
          // @ts-ignore
          course?.name,
          // @ts-ignore
          course?.id
        );
      } catch (error) {
        console.log(error);
      }
    }

    getLink();
  }, []);

  const getCertificate = async (
    url: string,
    date: string,
    points: number,
    name: string,
    userId: string,
    city: number,
    title: string,
    number: any
  ) => {
    try {
      const res = await axios.post(
        'http://cme-cpd.com.ua:1337/admin/api/createCrt',
        {
          url,
          date,
          points,
          name,
          userId,
          city,
          title,
          number
        }
      );

      if (res.data === 'exist') setExist(true);
    } catch (error) {
      console.log(error);
    }
  };

  //Ви вже отримали сертифікат за успішне проходження цього курсу.
  return (
    <div className={styles.wrapper}>
      <div className={styles.headline}>
        {isExists
          ? 'Ви вже отримали сертифікат за успішне проходження цього курсу.'
          : `Вітаємо! Ви отримали сертифікат за успішне проходження курсу "
        ${course?.name}"`}
      </div>
      <div className={styles.text}>
        У Вас є можливість переглянути клінічні випадки по даній тематиці з
        сучасними схемами лікування.{' '}
      </div>
      <div className={styles.text} style={{margin: '20px 0'}}>
        {/* @ts-ignore */}
        {lecture.clinicalLink !== '' && (
          <a
            // @ts-ignore
            href={lecture.clinicalLink}
            className={styles.link}
            style={{
              color: '#fff',
              background: '#FF7070',
              padding: '10px 20px',
              borderRadius: '10px',
              textDecoration: 'none',
            }}
            onClick={() => {
              alert(
                'Ви переходите на сторінку, яка може містити комерційний інтерес'
              );
              getPoints(
                // @ts-ignore
                JSON.parse(localStorage.getItem('userId')),
                // @ts-ignore
                lecture.pointsForLink
              );
            }}
            target='_blank'
          >
            Переглянути клінічні випадки.
          </a>
        )}
      </div>
      <div className={styles.text}>
        Щоб перейти до сертифікатів,{' '}
        <Link to={'/account/certificates'} className={styles.link}>
          натисніть сюди
        </Link>
      </div>
    </div>
  );
};

export default CourseFinished;
