import IPageDataProvider, {
  SIPageDataProvider,
} from 'infrastructure/providers/PageDataProvider/IPageDataProvider';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../shared/Button';
import ExpertModal from '../../base/Modals/ExpertModal';
import {Link} from 'react-router-dom';
import MAIN_LINKS from 'constants/links/mainLinks';
import container from 'ioc';
import {isAuthorized} from 'store/modules/auth/selectors';
import {packArrayBy} from 'helpers/arrays';
import {setModal} from 'store/modules/ui/actions';
import styles from './Footer.module.sass';

const links = packArrayBy<string[]>(MAIN_LINKS, 2);

const Footer: React.FC = () => {
  const [data, setData] = useState<Record<string, any>>({});
  const provider = useCallback(() => container.get<IPageDataProvider>(SIPageDataProvider), []);
  const dispatch = useDispatch();
  const authorized = useSelector(isAuthorized);

  const [isOpen, setisOpen] = useState<boolean>(false);

  useEffect(() => {
    provider()
      .getBlock('footer')
      .then(setData)
      // tslint:disable-next-line:no-console
      .catch(console.log);
  }, []);

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footer__content}>
          <div className={styles.footer__info}>
            <div className={styles.footer__text}>{data.text}</div>
            <div className={styles.footer__copy}>
              © {new Date().getFullYear()} {data.copyright}
            </div>
          </div>
          <div className={styles.footer__links}>
            {links.map((column, i) => (
              <div className={styles.footer__column} key={i}>
                {column.map(([link, name]) => (
                  <Link to={link} key={link} className={styles.footer__link}>
                    {name}
                  </Link>
                ))}
              </div>
            ))}
          </div>
          {!authorized && (
            <>
              <Button
                color={'white'}
                withBorders
                onClick={() => dispatch(setModal('signUp', true))}
              >
                {data.buttonText}
              </Button>
              <Button color={'white'} withBorders onClick={() => setisOpen(!isOpen)}>
                Хочу стати Експертом
              </Button>
            </>
          )}
        </div>
      </div>
      {isOpen && <ExpertModal setIsOpen={setisOpen}></ExpertModal>}
    </>
  );
};

export default Footer;
