import React from 'react';
import { clns } from '../../../helpers/strings';
import IconInfo from '../IconInfo';

import styles from './IconsRow.module.sass';

interface IconsRowProps {
  items: Array<{ icon: string; text: string }>;
  noBg?: boolean;
  className?: string;
}

const IconsRow: React.FC<IconsRowProps> = ({ items, className, noBg }) => {
  return (
    <div
      className={clns([
        styles.iconsRow,
        noBg && styles.iconsRow_noBg,
        className,
      ])}
    >
      <div className={styles.iconsRow__content}>
        {items.map((item, i) => (
          <IconInfo direction={'column'} {...item} key={i} />
        ))}
      </div>
    </div>
  );
};

export default IconsRow;
