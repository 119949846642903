import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import Button from 'components/shared/Button';
import React, { useContext } from 'react';

import styles from './CourseTesting.module.sass';

interface CourseStartTestingProps {
  onStart: () => void;
}

const CourseTestingStart: React.FC<CourseStartTestingProps> = ({ onStart }) => {
  const { currentLection } = useContext(CourseLectionsContext);
  const {
    name: title,
    retryCount: retries,
    questions,
    testDuration: time,
  } = currentLection!;
  return (
    <>
      <div className={styles.main}>Іспит з навчального курсу “{title}”</div>
      <div className={styles.content}>
        <div className={styles.infoLine}>
          Кількість запитань в іспиту: {questions.length}
        </div>
        <div className={styles.infoLine}>Час складання іспиту: {time} хв</div>
        <div className={styles.infoLine}>
          Залишилося спроб скласти іспит: {retries}
        </div>
        <div className={styles.infoLine}>
          Сума балів залежить від кількості правильних відповідей на запитання.
        </div>
      </div>
      <Button
        onClick={onStart}
        className={styles.button}
        color={'lightBlue'}
        filled
      >
        Почати іспит
      </Button>
    </>
  );
};

export default CourseTestingStart;
