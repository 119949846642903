import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from 'components/pages/Account/Profile.module.sass';

interface AccountNavProps {
  icons?: string[];
  links: string[][];
}

const AccountNav = React.memo<AccountNavProps>(({ icons, links }) => {
  return (
    <div className={styles.profile__links}>
      <div className={styles.profile__scrollable}>
        {links.map(([link, name], i) => (
          <NavLink
            to={`/account${link}`}
            className={styles.profile__link}
            activeClassName={styles.profile__link_active}
            exact={true}
            key={link}
          >
            {icons && (
              <img
                src={icons[i]}
                alt={'icon'}
                className={styles.profile__link_icon}
              />
            )}{' '}
            {name}
          </NavLink>
        ))}
      </div>
    </div>
  );
});

export default AccountNav;
