import React, { useCallback, useEffect, useState } from 'react';

import AlertsContext from 'contexts/AlertsContext';
import { clns } from 'helpers/strings';
import error from 'assets/images/close.svg';
import styles from './Alerts.module.sass';
import success from 'assets/images/tick.svg';
import warning from 'assets/images/warning.svg';

const icons = {
  error,
  success,
  warning,
};

type AlertType = 'warning' | 'success' | 'error';

interface AlertModel {
  content: string;
  type: AlertType;
  deathTime: number;
}

const AlertsProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertModel[]>([]);

  const fireAlert = useCallback(
    (content: string, type: AlertType, delay: number = 5000) => {
      setAlerts([...alerts, { content, type, deathTime: Date.now() + delay }]);
    },
    [alerts]
  );

  const fireSuccess = useCallback(
    (content: string, delay?: number) => {
      fireAlert(content, 'success', delay);
    },
    [fireAlert]
  );
  const fireWarning = useCallback(
    (content: string, delay?: number) => {
      fireAlert(content, 'warning', delay);
    },
    [fireAlert]
  );
  const fireError = useCallback(
    (content: string, delay?: number) => {
      fireAlert(content, 'error', delay);
    },
    [fireAlert]
  );

  useEffect(() => {
    if (alerts.length) {
      const timeout = setTimeout(() => {
        setAlerts(alerts.slice(1));
      }, alerts[0].deathTime - Date.now());

      return () => clearTimeout(timeout);
    }
  }, [alerts]);

  return (
    <AlertsContext.Provider value={{ fireSuccess, fireError, fireWarning }}>
      <div className={styles.alerts} style={{marginTop: '100px'}}>
        {alerts.map(({ content, type, deathTime }) => (
          <div key={deathTime} className={clns([styles.alert, styles[type]])}>
            <img src={icons[type]} alt={''} className={styles.icon} />
            <div className={styles.content}>{content}</div>
          </div>
        ))}
      </div>
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;
