import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import { clns } from 'helpers/strings';
import React, { useCallback } from 'react';

import styles from './Checkbox.module.sass';

interface CheckboxProps extends WidgetDataProps {
  label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  error,
  onChange,
  required,
  label,
}) => {
  const handleChange = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(checked);
    },
    [onChange]
  );
  return (
    <label className={styles.checkbox}>
      <input type={'checkbox'} checked={value} onChange={handleChange} />
      <div
        className={clns([
          styles.box,
          value && styles.checked,
          error && styles.error,
        ])}
      />
      <div className={clns([styles.label, error && styles.error])}>
        {label}
        {required ? ' *' : ''}
      </div>
    </label>
  );
};

export default withFormConnected(Checkbox);
