import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';

export interface LoginValueModel {
  email: string;
  password: string;
}

const LoginSchema = Schema.create<LoginValueModel>({
  email: Fields.email().required(),
  password: Fields.password().required(),
});

export default LoginSchema;
