import Id from '@project-m/core/entities/common/Id';

// maybe rename this file

export enum CourseType {
  AUDIO = 'audio',
  VIDEO = 'video',
  SLIDES = 'slides',
}

// dont know what should be here
export interface CourseFilters {
  occupation?: string;
  type?: string;
}

export interface LectionListItemDto {
  id: Id;
  title: string;
  duration: string;
  type: CourseType;
  videoWatched: boolean;
  testComplete: boolean;
  sortId: number;
}

export interface LectionDto {
  id: Id;
  name: string;
  type: CourseType;
  contentUrl: string;
  contentType: string;
  videoWatched: boolean;
  testComplete: boolean;
  testDuration: number; // timer for testing
  retryCount: number;
  lectionReward: number;
  questions: QuestionDto[];
  sortId: number;
}

export interface QuestionDto {
  id: Id;
  text: string;
  complete: boolean;
  answers: AnswerDto[];
}

export interface AnswerDto {
  id: Id;
  text: string;
}
