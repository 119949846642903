import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import TextArea from 'components/forms/Widgets/TextArea';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/modules/ui/actions';
import IFaqProvider, {
  SIFaqProvider,
} from '../../../infrastructure/providers/FaqProvider/IFaqProvider';
import container from '../../../ioc';
import AskSchema from '../../../schemas/AskSchema';
import styles from './AskForm.module.sass';

const provider = () => container.get<IFaqProvider>(SIFaqProvider);

const AskForm = () => {
  const [sent, setSent] = useState<boolean>(false);
  const [{ value, errors }, setData] = useState(AskSchema.generateEmpty());
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    const { name, surname, email, question } = value;
    dispatch(setLoader('global', true));
    provider()
      .makeFeedback({
        name,
        lastName: surname,
        email,
        question,
      })
      .then(() => setSent(true))
      .finally(() => dispatch(setLoader('global', false)));
  }, [value]);

  return (
    <div className={styles.askForm}>
      <div className={styles.askForm__headline}>Задати своє питання</div>
      {sent ? (
        <div>Дякуємо за звернення, ми відповімо вам найближчим часом</div>
      ) : (
        <Form
          schema={AskSchema}
          value={value}
          errors={errors}
          onChange={setData}
          onSubmit={handleClick}
        >
          <Form.Row>
            <Form.Col width={50}>
              <Form.Row>
                <Form.Col width={50}>
                  <Input.Connected name={'name'} label={"Ім'я"} />
                </Form.Col>
                <Form.Col width={50}>
                  <Input.Connected name={'surname'} label={'Прізвище'} />
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col width={100}>
                  <Input.Connected name={'email'} label={'Email'} />
                </Form.Col>
              </Form.Row>
            </Form.Col>
            <Form.Col width={50}>
              <Form.Row>
                <TextArea.Connected name={'question'} label={'Питання'} />
              </Form.Row>
            </Form.Col>
          </Form.Row>
          <Form.Button>Відправити</Form.Button>
        </Form>
      )}
    </div>
  );
};

export default AskForm;
