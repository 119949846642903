//@ts-nocheck
import IProfileProvider, {
  SIProfileProvider,
} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import { UserActions, UserSelectors } from 'store/modules/user';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Schema from '@project-m/schemas';
import { SchemaDataType } from '@project-m/schemas/dist/types';
import { UserState } from 'store/modules/user/reducer';
import axios from 'axios'
import container from 'ioc';
import useAlerts from 'hooks/useAlerts';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const getPoints = async (userId: any, points: any) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/forSettings',
      {userId, points}
    );
  } catch (error) {
    console.log(error);
  }
};

const getForCert = (value:any) => value.country === 'ukraine' ? 50 : 100; 
const getForDegree = (value:any) => value.country === 'ukraine' ? 50 : 100; 
const getForDistance = (value:any) => value.language !== 7 ? 20 : 15; 
const getForPublications = (value:any) => value.country === 'ukraine' ? 20 : 30; 
const getForTrainings = (value:any) => value.country === 'ukraine' ? 20 : 25; 

const fields = ['certificate','trainings','remote','publications','scienceDegree'];

const getExactPoints = (label:string,value:any) => {
  if(value !== undefined) {
    switch(label) {
      case 'publications':
      return getForPublications(value);
  
      case "remote":
        return getForDistance(value);
  
      case 'scienceDegree':
        return getForDegree(value);
  
      case 'trainings':
        return getForTrainings(value);
  
      case 'certificate':
        return getForCert(value);
  
      default:
        return 0;
    }
  }
}

const selectors = {
  main: UserSelectors.getMainData,
  education: UserSelectors.getEducationData,
  postgraduate: UserSelectors.getPostgraduateData,
  experience: UserSelectors.getExperienceData,
  teaching: UserSelectors.getTeachingData,
  innovative: UserSelectors.getInnovativeData,
};

export default function useUserSettings<T = any>(
  key: keyof UserState,
  schema: Schema
) {
  const initial = useSelector(selectors[key]);
  const dispatch = useDispatch();
  const { fireSuccess } = useAlerts();
  const [{ value, errors }, setData] = useState(schema.toInternal(initial));
  
  const onSave = useCallback(() => {   
    fields.forEach(key => {
      if(value[key] !== undefined) {
        value[key].map((d:any) => {
          // @ts-ignore
          getPoints(JSON.parse(localStorage.getItem('userId')),getExactPoints(key,d))
        })
      }
    })

    provider()
      .saveSettings(key, value)
      .then(() => {
        fireSuccess('Зміни збережені');
        dispatch(UserActions.setData({ [key]: value }));
      });
  }, [value, errors, fireSuccess]);

  useEffect(() => {
    setData(schema.toInternal(initial));
  }, [initial]);

  return { value, errors, onChange: setData, onSave };
}
