import React from 'react';
import { clns } from '../../../helpers/strings';
import HTMLContent from '../HTMLContent';
import styles from './ContentBlock.module.sass';

export interface ContentBlockProps {
  padding?: boolean;
  headline: string;
  textFull?: boolean;
  text?: string;
  list?: string[];
  right?: boolean;
  image?: string;
  imageDescription?: string;
  className?: string;
}

const ContentBlock: React.FC<ContentBlockProps> = ({
  padding,
  headline,
  children,
  textFull,
  text,
  list,
  right,
  image,
  imageDescription,
  className,
}) => {
  return (
    <div className={clns([className, styles.contentBlock])}>
      <div
        className={clns([
          styles.contentBlock__padding,
          !padding && styles.contentBlock__padding_no,
        ])}
      >
        <div className={styles.contentBlock__headline}>{headline}</div>
        <div className={styles.contentBlock__body}>
          {children ? (
            children
          ) : (
            <>
              <div className={styles.contentBlock__content}>
                <div
                  className={clns([
                    styles.contentBlock__text,
                    textFull && styles.contentBlock__text_full,
                  ])}
                >
                  {text && <HTMLContent content={text} />}
                </div>
                {list && (
                  <div className={styles.contentBlock__list}>
                    {list.map((item, i) => (
                      <div className={styles.contentBlock__listItem} key={i}>
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {image && (
        <div
          className={clns([
            styles.contentBlock__image,
            right && styles.contentBlock__image_right,
          ])}
        >
          <img src={image} alt={imageDescription || ''} />
        </div>
      )}
    </div>
  );
};

export default ContentBlock;
