import React, { useEffect, useState } from 'react';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';

import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';
import Select from 'components/forms/Widgets/Select';
import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import range from 'lodash/range';
import styles from './DateInput.module.sass';
import useDidUpdate from 'hooks/useDidUpdate';

interface DateInputProps extends WidgetDataProps {
  label: string;
  required?: boolean;
  className?: string;
}

const dayChoices: ChoicesType = range(1, 32).map(item => [item, String(item)]);
const monthChoices: ChoicesType = [
  [0, 'Січень'],
  [1, 'Лютий'],
  [2, 'Березень'],
  [3, 'Квітень'],
  [4, 'Травень'],
  [5, 'Червень'],
  [6, 'Липень'],
  [7, 'Серпень'],
  [8, 'Вересень'],
  [9, 'Жовтень'],
  [10, 'Листопад'],
  [11, 'Грудень'],
];
const currentYear = new Date(new Date().setFullYear(2040)).getFullYear();
const yearChoices: ChoicesType = range(140).map(item => [
  currentYear - item,
  String(currentYear - item),
]);

const DateInput: React.FC<DateInputProps> = ({
  value,
  error,
  label,
  required,
  className,
  onChange,
}) => {
  const [day, setDay] = useState<number | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);

  useDidUpdate(() => {
    if (day && month !== null && year) {
      const newDate = new Date(year, month, day).getTime();
      if (value !== newDate) {
        onChange(newDate);
      }
    } else if (value && [day, month, year].includes(null)) {
      onChange(null);
    }
  }, [day, month, year]);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      const incomeDay = date.getDate();
      const incomeMonth = date.getMonth();
      const incomeYear = date.getFullYear();

      if (incomeDay !== day) {
        setDay(incomeDay);
      }

      if (incomeMonth !== month) {
        setMonth(incomeMonth);
      }

      if (incomeYear !== year) {
        setYear(incomeYear);
      }
    }
  }, [value]);

  return (
    <WidgetWrapper
      label={label}
      error={error}
      required={required}
      className={className}
    >
      <div className={styles.input}>
        <div className={styles.col}>
          <Select
            value={day}
            onChange={setDay}
            error={null}
            choices={dayChoices}
            placeholder={'День'}
          />
        </div>
        <div className={styles.col}>
          <Select
            value={month}
            onChange={setMonth}
            error={null}
            choices={monthChoices}
            placeholder={'Місяць'}
          />
        </div>
        <div className={styles.col}>
          <Select
            value={year}
            onChange={setYear}
            error={null}
            choices={yearChoices}
            placeholder={'Рік'}
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default withFormConnected(DateInput);
