import React from 'react';
import {Link} from 'react-router-dom';

function RegistPage() {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100%',
        background: '#fff',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h2 style={{color: '#1E78ED'}}>Дякуємо за реєстрацію</h2>
      <Link to='/' style={{marginTop: '20px'}}>
        На головну
      </Link>
    </div>
  );
}

export default RegistPage;
