export function packArrayBy<T = any>(
  arr: T[],
  count: number = 2,
  newArray: T[][] = []
): T[][] {
  if (!arr.length) {
    return newArray;
  }

  return packArrayBy(arr.slice(count, arr.length + 1), count, [
    ...newArray,
    [...arr.slice(0, count)],
  ]);
}

export function shuffle(array: any[]): any[] {
  return [...array].sort(() => Math.random() - 0.5);
}

export function getValueByKeyFromTuple(key: any, arr: any[][]) {
  const el = arr.find(([currentKey, value]) => currentKey === key);
  return el && el[1];
}

export function dropEmpty(arr: any[]) {
  return arr.filter(item => !!item);
}

export function replaceOnId<T = any>(
  fullArray: T[],
  newValue: T,
  id: number
): T[] {
  return fullArray.map((item, i) => (i === id ? newValue : item));
}

export function removeOnId<T = any>(fullArray: T[], id: number): T[] {
  return fullArray.filter((item, i) => i !== id);
}
