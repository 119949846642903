import React from 'react';
import EventListItemDto from '../../../../../infrastructure/models/events/EventListItemDto';

import EventItem from '../../../../shared/EventItem';
import ListView from '../../../../shared/ListView';

interface EventsBlockProps {
  events: { list: EventListItemDto[] };
}

const EventsBlock = React.memo<EventsBlockProps>(({ events }) => {
  return (
    <ListView scrollable>
      {events.list.map(event => (
        <EventItem event={event} key={event.id} />
      ))}
    </ListView>
  );
});

export default EventsBlock;
