import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import { clns } from 'helpers/strings';
import React, { ChangeEvent, useCallback, useState } from 'react';

import remove from 'assets/images/cancel.svg';
import docIcon from 'assets/images/interface.svg';
import styles from './FileField.module.sass';

interface FileFieldProps extends WidgetDataProps {
  label: string;
  accept?: string;
  uploader: (file: File) => Promise<number>;
  multiple?: boolean;
}

interface FilePreviewModel {
  id: number;
  name: string;
  thumb?: string;
}

interface FilePreviewProps extends FilePreviewModel {
  onDelete: (id: number) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  name,
  thumb,
  onDelete,
  id,
}) => {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  return (
    <div className={styles.file}>
      <img src={thumb || docIcon} alt={'thumb'} className={styles.thumb} />
      <div className={styles.fileName}>{name}</div>
      <img
        onClick={handleDelete}
        alt={'delete'}
        src={remove}
        className={styles.remove}
      />
    </div>
  );
};

const FileField: React.FC<FileFieldProps> = ({
  value,
  onChange,
  required,
  error,
  label,
  accept,
  uploader,
  multiple = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FilePreviewModel[]>([]);

  const handleChange = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      const nextFiles: File[] = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < files!.length; i++) {
        nextFiles.push(files![i]);
      }

      setLoading(true);
      Promise.all(nextFiles.map(uploader))
        .then(results => {
          setSelectedFiles(
            results.map((id, idx) => {
              const file = nextFiles[idx];
              const thumb = file.type.startsWith('image')
                ? URL.createObjectURL(file)
                : undefined;
              return { id, thumb, name: file.name };
            })
          );
          onChange(results);
        })
        .finally(() => setLoading(false));
    },
    [onChange]
  );

  const handleDelete = useCallback(
    (id: number) => {
      setSelectedFiles(selectedFiles.filter(file => file.id !== id));
      onChange(value?.filter((file: number) => file !== id));
    },
    [selectedFiles, onChange]
  );

  return (
    <WidgetWrapper label={label} error={error} required={required}>
      <div className={styles.wrapper}>
        <div className={clns([styles.loader, loading && styles.loading])} />
        <label>
          <input
            type={'file'}
            accept={accept || '*/*'}
            onChange={handleChange}
            multiple={multiple}
          />
          <div className={clns([styles.icon, error && styles.error])}>+</div>
        </label>
        <div className={styles.files}>
          {selectedFiles.map(file => (
            <FilePreview key={file.id} {...file} onDelete={handleDelete} />
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default withFormConnected<FileFieldProps>(FileField);
