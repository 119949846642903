import React from 'react';
import Button, { ButtonProps } from '../Button';

import styles from './Error.module.sass';

interface ErrorProps {
  headline: string;
  text: string;
  buttons?: Array<{ label: string } & ButtonProps>;
}

const Error: React.FC<ErrorProps> = ({ headline, text, buttons }) => {
  return (
    <div className={styles.error}>
      <div className={styles.error__headline}>{headline}</div>
      <div className={styles.error__text}>{text}</div>
      {buttons && (
        <div className={styles.error__buttons}>
          {buttons.map(button => {
            const { label, ...props } = button;
            return (
              <Button {...props} key={label} className={styles.error__button}>
                {label}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Error;
