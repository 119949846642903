import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import Error from '../../shared/Error';

const NotFound = () => {
  const dispatch = useDispatch();

  return (
    <Error
      headline={'404'}
      text={'Такої сторінки не існує'}
      buttons={[
        {
          label: 'на головну',
          onClick: () => dispatch(push('/')),
          filled: true,
          color: 'lightBlue',
        },
      ]}
    />
  );
};

export default NotFound;
