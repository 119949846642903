import React from 'react';
import { clns } from '../../../helpers/strings';
import Button, { ButtonProps } from '../Button';

import styles from './TopBlock.module.sass';

interface TopBlockProps {
  headline?: string;
  image?: string;
  fullScreen?: boolean;
  buttons?: Array<{ label: string } & ButtonProps>;
  className?: string;
}

const TopBlock: React.FC<TopBlockProps> = ({
  headline,
  image,
  buttons,
  fullScreen,
  children,
  className,
}) => {
  return (
    <div
      className={clns([
        styles.topBlock,
        fullScreen && styles.topBlock_fullScreen,
        className,
      ])}
    >
      <div className={styles.topBlock__main}>
        <div className={styles.topBlock__headline}>{headline}</div>
        {buttons && (
          <div className={styles.topBlock__buttons}>
            {buttons.map(button => {
              const { label, ...props } = button;

              return (
                <Button {...props} key={label}>
                  {label}
                </Button>
              );
            })}
          </div>
        )}
        {children && <div className={styles.topBlock__content}>{children}</div>}
      </div>
      <div className={styles.topBlock__bg}>
        {image && (
          <img className={styles.topBlock__image} src={image} alt={''} />
        )}
      </div>
    </div>
  );
};

export default TopBlock;
