import {
  conferenceType,
  countries,
  jobSpecializations,
  participationTypes,
  postgraduateCategory,
  postgraduateType,
  publicationTypes,
  studiedLanguages,
  trainingsTypes,
  year
} from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const PostgraduateSchema = Schema.create({
  type: Fields.choice(dataJSON.postgraduateType || postgraduateType),
  name: Fields.any(),
  place: Fields.any(),
  startDate: Fields.date(),
  endDate: Fields.date(),
  dayCount: Fields.number(),
  country: Fields.choice(dataJSON.countries || countries),
  category: Fields.choice(dataJSON.postgraduateCategory || postgraduateCategory),
});

const TrainingsSchema = Schema.create({
  type: Fields.choice(dataJSON.trainingsTypes || trainingsTypes).required(),
  name: Fields.any().required(),
  place: Fields.any().required(),
  startDate: Fields.date().required(),
  endDate: Fields.date().required(),
  dayCount: Fields.number().required(),
  country: Fields.choice(dataJSON.countries || countries).required(),
  category: Fields.choice(postgraduateCategory).required(),
});

const RemoteEducationSchema = Schema.create({
  name: Fields.any().required(),
  resourceName: Fields.any().required(),
  hours: Fields.number(),
  points: Fields.number(),
  language: Fields.choice(dataJSON.studiedLanguages || studiedLanguages),
  startDate: Fields.date().required(),
  endDate: Fields.date().required(),
});

const ConferenceSchema = Schema.create({
  name: Fields.any().required(),
  reportTitle: Fields.any(),
  organizer: Fields.any(),
  participationType: Fields.choice(dataJSON.participationTypes || participationTypes).required(),
  type: Fields.choice(dataJSON.conferenceType || conferenceType).required(),
  city: Fields.text().required(),
  startDate: Fields.date().required(),
  endDate: Fields.date().required(),
  category: Fields.choice(postgraduateCategory),
  hours: Fields.number(),
  points: Fields.number(),
});

const PublicationsSchema = Schema.create({
  description: Fields.any().required(),
  type: Fields.choice(dataJSON.publicationTypes || publicationTypes).required(),
  language: Fields.choice(dataJSON.studiedLanguages || studiedLanguages).required(),
  year: Fields.choice(year()),
});

const CertificateSchema = Schema.create({
  beginDate:Fields.date(),
  startDate: Fields.date(),
  endDate: Fields.date(),
  dayCount: Fields.number(),
  specialization: Fields.choice(dataJSON.jobSpecializations || jobSpecializations),
  country: Fields.choice(dataJSON.countries || countries),
});

const InformalEducationSchema = Schema.create({
  certificate: Fields.array(CertificateSchema),
  postgraduate: Fields.array(PostgraduateSchema),
  trainings: Fields.array(TrainingsSchema),
  remote: Fields.array(RemoteEducationSchema),
  conference: Fields.array(ConferenceSchema),
  publications: Fields.array(PublicationsSchema),
});

export default InformalEducationSchema;
