import React from 'react';

import { clns } from '../../../helpers/strings';

import styles from './Burger.module.sass';

interface BurgerProps {
  opened: boolean;
  className?: string;
  onClick?: () => void;
}

const Burger: React.FC<BurgerProps> = ({ opened, className, onClick }) => {
  return (
    <div
      className={clns([
        styles.burger,
        opened && styles.burger_opened,
        className,
      ])}
      onClick={onClick}
    >
      <div className={styles.burger__line} />
      <div className={styles.burger__line} />
      <div className={styles.burger__line} />
    </div>
  );
};

export default Burger;
