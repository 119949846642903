import NewsListItemDto from 'infrastructure/models/news/NewsListItemDto';
import INewsProvider, {
  SINewsProvider,
} from 'infrastructure/providers/NewsProvider/INewsProvider';
import container from 'ioc';
import createListModule from 'store/helpers/createListModule';

const provider = () => container.get<INewsProvider>(SINewsProvider);

const {
  actionTypes: newsActionTypes,
  actions: newsActions,
  reducer: newsReducer,
  handlers: newsHandlers,
  selectors: newsSelectors,
} = createListModule<NewsListItemDto>('news', provider);

export {
  newsActionTypes,
  newsActions,
  newsHandlers,
  newsReducer,
  newsSelectors,
};
