import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';
import { injectable } from 'inversify';
import range from 'lodash/range';
import IOptionsProvider, { Options } from './IOptionsProvider';

@injectable()
export default class MockOptionsProvider implements IOptionsProvider {
  public getCategories(): Promise<Options> {
    return this.getOptions('categories', 'category');
  }

  public getCities(): Promise<Options> {
    return this.getOptions('cities', 'cities');
  }

  public getSpecialities(): Promise<Options> {
    return this.getOptions('specialities', 'specialities');
  }

  private async getOptions(
    category: string,
    name: string
  ): Promise<ChoicesType> {
    return range(10).map(x => [`${name}-${x}`, `${name}-${x}`]);
  }
}
