import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import { clns } from 'helpers/strings';
import React, { useCallback } from 'react';

import styles from './TextArea.module.sass';

interface TextAreaProps extends WidgetDataProps {
  label: string;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  onChange,
  value,
  error,
  required,
  placeholder,
}) => {
  const handleChange = useCallback(
    ({
      target: { value: currentValue },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(currentValue);
    },
    [onChange]
  );

  return (
    <WidgetWrapper label={label} error={error} required={required}>
      <textarea
        onChange={handleChange}
        onBlur={handleChange}
        placeholder={placeholder || label || ''}
        value={value}
        className={clns([styles.textarea, error && styles.error])}
      />
    </WidgetWrapper>
  );
};

export default withFormConnected<TextAreaProps>(TextArea);
