import SeoContext from 'contexts/SeoContext';
import React, { useContext, useEffect } from 'react';
import { match } from 'react-router';

import useGetBySlug from 'hooks/useGetBySlug';
import NoveltyDto from 'infrastructure/models/news/NoveltyDto';
import { SINewsProvider } from 'infrastructure/providers/NewsProvider/INewsProvider';

import ContentPage from 'components/shared/ContentPage';
import HTMLContent from 'components/shared/HTMLContent';
import MoreBlock from 'components/shared/MoreBlock';
import NewsItem from 'components/shared/NewsItem';
import ShareButton from 'components/shared/ShareButton';

import styles from './NewsInner.module.sass';

import LoadingContext from 'contexts/LoadingContext';

const getFullContent = (
  subHeadline?: string,
  image?: string,
  content?: string
) => {
  return (
    (subHeadline ? `<h2>${subHeadline}</h2>` : '') +
    (image ? `<img src="${image}" alt=""/>` : '') +
    content
  );
};

interface NewsInnerProps {
  match: match<{ slug: string }>;
}

const NewsInner: React.FC<NewsInnerProps> = ({
  match: {
    params: { slug },
  },
}) => {
  const { value, loading } = useGetBySlug<NoveltyDto>(SINewsProvider, slug);
  const { setSeo } = useContext(SeoContext);
  useEffect(() => {
    if (value) {
      setSeo({
        title: value.seo?.title || value.title,
        description: value.seo?.description || value.description,
        image: value.image,
        type: 'article',
        og: {
          'article:published_time': value.createdAt,
          'article:modified_time': value.createdAt,
          'article:author': 'Українська Академія Сімейного Лікаря',
        },
      });
    }
  }, [value]);

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ContentPage
        headline={value?.title!}
        className={styles.newsInner}
        backLink={{ link: '/news', name: 'Новини' }}
      >
        <HTMLContent
          content={getFullContent(
            value?.subTitle,
            value?.image,
            value?.description
          )}
        />
        <div className={styles.newsInner__date}>{value?.createdAt}</div>
        <ShareButton />
        <MoreBlock headline={'Що ще почитати'}>
          {value?.relatedNews.map((item, i) => (
            <NewsItem newsItem={item} key={i} />
          ))}
        </MoreBlock>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default NewsInner;
