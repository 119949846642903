import ListView from 'components/shared/ListView';
import Pagination from 'components/shared/Pagination';
import React from 'react';

import styles from './MultiPageList.module.sass';

interface MultiPageListProps {
  page: number;
  pages: number;
  goToPage: (page: number) => void;
}

const MultiPageList: React.FC<MultiPageListProps> = ({
  children,
  page,
  pages,
  goToPage,
}) => {
  return (
    <div className={styles.wrapper}>
      <ListView>{children}</ListView>
      <Pagination page={page} pages={pages} goToPage={goToPage} />
    </div>
  );
};

export default MultiPageList;
