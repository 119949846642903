import { injectable } from 'inversify';
import range from 'lodash/range';

import news_pic_1 from '../../../assets/images/news_pic_1.png';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import NoveltyDto from '../../models/news/NoveltyDto';
import INewsProvider from './INewsProvider';

const data: NewsListItemDto[] = range(10).map(id => ({
  id: id.toString(),
  image: news_pic_1,
  name: `Молодежная нестезиологическая конференция 4 «Трещинский чтения» Молодежная нестезиологическая ${id}`,
  createdAt: '02.09.19',
  slug: 'novost-o-medicine',
  liked: false,
}));

const novelty: NoveltyDto = {
  id: '1',
  title: 'Title',
  createdAt: '02.09.19',
  subTitle: '',
  image: '/images/news_image.png',
  description: `
<h2>
8 квітня 2019 року Міністерство охорони здоров’я 
України розмістило на власному сайті запрошення 
для представників лікарської спільноти на публічні 
консультації щодо запровадження професійного ліцензування лікарів.
</h2>
<img src="/images/news_image.png" alt="smth">
<p>
МОЗ підкреслює важливість обговорення змін, що пропонуються до втілення. Консультації 
планують проводити у форматі зустрічі з лікарями, адміністраторами закладів, представниками 
департаментів охорони здоров’я місцевих адміністрацій. За підтримки Департаменту з 
міжнародного розвитку Сполученого Королівства (DFID)  передбачено 10 заходів у таких 
містах України: Київ, Львів, Ужгород, Вінниця, Суми, Харків, Дніпро, Черкаси, Одеса, 
Рівне. Для тих представників лікарської спільноти, які не зможуть взяти участь 
у консультаціях, створено онлайн-опитування.
</p>
<p>Міністерство охорони здоров’я України запропонувало для обговорення в межах консультацій низку основних питань:</p>
<ul>
<li>критерії відбору та вимоги до членів Ліцензійної ради;</li>
<li>процедура формування Ліцензійної ради;</li>
<li>критерії автоматичного продовження ліцензії на новий строк та позбавлення ліцензії;</li>
<li>побудова системи управління ризиками в лікарській практиці в контексті контролю якості надання медичної допомоги;</li>
<li>особливості перехідного періоду для лікарів, що практикують.</li>
</ul>
<p>
Ці пропозиції, зауваження та коментарі пересічних лікарів будуть взяті до уваги у 
процесі підготовки відповідного законопроекту. Реєстрацію вже розпочато. 
Для лікарів Києва і Київської області — консультація 11 квітня.
</p>
<p>
Не залишайтеся осторонь від важливих змін!
</p>
<hr/>
<p>Україна починає співпрацю з Швейцарією у сфері розвитку медичної освіти  пожна почитати <a href="/">тут</a></p>
`,
  relatedNews: data,
  liked: false,
};

@injectable()
export default class MockNewsProvider implements INewsProvider {
  public async getBySlug(slug: string): Promise<NoveltyDto> {
    return novelty as any;
  }

  public async getList(): Promise<{ list: NewsListItemDto[]; pages: number }> {
    return { list: data, pages: 3 };
  }

  public async like(id: string, status: boolean): Promise<void> {
    return;
  }
}
