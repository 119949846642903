import { injectable } from 'inversify';
import PageData, { PageBlocks } from '../../models/pages/PageData';
import IPageDataProvider from './IPageDataProvider';

@injectable()
export default class MockPageDataProvider implements IPageDataProvider {
  public async get(page: string): Promise<PageData> {
    return { seo: {}, blocks: [], title: '' } as PageData;
  }

  public async getBlock(name: string): Promise<PageBlocks> {
    return {} as any;
  }
}
