import { History } from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import queryString from 'query-string';
import { Store } from 'redux';
import { setModal } from 'store/modules/ui/actions';
import { AppState } from 'store/reducer';
import createStore from './store';
import { initialize as init } from './store/modules/init/actions';

interface InitReturn {
  store: Store<AppState>;
  history: History;
}

export default async function initialize(): Promise<InitReturn> {
  const history = createBrowserHistory();
  const store = createStore({}, history);
  store.dispatch(init());

  const search = window?.location?.search;
  if (search) {
    const { updatepassword } = queryString.parse(search);
    if (updatepassword) {
      store.dispatch(setModal('updatePassword', true));
    }
  }

  return { store, history };
}
