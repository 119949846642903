import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';

export interface AskValueModel {
  name: string;
  surname: string;
  email: string;
  question: string;
}

const AskSchema = Schema.create<AskValueModel>({
  name: Fields.text().required(),
  surname: Fields.text().required(),
  email: Fields.email().required(),
  question: Fields.any().required(),
});

export default AskSchema;
