const ACCOUNT_LINKS = [
  ['/', 'Портфоліо'],
  ['/purchases', 'Мої курси'],
  ['/later', 'Мої подіЇ'],
  ['/liked', 'Мої новини'],
  ['/certificates', 'Мої сертифікати'],
  // ['/standarts', 'Стандарти терапії'],
];

export default ACCOUNT_LINKS;
