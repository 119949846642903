import DateInput from 'components/forms/Widgets/DateInput';
import Form from 'components/forms/Form';
import { HigherEducationSchema } from 'schemas/EducationSchemas';
import Input from 'components/forms/Widgets/Input';
import List from 'components/forms/Widgets/List';
import React from 'react';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';

const EducationSettings = () => {
  const { value, errors, onSave, onChange } = useUserSettings(
    'education',
    HigherEducationSchema
  );

  return (
    <Form
      schema={HigherEducationSchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >
      <List.Connected
        name={'universities'}
        addMoreText={'Додати ВНЗ'}
        label={'Вищий навчальний заклад'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected
              name={'country'}
              label={'Країна навчання'}
              disableEmpty
            />
          </Form.Col>
          <Form.Col width={(100 / 3) * 2}>
            <Select.Connected name={'university'} label={'Назва ВНЗ'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'specialization'} label={'Факультет'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected
              name={'docSpecialization'}
              label={'Спеціальність'}
            />
          </Form.Col>
          <Form.Col>
            <Input.Connected name={'diploma'} label={'Номер диплома'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected
              name={'educationType'}
              label={'Форма підготовки'}
            />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'startYear'} label={'Рік вступу'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'endYear'} label={'Рік закінчення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'scienceDegree'}
        label={'Науковий ступінь'}
        addMoreText={'Додати науковий ступінь'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'degree'} label={'Науковий ступінь'} />
          </Form.Col>
          <Form.Col width={100 / 3}>
            <DateInput.Connected
              name={'protectionDate'}
              label={'Дата захисту'}
            />
          </Form.Col>
          <Form.Col width={100 / 3}>
            <DateInput.Connected name={'dateOfIssue'} label={'Дата видачі'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'specialization'} label={'Спеціальність'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected
              name={'protectionPlace'}
              label={'Місце захисту'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={(100 / 3) * 2}>
            <Input.Connected name={'thesisTopic'} label={'Тема дисертації'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'diploma'} label={'Номер диплому'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'academicStatus'}
        label={'Вчене звання'}
        addMoreText={'Додати вчене звання'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'status'} label={'Вчене звання'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'languages'}
        label={'Володіння іноземними мовами'}
        addMoreText={'Додати мову'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected label={'Мова'} name={'language'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected label={'Рівень володіння'} name={'level'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <Form.Button />
    </Form>
  );
};

export default EducationSettings;
