import { SchemaDataType } from '@project-m/schemas/dist/types';
import Form from 'components/forms/Form';
import DateRange from 'components/forms/Widgets/DateRange';
import NumberRange from 'components/forms/Widgets/NumberRange';
import Select from 'components/forms/Widgets/Select';
import EventFilters from 'infrastructure/models/events/EventFilters';
import React, { useCallback } from 'react';
import EventsFiltersSchema from 'schemas/EventsFiltersSchema';

interface FiltersProps {
  onChange: (data: EventFilters) => void;
  initial: EventFilters;
}

const Filters: React.FC<FiltersProps> = ({ onChange, initial }) => {
  const handleChange = useCallback(
    (data: SchemaDataType<EventFilters>) => {
      onChange(EventsFiltersSchema.clearDefaultValues(data.value));
    },
    [onChange]
  );

  return (
    <Form
      schema={EventsFiltersSchema}
      value={initial}
      errors={null}
      onChange={handleChange}
    >
      <Form.Row>
        <Form.Col>
          <Select.Connected name={'category'} label={'Категорія'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'occupation'} label={'Спеціальність'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'city'} label={'Місто'} />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <DateRange.Connected name={'date'} label={'Дати'} />
        </Form.Col>
        <Form.Col>
          <NumberRange.Connected name={'price'} label={'Ціна'} />
        </Form.Col>
      </Form.Row>
    </Form>
  );
};

export default Filters;
