import React from 'react';

import ListView from '../ListView';

import { clns } from '../../../helpers/strings';

import styles from './MoreBlock.module.sass';

interface MoreBlockProps {
  headline: string;
  className?: string;
}

const MoreBlock: React.FC<MoreBlockProps> = ({
  headline,
  children,
  className,
}) => {
  return (
    <div className={clns([styles.moreBlock, className])}>
      <div className={styles.moreBlock__headline}>{headline}</div>
      <ListView scrollable>{children}</ListView>
    </div>
  );
};

export default MoreBlock;
