import { ModalsState, ModalState } from 'store/modules/ui/types';
import { AppState } from 'store/reducer';

export const getModals = (state: AppState): ModalsState => state.ui.modals;
export const getModal = (name: string) => (
  state: AppState
): ModalState | undefined => state.ui.modals[name];

export const getLoader = (name: string) => (state: AppState): boolean =>
  state.ui.loaders.includes(name);
