import ProfileDto from '../../../infrastructure/models/profile/ProfileDto';
import {ProfileActionTypes} from './constants';
import axios from 'axios';

export const loadProfile = () => ({
  type: ProfileActionTypes.LOAD,
});

const getIsBanned = async (profile: any) => {
  const res = await axios.post(
    `http://cme-cpd.com.ua:1337/admin/api/isBanned`,
    {id: profile.id}
  );

  localStorage.setItem('isBanned', JSON.stringify(res.data));
  return res.data;
};

export const setProfile = (profile?: ProfileDto) => {
  getIsBanned(profile);

  return {
    type: ProfileActionTypes.SET,
    payload: {profile},
  };
};

// export const setProfile = async (profile?: any) => {
//   console.log(profile, 'actions.ts setProfile');

//   const res = await axios.post(
//     `http://cme-cpd.com.ua:1337/admin/api/isBanned`,
//     {id: profile.id}
//   );

//   localStorage.setItem('isBanned', JSON.stringify(res.data));

//   console.log(res.data);

//   return {
//     type: ProfileActionTypes.SET,
//     payload: {profile},
//   };
// };

export const setAvatar = (payload: string) => ({
  type: ProfileActionTypes.SET_AVATAR,
  payload,
});
