import React, {useState} from 'react';

import Button from '../../../../shared/Button';
import ExpertModal from '../../../../base/Modals/ExpertModal';
import {setModal} from '../../../../../store/modules/ui/actions';
import styles from './TopBlock.module.sass';
import {useDispatch} from 'react-redux';

interface TopBlockProps {
  headline: string;
  text: string;
  image: string;
  buttonText: string;
}

const HomeTopBlock = React.memo<TopBlockProps>(({headline, image, buttonText, text}) => {
  const dispatch = useDispatch();

  const [isOpen, setisOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles.topBlock}>
        <div className={styles.topBlock__top}>
          <div className={styles.topBlock__left}>
            <div className={styles.topBlock__headline} style={{fontSize: '70px',lineHeight:'80px'}}>{headline}</div>
            <div className={styles.topBlock__text} dangerouslySetInnerHTML={{__html:text}}></div>
          </div>
          <div className={styles.topBlock__right}>
            <img src={image} alt={''} className={styles.topBlock__image} />
          </div>
        </div>
        <div className={styles.topBlock__buttons}>
          <Button
            filled
            className={styles.topBlock__button}
            onClick={() => dispatch(setModal('signUp', true))}
          >
            {buttonText}
          </Button>
          <Button
            color={'red'}
            withBorders
            className={styles.topBlock__button}
            onClick={() => setisOpen(!isOpen)}
          >
            Хочу стати Експертом
          </Button>
        </div>
      </div>
      {isOpen && <ExpertModal setIsOpen={setisOpen}></ExpertModal>}
    </>
  );
});

export default HomeTopBlock;
