import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SchemaDataType } from '@project-m/schemas/dist/types';
import LoginSchema, { LoginValueModel } from 'schemas/LoginSchema';

import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Modal, { ModalConsumerProps } from 'components/shared/Modal';

import { logIn } from 'store/modules/auth/actions';
import { setModal } from 'store/modules/ui/actions';

import login_image from 'assets/images/login_image.png';
import styles from './Login.module.sass';

const Login: React.FC<ModalConsumerProps> = ({ visible }) => {
  const [{ value, errors }, setData] = useState<
    SchemaDataType<LoginValueModel>
  >(LoginSchema.generateEmpty());
  const dispatch = useDispatch();
  const handleClick = useCallback(
    () => dispatch(logIn(value.email, value.password)),
    [value, errors]
  );
  const handleSignUp = useCallback(() => {
    dispatch(setModal('login', false));
    dispatch(setModal('signUp', true));
  }, []);
  const handleForgotPassword = useCallback(() => {
    dispatch(setModal('login', false));
    dispatch(setModal('forgotPassword', true));
  }, []);

  return (
    <Modal name={'login'} image={login_image} visible={visible}>
      <div className={styles.login}>
        <div className={styles.login__headline}>Вхід</div>
        <Form<LoginValueModel>
          schema={LoginSchema}
          value={value}
          errors={errors}
          onChange={setData}
          onSubmit={handleClick}
        >
          <Form.Row>
            <Form.Col width={100}>
              <Input.Connected name={'email'} label={'Email'} />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col width={100}>
              <Input.Connected
                name={'password'}
                label={'Пароль'}
                type={'password'}
              />
            </Form.Col>
          </Form.Row>
          <Form.Button>Увійти</Form.Button>
        </Form>
        <div className={styles.login__bottom}>
          <div className={styles.login__link} onClick={handleSignUp}>
            Реєстрація
          </div>
          <div className={styles.login__link} onClick={handleForgotPassword}>
            Забули пароль?
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Login;
