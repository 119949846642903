import { Location } from 'history';
import React, { useContext, useEffect, useState } from 'react';

import Button from 'components/shared/Button';
import ContentPage from 'components/shared/ContentPage';
import SeoContext from 'contexts/SeoContext';
import { ExpertsPageBlock } from 'infrastructure/models/pages/PageData';
import IPageDataProvider, {
  SIPageDataProvider,
} from 'infrastructure/providers/PageDataProvider/IPageDataProvider';
import container from 'ioc';

import styles from './About.module.sass';

const provider = () => container.get<IPageDataProvider>(SIPageDataProvider);

interface AboutProps {
  location: Location;
}

const About: React.FC<AboutProps> = ({ location: { pathname } }) => {
  const [data, setData] = useState<ExpertsPageBlock>({
    headline: '',
    experts: [],
    type: 'experts',
  });

  const { setSeo } = useContext(SeoContext);
  useEffect(() => {
    setSeo({ title: 'Про нас' });
  }, []);

  useEffect(() => {
    provider()
      .get(pathname)
      .then(payload => setData((payload.blocks[0] as any) as ExpertsPageBlock));
  }, [pathname]);

  return (
    <ContentPage headline={data.headline} className={styles.about}>
      <div className={styles.about__list}>
        {data.experts.map((expert, i) => (
          <div className={styles.about__item} key={i}>
            <div className={styles.about__imageContainer}>
              <img
                src={expert.image}
                alt={''}
                className={styles.about__image}
              />
            </div>
            <div className={styles.about__name}>{expert.name}</div>
            <div className={styles.about__occupation}>{expert.rank}</div>
          </div>
        ))}
      </div>
      <Button filled color={'red'}>
        Повернутись на головну
      </Button>
    </ContentPage>
  );
};

export default About;
