import ContentPage from 'components/shared/ContentPage';
import MultiPageList from 'components/shared/MultiPageList';
import NewsItem from 'components/shared/NewsItem';

import LoadingContext from 'contexts/LoadingContext';
import SeoContext from 'contexts/SeoContext';
import useList, { ListModules } from 'hooks/useList';
import React, { useContext, useEffect } from 'react';

const NewsList = () => {
  const { list: news, page, pages, loading, goToPage } = useList(
    ListModules.NEWS
  );
  const { setSeo } = useContext(SeoContext);
  useEffect(() => {
    setSeo({ title: 'Новини' });
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ContentPage headline={'Новини'} placeholder={false}>
        <MultiPageList page={page} pages={pages} goToPage={goToPage}>
          {news.map(newsItem => (
            <NewsItem newsItem={newsItem} key={newsItem.id} />
          ))}
        </MultiPageList>
      </ContentPage>
    </LoadingContext.Provider>
  );
};

export default NewsList;
