import { FieldError } from '@project-m/schemas/dist/types';
import { clns } from 'helpers/strings';
import React from 'react';

import styles from './WidgetWrapper.module.sass';

interface WidgetWrapperProps {
  label?: string;
  error?: FieldError;
  required?: boolean;
  className?: string;
}

const WidgetWrapper: React.FC<WidgetWrapperProps> = ({
  label,
  error,
  children,
  required,
  className,
}) => {
  return (
    <div className={clns([styles.wrapper, className])}>
      {label && (
        <div className={clns([styles.label, error && styles.error])}>
          {label}
          {required ? ' *' : ''}
        </div>
      )}
      <div className={styles.content}>{children}</div>
      {error && <div className={styles.errorLabel}>{error}</div>}
    </div>
  );
};

export default WidgetWrapper;
