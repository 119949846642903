import ListResponse from 'infrastructure/models/common/ListResponse';
import NewsFiltersModel from 'infrastructure/models/news/NewsFiltersModel';
import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import NoveltyDto from '../../models/news/NoveltyDto';
import INewsProvider from './INewsProvider';

interface GetListResult {
  total: number;
  list: any[];
}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const formatDate = (timestamp: number) =>
  new Date(timestamp).toLocaleDateString('uk-UA', dateOptions);

export const serializeListItem = (item: any): NewsListItemDto => ({
  id: item.id,
  name: item.title,
  image: item.image,
  createdAt: formatDate(item.createdAt),
  liked: item.liked,
  slug: item.slug,
});

const serializeItem = (item: any): NoveltyDto => ({
  id: item.id,
  title: item.title,
  createdAt: formatDate(item.createdAt),
  subTitle: item.subTitle,
  description: item.description,
  relatedNews: item.relatedNews.map(serializeListItem),
  liked: false,
  image: item.image,
  seo: {
    title: item.seoTitle,
    description: item.seoDescription,
  },
});

@injectable()
export default class ApiNewsProvider implements INewsProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  constructor() {}
  public async getList({
    page,
    count,
  }: NewsFiltersModel): Promise<ListResponse<NewsListItemDto>> {
    const resp = await this.apiClient.request<
      any,
      ListResponse<NewsListItemDto>
    >('news', 'getList', {
      count: count || 9,
      page,
    });
    return { list: resp.list.map(serializeListItem), pages: resp.pages };
  }

  public async getBySlug(slug: string): Promise<NoveltyDto> {
    const result = await this.apiClient.request('news', 'getBySlug', slug);
    return serializeItem(result);
  }

  public async like(id: string, status: boolean): Promise<void> {
    await this.apiClient.request('news', 'like', { noveltyId: id, status });
  }
}
