import { useCallback, useEffect, useState } from 'react';
import container from '../ioc';

export interface GetByHookReturn<T> {
  value: T | null;
  error: boolean;
  loading: boolean;
  updateValue: (data: T) => void;
  provider: <P>() => P;
}

export default function useGetBy<T>(
  sym: symbol,
  method: string,
  params: any[]
): GetByHookReturn<T> {
  const [requestParams, setRequestParams] = useState<any[]>(params);
  const provider = useCallback(() => container.get(sym), [sym, requestParams]);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setRequestParams(params);
  }, [...params]);

  useEffect(() => {
    setLoading(true);

    (provider() as any)
      [method](...requestParams)
      .then((response: T) => {
        setValue(response);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setValue(null);
      })
      .finally(() => setLoading(false));
  }, [requestParams]);

  const updateValue = useCallback((data: T) => {
    setValue(data);
  }, []);

  return { value, error, loading, updateValue, provider };
}
