import React, { ComponentType, PropsWithChildren, useContext } from 'react';
import LoadingContext from '../../contexts/LoadingContext';

export default function withPlaceholder<T>(
  Placeholder: ComponentType<any>,
  Component: ComponentType<T>
) {
  return (props: PropsWithChildren<T & { placeholder?: boolean }>) => {
    const { loading } = useContext(LoadingContext);
    const disablePlaceholder = 'placeholder' in props && !props.placeholder;

    if (!loading || disablePlaceholder) {
      return <Component {...props} />;
    }

    return <Placeholder {...props} />;
  };
}
