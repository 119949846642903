import { PayloadAction } from 'store/types';
import translationsActionTypes from './constants';

export interface LangState {
  current: string;
  list: Record<string, string>;
}

const initialState: LangState = {
  current: 'ru',
  list: {
    ru: 'Русский',
    ua: 'Українська',
  },
};

export default function lang(
  state: LangState = initialState,
  action: PayloadAction
): LangState {
  if (action.type === translationsActionTypes.SET) {
    return { ...state, current: action.payload };
  }
  return state;
}
