import { injectable } from 'inversify';
import Locale from '../../models/locale/Locale';
import ILocaleProvider from './ILocaleProvider';

@injectable()
export default class MockLocaleProvider implements ILocaleProvider {
  public current(): Locale {
    return 'ru';
  }

  public async setLocale(locale: Locale): Promise<void> {
    return;
  }
}
