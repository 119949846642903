import React from 'react';

interface AlertsContextType {
  fireSuccess: (content: string, delay?: number) => void;
  fireWarning: (content: string, delay?: number) => void;
  fireError: (content: string, delay?: number) => void;
}

const AlertsContext = React.createContext<AlertsContextType>({
  fireSuccess: () => {},
  fireWarning: () => {},
  fireError: () => {},
});

export default AlertsContext;
