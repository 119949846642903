import { SignUpValueModel } from '../../../schemas/SignUpSchema';
import SignUpActionTypes from './constants';

export const setSignUpStep = (step: number) => ({
  type: SignUpActionTypes.CHANGE_STEP,
  payload: step,
});

export const setSignUpData = (data: SignUpValueModel) => ({
  type: SignUpActionTypes.UPDATE_DATA,
  payload: data,
});

export const signUp = () => ({
  type: SignUpActionTypes.SING_UP,
});

export const signUpDone = () => ({
  type: SignUpActionTypes.SIGN_UP_DONE,
});
