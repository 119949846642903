import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';
import EventFilters from 'infrastructure/models/events/EventFilters';
import IOptionsProvider, {
  SIOptionsProvider,
} from 'infrastructure/providers/OptionsProvider/IOptionsProvider';
import container from 'ioc';

const provider = () => container.get<IOptionsProvider>(SIOptionsProvider);

const EventsFiltersSchema = Schema.create<EventFilters>({
  category: Fields.choice([], () => provider().getCategories()),
  occupation: Fields.choice([], () => provider().getSpecialities()),
  city: Fields.choice([], () => provider().getCities()),
  price: Fields.numberRange(0, 5000),
  date: Fields.dateRange(),
});

export default EventsFiltersSchema;
