import { universitiesPositions, year } from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const ScientificPedagogicalActivitySchema = Schema.create({
  name: Fields.any().required(),
  chair: Fields.any().required(),
  position: Fields.choice(dataJSON.universitiesPositions || universitiesPositions).required(),
  startYear: Fields.choice(year()).required(),
  endYear: Fields.choice(year(true)).required(),
});

const ConductedLecturesForMedicsSchema = Schema.create({
  name: Fields.any().required(),
  place: Fields.any().required(),
  hours: Fields.number().required(),
  date: Fields.date().required(),
});

const ConductedLecturesForPeopleSchema = Schema.create({
  name: Fields.any().required(),
  place: Fields.any().required(),
  hours: Fields.number().required(),
  date: Fields.date().required(),
});

const PublicationSchema = Schema.create({
  name: Fields.any().required(),
  date: Fields.date().required(),
});

const LeadershipSchema = Schema.create({
  place: Fields.any().required(),
  peopleCount: Fields.number().required(),
  hours: Fields.number().required(),
  startDate: Fields.date().required(),
  endDate: Fields.date().required(),
});

const EducationalActivitiesSchema = Schema.create({
  scientificPedagogicalActivity: Fields.array(
    ScientificPedagogicalActivitySchema
  ),
  conductedLecturesForMedics: Fields.array(ConductedLecturesForMedicsSchema),
  conductedLecturesForPeople: Fields.array(ConductedLecturesForPeopleSchema),
  publications: Fields.array(PublicationSchema),
  leadership: Fields.array(LeadershipSchema),
});

export default EducationalActivitiesSchema;
