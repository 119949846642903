import {CourseFilters, LectionDto} from '../../models/courses/common';

import CourseListItemDto from '../../models/courses/CourseListItemDto';
import CoursePreviewDto from '../../models/courses/CoursePreviewDto';
import CourseTrainingDto from '../../models/courses/CourseTrainingDto';
import Id from '@project-m/core/entities/common/Id';
import ListResponse from 'infrastructure/models/common/ListResponse';

export const SICourseProvider = Symbol.for('ICourseProvider');

export enum AddResultStatus {
  SUCCESS,
  FAILED,
}

export interface AddResult {
  status: AddResultStatus;
}

export type PreparedCoursesFilters = CourseFilters & {
  page: number;
  count: number;
};

export default interface ICourseProvider {
  getList(
    filters: PreparedCoursesFilters
  ): Promise<ListResponse<CourseListItemDto>>;
  getPreview(slug: string): Promise<CoursePreviewDto>;
  getTraining(slug: string): Promise<any>;
  getLection(courseId: Id, id: Id): Promise<any>;
  answer(
    courseId: Id,
    lectionId: Id,
    questionId: Id,
    answerId: Id
  ): Promise<boolean>;
  add(id: Id): Promise<AddResult>;
  testComplete(courseId: Id, lectionId: Id): Promise<void>;
  videoWatched(courseId: Id, lectionId: Id): Promise<void>;
}
