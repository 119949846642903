import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal, { ModalConsumerProps } from 'components/shared/Modal';
import MAIN_LINKS from 'constants/links/mainLinks';
import { setModal } from 'store/modules/ui/actions';

import menu_pic from 'assets/images/menu_pic.png';
import UserActions from 'components/shared/UserActions';
import styles from './Menu.module.sass';

const Menu: React.FC<ModalConsumerProps> = ({ visible }) => {
  const dispatch = useDispatch();

  return (
    <Modal image={menu_pic} name={'menu'} visible={visible}>
      <div className={styles.menu}>
        <div className={styles.menu__top}>
          <div className={styles.menu__links}>
            {MAIN_LINKS.slice(0, 3).map(([link, text]) => (
              <Link
                to={link}
                key={link}
                className={styles.menu__link}
                onClick={() => dispatch(setModal('menu', false))}
              >
                {text}
              </Link>
            ))}
          </div>
          <div className={styles.menu__links}>
            {MAIN_LINKS.slice(3, MAIN_LINKS.length).map(([link, text]) => (
              <Link
                to={link}
                key={link}
                className={styles.menu__link}
                onClick={() => dispatch(setModal('menu', false))}
              >
                {text}
              </Link>
            ))}
          </div>
          <UserActions className={styles.menu__userActions} mobile />
        </div>
        <div className={styles.menu__bottom}>
          <div className={styles.menu__textBlock}>
            <a className={styles.menu__text} href={'mailto:mail@example.com'}>
              mail@example.com
            </a>
            <a className={styles.menu__text} href={'tel:+38 (098) 000 01 02'}>
              +38 (098) 000 01 02
            </a>
          </div>
          <div className={styles.menu__textBlock}>
            <div className={styles.menu__text}>Україна, місто Київ</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Menu;
