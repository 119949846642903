import EducationActivitiesSettings from 'components/pages/Account/pages/Edit/EducationActivitiesSettings';
import EducationSettings from 'components/pages/Account/pages/Edit/EducationSettings';
import PostgraduateSettings from 'components/pages/Account/pages/Edit/PostgraduateSettings';
import ProfessionalSettings from 'components/pages/Account/pages/Edit/ProfessionalSettings';
import ScientificAndInnovativeWorkSettings from 'components/pages/Account/pages/Edit/ScientificAndInnovativeWorkSettings';
import Certificates from './pages/Certificates';
import MainSettings from './pages/Edit/MainSettings';
import Later from './pages/Later';
import Liked from './pages/Liked';
import Portfolio from './pages/Portfolio';
import Purchases from './pages/Purchases';
import Standarts from './pages/Standarts';

const ACCOUNT_ROUTES = [
  {
    url: '/',
    exact: true,
    component: Portfolio,
  },
  {
    url: '/purchases',
    exact: true,
    component: Purchases,
  },
  {
    url: '/later',
    exact: true,
    component: Later,
  },
  {
    url: '/liked',
    exact: true,
    component: Liked,
  },
  {
    url: '/certificates',
    exact: true,
    component: Certificates,
  },
  {
    url: '/standarts',
    exact: true,
    component: Standarts,
  },
  {
    url: '/edit',
    exact: true,
    component: MainSettings,
  },
  {
    url: '/edit/education',
    exact: true,
    component: EducationSettings,
  },
  {
    url: '/edit/postgraduate',
    exact: true,
    component: PostgraduateSettings,
  },
  {
    url: '/edit/experience',
    exact: true,
    component: ProfessionalSettings,
  },
  {
    url: '/edit/teaching',
    exact: true,
    component: EducationActivitiesSettings,
  },
  {
    url: '/edit/innovative',
    exact: true,
    component: ScientificAndInnovativeWorkSettings,
  },
];

export default ACCOUNT_ROUTES;
