import React, {useState} from 'react';

import Select from 'components/forms/Widgets/Select';
import moment from 'moment';
import styles from './ItemInfo.module.sass';

type ItemInfoType = Record<string, any>;
export type ItemInfoLayoutType = Record<
  string,
  {label: string; editable?: boolean; price?: any; priceLabel?: any}
>;

interface ItemInfoProps {
  data: ItemInfoType[];
  layout: ItemInfoLayoutType;
  onChange?: (params: any) => void;
  startCheckout?: (params: any) => void;
  courseId: number;
}

const ItemInfo: React.FC<ItemInfoProps> = ({
  data,
  layout,
  onChange,
  startCheckout,
  courseId,
}) => {
  const [current, setCurrent] = useState<ItemInfoType>(data[0]);

  const handleChange = (value: string, key: string): void => {
    const newValue = data.find((item) => item[key] === value)!;
    setCurrent(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.eventPicker}>
      {Object.entries(layout).map(
        ([name, {label, editable, price, priceLabel}]) => {
          console.log(current, name);
          return (
            <div className={styles.eventPicker__column} key={label}>
              {editable ? (
                <Select
                  label={label}
                  value={current[name]}
                  choices={data.map((item) => [item[name], item[name]])}
                  onChange={(value) => handleChange(value, name)}
                  required={false}
                  disableEmpty
                  error={null}
                />
              ) : (
                <>
                  <div className={styles.eventPicker__label}>{label}</div>
                  <div className={styles.eventPicker__value}>
                    {current[name] === 0
                      ? 'Безкоштовно'
                      : `${current[name]} ${
                          label === 'Ціна' ? current.priceLabel : ''
                        }`}
                  </div>
                </>
              )}
              {label === 'Ціна' && current[name] !== 0 && (
                <div
                  onClick={() =>
                    // //@ts-ignore
                    // startCheckout(
                    //   courseId,
                    //    //@ts-ignore
                    //   JSON.parse(localStorage.getItem('userId')),
                    //   moment(Date.now()).format('DD-MM-YYYY')
                    // )
                    (window.location.href = 'https://easypay.ua/ua')
                  }
                  style={{
                    justifyContent: 'center',
                    color: '#fff',
                    background: '#4169E1',
                    cursor: 'pointer',
                  }}
                  className={styles.eventPicker__value}
                >
                  Купити
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default ItemInfo;
