import { DateRangeType } from '@project-m/schemas/dist/fields/DateRangeField';
import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';
import styles from './DateRange.module.sass';
import './DateRangePicker.sass';

interface DateRangeProps extends WidgetDataProps {
  range: DateRangeType;
  label: string;
}

const DateRange: React.FC<DateRangeProps> = ({
  label,
  range: { from: minDate, to: maxDate },
  value,
  onChange,
  error,
  required,
}) => {
  const [focused, setFocused] = useState<FocusedInputShape | null>(null);
  const { from, to } = value || {};

  const handleChange = useCallback(
    (startDate: Moment | null, endDate: Moment | null) => {
      onChange({ from: startDate?.valueOf(), to: endDate?.valueOf() });
    },
    [onChange]
  );

  return (
    <WidgetWrapper label={label} error={error} required={required}>
      <DateRangePicker
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        startDate={from ? moment(from) : null}
        startDateId="your_unique_start_date_id"
        endDate={to ? moment(to) : null}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) =>
          handleChange(startDate, endDate)
        }
        focusedInput={focused}
        onFocusChange={arg => setFocused(arg)}
        startDatePlaceholderText={'Від'}
        endDatePlaceholderText={'До'}
      />
    </WidgetWrapper>
  );
};

export default withFormConnected(DateRange);
