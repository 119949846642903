import React, { useCallback, useEffect, useState } from 'react';
import { removeOnId, replaceOnId } from 'helpers/arrays';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';

import BaseSchema from '@project-m/schemas';
import Form from 'components/forms/Form';
import MainSettingsSchema from '../../../../schemas/MainSettingsSchema'
import { SchemaErrorType } from '@project-m/schemas/dist/types';
import axios from 'axios'
import cancel from 'assets/images/cancel.svg';
import plus from 'assets/images/plus.svg';
import styles from './List.module.sass';
import useUserSettings from '../../../../hooks/useUserSettings'

const sendAdminMsg = async (userId:any, message:any) => {
  try {
    const res = await axios.post(
      `http://cme-cpd.com.ua:1337/admin/api/sendAdminMsg`,
      {userId, message}
    );

  } catch (error) {
    console.log(error);
  }
};

const sendEmail = async (toWhom:string,text:string) => {
  try {
    const res = await axios.post(
      `http://cme-cpd.com.ua:1337/admin/api/mail`,
      {
        emailForm: {title: 'Українська Академія Сімейного Лікаря',from:'no-reply@gmail.com',text},
        toWhom
      }
    );

    console.log(res.status);
  } catch (error) {
    console.log(error);
  }
}

const removePoints = async (userId: any, points: any) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/removePointsForSettings',
      {userId, points}
    );
  } catch (error) {
    console.log(error);
  }
}

interface ListProps<T = any> extends WidgetDataProps<T[]> {
  schema: BaseSchema<T>;
  addMoreText?: string;
  label?: string;
}

function getErrors<T = any>(
  allErrors: T[],
  currentError: T,
  length: number,
  id: number
) {
  if (!currentError) {
    return allErrors ? allErrors.filter((item, i) => i !== id) : allErrors;
  }

  if (currentError && !allErrors) {
    return [currentError];
  }

  return allErrors.map((item, i) => (i === id ? currentError : item));
}

const List: React.FC<ListProps> = ({
  value,
  error,
  onChange,
  onUpdate,
  schema,
  addMoreText,
  children,
  label,
}) => {
  const {value:main} = useUserSettings('main', MainSettingsSchema);

  const [text,setText] = useState('');
  const getForCert = (value:any) => value.country === 'ukraine' ? 50 : 100; 
  const getForDegree = (value:any) => value.country === 'ukraine' ? 50 : 100; 
  const getForDistance = (value:any) => value.language !== 7 ? 20 : 15; 
  const getForPublications = (value:any) => value.country === 'ukraine' ? 20 : 30; 
  const getForTrainings = (value:any) => value === 'ukraine' ? 20 : 25; 
  
  function monthDiff(dateFrom:Date, dateTo:Date):number {
    console.log(dateFrom,dateTo,'from=>to')
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
   }

  useEffect(() => {
    async function getTemplate() {
      try {
        const res = await axios.get(
          `http://cme-cpd.com.ua:1337/admin/api/getTwoWeeksTemplate`
        );
        setText(res.data.text);

         if(label === 'Сертификат Спеціаліста') {
            // @ts-ignore 
            if(value[0] !== undefined) {
              console.log(value[0],res.data.text);
                const diff = monthDiff(new Date(value[0].startDate) ,new Date(value[0].endDate));
                console.log(diff,'=> diff')
                if(diff <= 2) {
                  console.log('after all')
                  // @ts-ignore
                  sendAdminMsg(JSON.parse(localStorage.getItem('userId'),res.data.text));
                  // @ts-ignore
                  sendEmail(main.email,res.data.text);
                }
            }

        }
      } catch (error) {
        console.log(error);
      }
    }
    getTemplate();
  },[value]);

  const getExactPoints = (label:string,value:any) => {
      switch(label) {
        case 'Публікації в наукових виданнях':
        return getForPublications(value);

        case "Дистанційна форма навчання":
          return getForDistance(value);

        case 'Науковий ступінь':
          return getForDegree(value);

        case 'Тренінги та тематичні навчання':
          return getForTrainings(value);

        case 'Сертификат Спеціаліста':
          return getForCert(value);

        default:
          return 0;
      }
  }

  const handleChange = useCallback(
    (currentData: any, id: number) => {
      const newValue = replaceOnId(value, currentData.value, id);
      const newErrors = getErrors(
        error as SchemaErrorType[],
        currentData.errors,
        newValue.length,
        id
      );
      onUpdate!({
        value: newValue,
        errors: (newErrors as any) as SchemaErrorType,
      });
    },
    [value, error, onChange]
  );

  const handleAddMore = useCallback(
    () => {

      // if(label === 'Сертификат Спеціаліста') {
      //   console.log(text)
      //   // @ts-ignore
      //   sendAdminMsg(JSON.parse(localStorage.getItem('userId')),text);
      //   // @ts-ignore
      //   sendEmail(main.email,text);
      // }

      onUpdate!({
        value: [...value, schema.generateEmpty().value],
        errors: error as SchemaErrorType,
      })
    }
,
    [value, error, onChange,text]
  );
  const handleRemove = useCallback(
    // @ts-ignore
    ({ target: { dataset } }: React.MouseEvent<HTMLDivElement>) => {
      // @ts-ignore
      removePoints(JSON.parse(localStorage.getItem('userId')),getExactPoints(label,value)); 

      const id = parseInt(dataset.id, 10);
      onUpdate!({
        value: removeOnId(value, id),
        // @ts-ignore
        errors: error && removeOnId(error, id),
      });
    },
    [value, error, onChange]
  );

  
  // function monthDiff(dateFrom:Date, dateTo:Date):number {
  //   return dateTo.getMonth() - dateFrom.getMonth() + 
  //     (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  //  }

  //  if(label === 'Сертификат Спеціаліста') {
  //    const diff = monthDiff(new Date(1580767200000), new Date(1585774800000));
  //    console.log(diff,'=> diff')
  //    if(diff <= 2) {
  //      console.log('less than 2 motnh')
  //      // @ts-ignore
  //      sendAdminMsg(JSON.parse(localStorage.getItem('userId'),text));
  //      // @ts-ignore
  //      sendEmail(main.email,text);
  //    }
  // }

  return (
    <div className={styles.list}>
      {label && <div className={styles.label}>{label}</div>}
      {value.map((item, i) => (
        <div className={styles.item} key={i}>
          <div className={styles.delete} onClick={handleRemove} data-id={i}>
            <img src={cancel} alt={'Видалити'} data-id={i} />
          </div>
          <div className={styles.content}>
            <Form
              schema={schema}
              value={value[i]}
              errors={
                (error as SchemaErrorType) && (error as SchemaErrorType[])[i]
              }
              onChange={handleChange}
              dataId={i}
            >
              {React.cloneElement(<>{children}</>)}
            </Form>
          </div>
        </div>
      ))}
      <div onClick={handleAddMore} className={styles.addMore}>
        <img src={plus} alt={'Додати'} />
        {addMoreText || 'Додати ще'}
      </div>
    </div>
  );
};

export default withFormConnected<ListProps>(List);
