import React, { ComponentType } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SchemaDataType } from '@project-m/schemas/dist/types';
import Button from 'components/shared/Button';
import Modal, { ModalConsumerProps } from 'components/shared/Modal';
import { clns } from 'helpers/strings';
import { SignUpValueModel } from 'schemas/SignUpSchema';
import {
  setSignUpData,
  setSignUpStep,
  signUp,
} from 'store/modules/signUp/actions';
import { getSignUpData, getSignUpStep } from 'store/modules/signUp/selectors';
import { StepCallbackValue, StepProps } from './StepProps';
import First from './steps/First';
import Second from './steps/Second';
import Third from './steps/Third';

import signup_image from '../../../../assets/images/signup_image.png';
import style from './SignUp.module.sass';

const steps: Array<ComponentType<StepProps>> = [First, Second, Third];

const SignUp: React.FC<ModalConsumerProps> = ({ visible }) => {
  const step = useSelector(getSignUpStep);
  const data = useSelector(getSignUpData);
  const dispatch = useDispatch();
  const Step = steps[step];

  const handleChange = (value: SchemaDataType<StepCallbackValue>) => {
    dispatch(setSignUpData({ ...data, ...value.value } as SignUpValueModel));
  };

  const handleSubmit = () => {
    if (step < steps.length - 1) {
      dispatch(setSignUpStep(step + 1));
    } else {
      dispatch(signUp());
    }
  };

  return (
    <Modal name={'signUp'} image={signup_image} visible={visible}>
      <div className={style.signUp}>
        <div className={style.signUp__headline}>Реєстрація</div>
        <Step onChange={handleChange} onSubmit={handleSubmit} />
        {step > 0 && (
          <Button
            withBorders
            color={'blue'}
            onClick={() => dispatch(setSignUpStep(step - 1))}
            className={style.signUp__button}
          >
            Назад
          </Button>
        )}
        <div className={style.signUp__steps}>
          {steps.map((item, i) => (
            <div
              className={clns([
                style.signUp__step,
                step === i && style.signUp__step_active,
              ])}
              key={i}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SignUp;
