import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';
import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import { getValueByKeyFromTuple } from 'helpers/arrays';
import { clns } from 'helpers/strings';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './Select.module.sass';

interface SelectChoiceProps {
  label: string;
  value: any;
  onClick: (value: any) => void;
}

const SelectChoice = React.memo<SelectChoiceProps>(
  ({ label, value, onClick }) => {
    const handleClick = useCallback(() => onClick(value), [onClick, value]);
    return (
      <div className={styles.option} onClick={handleClick}>
        {label}
      </div>
    );
  }
);

interface SelectProps extends WidgetDataProps {
  className?: string;
  disableEmpty?: boolean;
  choices: (() => Promise<ChoicesType>) | ChoicesType;
  label?: string;
  placeholder?: string;
}

const Select: React.FC<SelectProps> = ({
  value = null,
  error,
  onChange,
  label,
  className,
  disableEmpty,
  required,
  choices: incomeChoices,
  placeholder = 'Оберіть',
}) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [choices, setChoices] = useState<ChoicesType | null>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const handleChange = useCallback(
    (choice: string | number) => {
      setOpened(false);
      onChange(choice);
    },
    [onChange]
  );
  const handleOpen = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useEffect(() => {
    if (Array.isArray(incomeChoices)) {
      setChoices(incomeChoices);
    } else {
      incomeChoices().then(data => setChoices(data));
    }
  }, []);

  useEffect(() => {
    if (wrapper.current) {
      const handler = ({ target }: MouseEvent) => {
        if (!wrapper.current!.contains(target as Node)) {
          setOpened(false);
        }
      };
      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }
  }, [wrapper]);

  return (
    <WidgetWrapper
      label={label}
      error={error}
      className={className}
      required={required}
    >
      <div
        className={clns([
          styles.field,
          opened && styles.opened,
          error && styles.error,
        ])}
        ref={wrapper}
      >
        <div className={clns([styles.arrow, opened && styles.opened])} />
        <div className={styles.current} onClick={handleOpen}>
          <div className={styles.content}>
            {value && choices
              ? getValueByKeyFromTuple(value, choices)
              : choices
              ? placeholder
              : 'Завантаження...'}
          </div>
        </div>
        {opened && (
          <div className={styles.options}>
            {!disableEmpty && (
              <SelectChoice
                value={null}
                label={placeholder}
                onClick={handleChange}
              />
            )}
            {choices?.map(([choiceValue, choiceLabel]) => (
              <SelectChoice
                key={choiceValue}
                value={choiceValue}
                label={choiceLabel}
                onClick={handleChange}
              />
            ))}
          </div>
        )}
      </div>
    </WidgetWrapper>
  );
};

export default withFormConnected<SelectProps>(Select);
