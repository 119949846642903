const EDIT_LINKS = [
  ['/edit', 'Основні дані'],
  ['/edit/education', 'Вища освіта'],
  ['/edit/postgraduate', 'Післядипломна освіта'],
  ['/edit/experience', 'Професійна діяльність'],
  ['/edit/teaching', 'Навчально-методична діяльність'],
  ['/edit/innovative', 'Науково-дослідницька діяльність'],
];

export default EDIT_LINKS;
