import ListResponse from 'infrastructure/models/common/ListResponse';
import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import EventDto from '../../models/events/EventDto';
import EventListItemDto from '../../models/events/EventListItemDto';
import IEventsProvider, {
  EventRegistrationData,
  PreparedFilters,
} from './IEventsProvider';

@injectable()
export default class ApiEventsProvider implements IEventsProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;

  public async getBySlug(slug: string): Promise<EventDto> {
    const event: EventDto = await this.apiClient.request(
      'events',
      'getBySlug',
      slug
    );
    return event;
  }

  public async getList(
    filters: PreparedFilters
  ): Promise<ListResponse<EventListItemDto>> {
    return this.apiClient.request('events', 'getList', filters);
  }

  public like(id: string, status: boolean): Promise<void> {
    return this.apiClient.request('events', 'like', { id, status });
  }

  public register(eventId: string, data: EventRegistrationData): Promise<void> {
    return this.apiClient.request('events', 'register', { ...data, eventId });
  }
}
