import React from 'react';

export interface SeoDataModel {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  og?: Record<string, string>;
}

interface SeoContextModel {
  setSeo: (data: SeoDataModel) => void;
}

const SeoContext = React.createContext<SeoContextModel>({
  setSeo: () => {},
});

export default SeoContext;
