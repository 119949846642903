import React, {useContext} from 'react';

import CourseLectionListItem from 'components/pages/Courses/CourseLections/blocks/CourseLectionListItem';
import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import styles from './CourseLectionsList.module.sass';

const CourseLectionsList: React.FC = () => {
  const {setLection, course, currentLection} = useContext(
    CourseLectionsContext
  );

  function alphabetically(ascending: boolean) {
    return function (a: any, b: any) {
      if (a.sortId === b.sortId) {
        return 0;
      } else if (a.sortId === null) {
        return 1;
      } else if (b.sortId === null) {
        return -1;
      } else if (ascending) {
        return a.sortId < b.sortId ? -1 : 1;
      } else {
        return a.sortId < b.sortId ? 1 : -1;
      }
    };
  }

  return (
    <div className={styles.wrapper}>
      {course?.lections
        .sort(alphabetically(true))
        .map((lection: any, i: any, arr: any) => {
          console.log(lection);
          const prevLection = i > 0 && arr[i - 1];
          const disabled =
            prevLection &&
            (!prevLection.videoWatched || !prevLection.testComplete);

          return (
            <CourseLectionListItem
              key={lection.id}
              disabled={disabled}
              active={lection.id === currentLection?.id}
              onChoose={setLection}
              {...lection}
            />
          );
        })}
    </div>
  );
};

export default CourseLectionsList;
