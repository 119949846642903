import { inject, injectable } from 'inversify';
import container from '../../ioc';
import IAuthProvider, {
  SIAuthProvider,
} from '../providers/AuthProvider/IAuthProvider';
import IApiClient from './IApiClient';

const API_URL =
  process.env.REACT_APP_API_URL ?? 'http://okio.space:1337/app/api';

@injectable()
export default class ApiClient implements IApiClient {
  private access?: string;
  private refresh?: string;
  private authProvider: IAuthProvider;
  constructor() {
    this.getAuthTokens();
    this.authProvider = container.get(SIAuthProvider);
    this.authProvider.on('change', (status) => {
      if (status) {
        this.getAuthTokens();
      } else {
        this.removeAuthTokens();
      }
    });
  }
  public async request<Input, Result>(
    module: string,
    method: string,
    params: Input
  ): Promise<Result> {
    const req = await fetch(`${API_URL}/api`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(this.access ? { Authorization: `Bearer ${this.access}` } : {}),
      },
      body: JSON.stringify({
        module,
        method,
        params,
      }),
    });
    const result = await req.json();
    if (req.status !== 200) {
      if (req.status === 401) {
        await this.refreshToken();
      }
      throw new Error(result.error);
    }
    return result as Result;
  }
  // todo: finish it
  private refreshToken() {
    this.access = undefined;
    return this.request('auth', 'refresh', this.refresh);
  }

  private getAuthTokens() {
    const json = localStorage.getItem('auth');
    if (json) {
      const tokens = JSON.parse(json);
      this.access = tokens.access;
      this.refresh = tokens.refresh;
    }
  }

  private removeAuthTokens() {
    this.access = undefined;
    this.refresh = undefined;
  }
}
