import EventListItemDto from 'infrastructure/models/events/EventListItemDto';
import IEventsProvider, {
  SIEventsProvider,
} from 'infrastructure/providers/EventsProvider/IEventsProvider';
import container from 'ioc';
import createListModule from 'store/helpers/createListModule';

const provider = () => container.get<IEventsProvider>(SIEventsProvider);

const {
  actions: eventsActions,
  actionTypes: eventsActionTypes,
  selectors: eventsSelectors,
  reducer: eventsReducer,
  handlers: eventsHandlers,
} = createListModule<EventListItemDto>('events', provider);

export {
  eventsActions,
  eventsActionTypes,
  eventsHandlers,
  eventsReducer,
  eventsSelectors,
};
