import AvatarField from 'components/pages/Account/blocks/User/AvatarField';
import {clns} from 'helpers/strings';
import capitalize from 'lodash/capitalize';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {profileSelector} from 'store/modules/profile/selectors';
import styles from './User.module.sass';
import axios from 'axios';

interface UserProps {
  plain?: boolean;
  className?: string;
}

const updateLastSeen = async (userId: any) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/updateLastSeen',
      {userId}
    );
  } catch (error) {
    console.log(error);
  }
};

const User: React.FC<UserProps> = ({plain, className}) => {
  const user = useSelector(profileSelector);

  useEffect(() => {
    updateLastSeen(user?.id);
  }, [user]);

  return (
    <div className={clns([styles.user, plain && styles.user_plain, className])}>
      {user && (
        <>
          <AvatarField />
          <div className={styles.user__right}>
            <div className={styles.user__name}>{user.name}</div>
            {user?.specialization && (
              <div className={styles.user__occupation}>
                {capitalize(user.specialization)}
              </div>
            )}
            {user?.city && (
              <div className={styles.user__location}>{user.city}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default User;
