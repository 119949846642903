import RegistrationModel from '@project-m/core/entities/auth/RegistrationModel';

export const SIUserProvider = Symbol.for('IUserProvider');

export default interface IUserProvider {
  login(email: string, password: string): Promise<void>;
  logOut(): Promise<void>;
  register(model: RegistrationModel): Promise<void>;
  resetPassword(email: string): Promise<void>;
  continuePasswordReset(token: string, password: string): Promise<void>;
}
