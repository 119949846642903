import 'moment/locale/uk'

import React, { useCallback, useContext, useEffect, useState } from 'react';

import Button from 'components/shared/Button';
import CourseContent from '../../blocks/CourseContent';
import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import CourseTesting from '../../blocks/CourseTesting';
import { StepTypes } from '../../state';
import axios from 'axios';
import moment from 'moment';
import styles from './CourseLection.module.sass';

const getPoints = async (userId: any, points: any) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/getPointsForLink',
      {userId, points}
    );
  } catch (error) {
    console.log(error);
  }
};

const CourseLection = () => {
  const { currentLection: lection, step, handleStepChange } = useContext(
    CourseLectionsContext
  );
  const {course} = useContext(CourseLectionsContext);
  const { videoWatched: watched, name } = lection!;
  const [isExists, setExist] = useState(false);
  const [lecture, setLink] = useState('');

  const reWatch = useCallback(() => {
    handleStepChange(StepTypes.WATCHING);
  }, []);

  const getCertificate = async (
    url: string,
    date: string,
    points: number,
    name: string,
    userId: string,
    city: number,
    title: string,
    number:any
  ) => {
    try {
      const res = await axios.post(
        'http://cme-cpd.com.ua:1337/admin/api/createCrt',
        {
          url,
          date,
          points,
          name,
          userId,
          city,
          title,
          number
        }
      );

      if (res.data === 'exist') setExist(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reWatch();
  }, [lection!.id]);

  const handleStartTesting = useCallback(() => {
    handleStepChange(StepTypes.TESTING);
  }, []);

  useEffect(() => {
    async function getLink() {
      try {
        const res = await axios.post(
          `http://cme-cpd.com.ua:1337/admin/api/getLink`,
          {id: course?.id}
        );

        console.log(course);

        setLink(res.data);
        getCertificate(
          res.data.certificate,
          moment(Date.now()).format('LL'),
          res.data.reward,
          //@ts-ignore
          `${value?.name} ${value?.surname}`,
          // @ts-ignore
          JSON.parse(localStorage.getItem('userId')),
          // @ts-ignore
          value?.city,
          // @ts-ignore
          course?.name,
          // @ts-ignore
          course?.id
        );
      } catch (error) {
        console.log(error);
      }
    }

    getLink();
  },[])

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline}>Модуль: {name}</div>
      {step === StepTypes.WATCHING ? <CourseContent /> : <CourseTesting />}
      <div className={styles.buttons}>
        {watched && step !== StepTypes.TESTING && (
          <Button filled color={'lightBlue'} onClick={handleStartTesting}>
            Почати тестування
          </Button>
        )}
      </div>
      <div className={styles.text} style={{margin: '20px 0'}}>
        {/* @ts-ignore */}
        {lecture.clinicalLink !== '' && (
          <a
            // @ts-ignore
            href={lecture.clinicalLink}
            className={styles.link}
            style={{
              color: '#fff',
              background: '#FF7070',
              padding: '10px 20px',
              borderRadius: '10px',
              textDecoration: 'none',
            }}
            onClick={() => {
              alert(
                'Ви переходите на сторінку, яка може містити комерційний інтерес'
              );
              getPoints(
                // @ts-ignore
                JSON.parse(localStorage.getItem('userId')),
                // @ts-ignore
                lecture.pointsForLink
              );
            }}
            target='_blank'
          >
            Переглянути клінічні випадки.
          </a>
        )}
      </div>
    </div>
  );
};

export default CourseLection;
