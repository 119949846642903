import Id from '@project-m/core/entities/common/Id';
import ListResponse from 'infrastructure/models/common/ListResponse';
import { injectable } from 'inversify';
import range from 'lodash/range';
import news_pic_1 from '../../../assets/images/news_pic_1.png';

import EventDto from '../../models/events/EventDto';
import EventFilters from '../../models/events/EventFilters';
import EventListItemDto from '../../models/events/EventListItemDto';
import IEventsProvider, { EventRegistrationData } from './IEventsProvider';

const data: EventListItemDto[] = range(10).map(id => ({
  id: id.toString(),
  image: news_pic_1,
  title: `Молодежная нестезиологическая конференция 4 «Трещинский чтения» Молодежная нестезиологическая ${id}`,
  date: '02.09.19',
  slug: 'meropriyatie-tam-to',
  city: 'Киев',
  price: '1000 грн',
  liked: false,
}));

const event: EventDto = {
  id: '1',
  title: 'Міжнародні стандарти надання гінекологічної допомоги',
  description: `
<h2>
Шановні колеги! Запрошуємо Вас взяти участь у роботі Науково-практичної 
конференції «Тромбопрофілактика в Україні. Сучасні світові тенденції», 
яка відбудеться 11 жовтня 2019 року у м. Київ.
</h2>
<h2>Мета проекту:</h2>
<p>
Тромбоз і його найчастіші наслідки - ІНСУЛЬТ та ІНФАРКТ є одними з провідних 
причин смертності пацієнтів із серцево-судинними захворюваннями. Тому, 
мета даного проекту - привернути увагу фахівців до проблеми тромбопрофілактики 
в Україні та ознайомити зі світовим досвідом боротьби з цією патологією.
</p>
<p>
Захід внесено до реєстру з'їздів, конгресів, симпозіумів та науково-практичних 
конференцій, які проводитимуться в Україні в 2019 році.
</p>
<h2>Регистрация на сайте : </h2>
<p><a href="//wtd-ukraine.org">wtd-ukraine.org</a></p>
<h2>Кому будет интерессно:</h2>
<ul>
<li>Хірурги</li>
<li>Анестезіологи</li>
<li>Акушери-гінекологи</li>
<li>Кардіологи</li>
<li>Лікарі відділень невідкладної медичної допомоги</li>
<li>Неврологи</li>
<li>Лікарі загальної практики (сімейні лікарі)</li>
<li>Співробітники профільних кафедр вищих медичних навчальних закладів та закладів післядипломної освіти</li>
</ul>
<p>Якщо вас цікавить лекція, але не виходить відвідати її особисто — приєднуйтесь до онлайн-трансляції.</p>
`,
  relatedEvents: data,
  dateTime: '',
  place: '',
  price: '',
  image: '',
  lecturers: [],
  dates: [],
};

@injectable()
export default class MockEventsProvider implements IEventsProvider {
  public async getBySlug(slug: string): Promise<EventDto> {
    return event;
  }

  public async getList(
    filters: EventFilters
  ): Promise<ListResponse<EventListItemDto>> {
    return { list: data, pages: 3 };
  }

  public async like(id: Id, status: boolean): Promise<void> {
    return;
  }

  public register(id: Id, reg: EventRegistrationData): Promise<void> {
    return Promise.resolve();
  }
}
