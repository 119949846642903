import download from 'assets/images/download.png';
import watch from 'assets/images/watch.png';
import styles from 'components/pages/Account/pages/Certificates/Certificates.module.sass';
import RoundButton from 'components/shared/RoundButton';
import { SertificateDto } from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import moment from 'moment';
import React, { useCallback } from 'react';

interface CertificateProps {
  certificate: SertificateDto;
}

const Certificate: React.FC<CertificateProps> = ({ certificate }) => {
  // const handleDownload = useCallback(() => {
  //   const link = document.createElement('a');
  //   link.style.display = 'none';
  //   link.href = certificate.file;
  //   link.download = 'file.pdf';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }, []);

  const handleOpen = useCallback(() => {
    const win = window.open(certificate.file, '_blank');
    if (win) {
      win.focus();
    }
  }, []);

  return (
    <div className={styles.certificates__certificate}>
      <div className={styles.certificates__logo}>
        <img
          className={styles.certificates__image}
          src={certificate.image}
          alt={''}
        />
      </div>
      <div className={styles.certificates__left}>
        <div className={styles.certificates__info}>
          <div className={styles.certificates__name}>{certificate.name}</div>
          {certificate.points && (
            <div className={styles.certificates__description}>
              Кількість балів за сертифікат: {certificate.points}
            </div>
          )}
        </div>
        <div className={styles.certificates__date}>
          Додано: {moment(certificate.createdAt).format('DD.MM.YYYY')}
        </div>
      </div>
      <div className={styles.certificates__right}>
        <div className={styles.certificates__buttons}>
          <RoundButton
            icon={watch}
            className={styles.certificates__button}
            onClick={handleOpen}
          />
          <RoundButton
            icon={download}
            className={styles.certificates__button}
            onClick={handleOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Certificate;
