import React, { useEffect, useState } from 'react';
import { sleep } from '../../../helpers/aio';
import { clns } from '../../../helpers/strings';

import styles from './ProgressCircle.module.sass';

interface ProgressCircleProps {
  percentage: number;
  withNumber?: boolean;
  size?: number;
  className?: string;
  noTransition?: boolean;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  percentage,
  withNumber,
  size,
  className,
  children,
  noTransition,
}) => {
  const canvasSize = size || 150;
  const circleCenter = canvasSize / 2;
  const circleRadius = canvasSize / 2 - 10;
  const circleLength = 2 * Math.PI * circleRadius;

  const [filled, setFilled] = useState<number>(circleLength);

  useEffect(() => {
    (async () => {
      await sleep(1);
      setFilled(circleLength - (circleLength * percentage) / 100);
    })();
  }, [percentage, size, circleLength]);

  return (
    <div className={clns([styles.progressCircle, className])}>
      <svg
        viewBox={`0 0 ${canvasSize} ${canvasSize}`}
        xmlns="http://www.w3.org/2000/svg"
        className={clns([
          styles.progressCircle__circle,
          noTransition && styles.progressCircle__circle_noTransition,
        ])}
      >
        <circle cx={circleCenter} cy={circleCenter} r={circleRadius} />
        <circle
          cx={circleCenter}
          cy={circleCenter}
          r={circleRadius}
          strokeDasharray={circleLength}
          strokeDashoffset={filled}
        />
      </svg>
      <div className={styles.progressCircle__info}>
        {withNumber && (
          <div className={styles.progressCircle__number}>
            {Math.floor(percentage)}%
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default ProgressCircle;
