import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Select from 'components/forms/Widgets/Select';

import {
  MainSignUpSignSchema,
  MainSignUpValueModel,
} from 'schemas/SignUpSchema';

import { getSignUpData } from 'store/modules/signUp/selectors';
import { StepProps } from '../StepProps';

const First: React.FC<StepProps> = ({ onChange, onSubmit }) => {
  const [{ value, errors }, setData] = useState(
    MainSignUpSignSchema.toInternal(
      pick(
        useSelector(getSignUpData),
        Object.keys(MainSignUpSignSchema.fields)
      ) as MainSignUpValueModel
    )
  );

  useEffect(() => {
    onChange({ value, errors });
  }, [value, errors]);

  return (
    <Form
      schema={MainSignUpSignSchema}
      value={value}
      errors={errors}
      onChange={setData}
      onSubmit={onSubmit}
    >
      <Form.Row>
        <Input.Connected name={'name'} label={"Ім'я"} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'surname'} label={'Прізвище'} />
      </Form.Row>
      <Form.Row>
        <Input.Connected name={'patronymic'} label={'По батькові'} />
      </Form.Row>
      <Form.Row>
        <Select.Connected name={'gender'} label={'Стать'} />
      </Form.Row>
      <Form.Button>Далі</Form.Button>
    </Form>
  );
};

export default First;
