import { useEffect, useState } from 'react';
import CourseListItemDto from '../infrastructure/models/courses/CourseListItemDto';
import ICourseProvider, {
  SICourseProvider,
} from '../infrastructure/providers/CourseProvider/ICourseProvider';
import container from '../ioc';

const provider = () => container.get<ICourseProvider>(SICourseProvider);

export default function useLastCourses(): CourseListItemDto[] {
  const [courses, setCourses] = useState<CourseListItemDto[]>([]);

  useEffect(() => {
    provider()
      .getList({ page: 1, count: 3 })
      .then(({ list }) => setCourses(list));
  }, []);

  return courses;
}
