import { ChoicesType } from '@project-m/schemas/dist/fields/ChoiceField';

export const SIOptionsProvider = Symbol.for('IOptionsProvider');

export type Options = ChoicesType;

export default interface IOptionsProvider {
  getCategories(): Promise<Options>;
  getCities(): Promise<Options>;
  getSpecialities(): Promise<Options>;
}
