import React from 'react';

import RoundButton from '../../../../shared/RoundButton';

import { standarts } from '../../../../../constants/data';

import download from '../../../../../assets/images/download.png';
import watch from '../../../../../assets/images/watch.png';
import styles from './Standarts.module.sass';

const Standarts = () => {
  return (
    <div className={styles.standarts}>
      {standarts.map((standart, i) => (
        <div className={styles.standarts__standart} key={i}>
          <div className={styles.standarts__left}>
            <div className={styles.standarts__info}>
              <div className={styles.standarts__name}>{standart.name}</div>
              <div className={styles.standarts__description}>
                {standart.description}
              </div>
            </div>
            <div className={styles.standarts__date}>
              Додано: {standart.date}
            </div>
          </div>
          <div className={styles.standarts__center}>
            <div className={styles.standarts__tags}>
              {standart.tags.map(tag => (
                <div className={styles.standarts__tag} key={tag}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.standarts__right}>
            <div className={styles.standarts__buttons}>
              <RoundButton icon={watch} className={styles.standarts__button} />
              <RoundButton
                icon={download}
                className={styles.standarts__button}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Standarts;
