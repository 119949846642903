import { Container } from 'inversify';
import ApiClient from '../infrastructure/ApiClient/ApiClient';
import IApiClient, {
  SIApiClient,
} from '../infrastructure/ApiClient/IApiClient';
import ApiAuthProvider from '../infrastructure/providers/AuthProvider/ApiAuthProvider';
import IAuthProvider, {
  SIAuthProvider,
} from '../infrastructure/providers/AuthProvider/IAuthProvider';
import ApiCourseProvider from '../infrastructure/providers/CourseProvider/ApiCourseProvider';
import ICourseProvider, {
  SICourseProvider,
} from '../infrastructure/providers/CourseProvider/ICourseProvider';
import ApiEventsProvider from '../infrastructure/providers/EventsProvider/ApiEventsProvider';
import IEventsProvider, {
  SIEventsProvider,
} from '../infrastructure/providers/EventsProvider/IEventsProvider';
import ApiFaqProvider from '../infrastructure/providers/FaqProvider/ApiFaqProvider';
import IFaqProvider, {
  SIFaqProvider,
} from '../infrastructure/providers/FaqProvider/IFaqProvider';
import { ApiInitializationProvider } from '../infrastructure/providers/InitializationProvider/ApiInitializationProvider';
import IInitializationProvider, {
  SIInitializationProvider,
} from '../infrastructure/providers/InitializationProvider/IInitializationProvider';
import ILocaleProvider, {
  SILocaleProvider,
} from '../infrastructure/providers/LocaleProvider/ILocaleProvider';
import MockLocaleProvider from '../infrastructure/providers/LocaleProvider/MockLocaleProvider';
import ApiNewsProvider from '../infrastructure/providers/NewsProvider/ApiNewsProvider';
import INewsProvider, {
  SINewsProvider,
} from '../infrastructure/providers/NewsProvider/INewsProvider';
import INotificationsProvider, {
  SINotificationsProvider,
} from '../infrastructure/providers/NotificationsProvider/INotificationsProvider';
import MockNotificationsProvider from '../infrastructure/providers/NotificationsProvider/MockNotificationsProvider';
import ApiOptionsProvider from '../infrastructure/providers/OptionsProvider/ApiOptionsProvider';
import IOptionsProvider, {
  SIOptionsProvider,
} from '../infrastructure/providers/OptionsProvider/IOptionsProvider';
import ApiPageDataProvider from '../infrastructure/providers/PageDataProvider/ApiPageDataProvider';
import IPageDataProvider, {
  SIPageDataProvider,
} from '../infrastructure/providers/PageDataProvider/IPageDataProvider';
import { ApiProfileProvider } from '../infrastructure/providers/ProfileProvider/ApiProfileProvider';
import IProfileProvider, {
  SIProfileProvider,
} from '../infrastructure/providers/ProfileProvider/IProfileProvider';
import ISearchProvider, {
  SISearchProvider,
} from '../infrastructure/providers/SearchProvider/ISearchProvider';
import MockSearchProvider from '../infrastructure/providers/SearchProvider/MockSearchProvider';
import ApiUserProvider from '../infrastructure/providers/UserProvider/ApiUserProvider';
import IAuthNotifiable, {
  SIAuthNotifiable,
} from '../infrastructure/providers/UserProvider/IAuthNotifiable';
import IUserProvider, {
  SIUserProvider,
} from '../infrastructure/providers/UserProvider/IUserProvider';

const injectObjects = (container: Container) => {
  container
    .bind<IAuthProvider>(SIAuthProvider)
    .to(ApiAuthProvider)
    .inSingletonScope();
  container
    .bind<IApiClient>(SIApiClient)
    .to(ApiClient)
    .inSingletonScope();

  container.bind<INewsProvider>(SINewsProvider).to(ApiNewsProvider);
  container.bind<IUserProvider>(SIUserProvider).to(ApiUserProvider);

  const authNotifiable = container.get<IAuthNotifiable>(SIAuthProvider);
  container
    .bind<IAuthNotifiable>(SIAuthNotifiable)
    .toConstantValue(authNotifiable);

  container.bind<IFaqProvider>(SIFaqProvider).to(ApiFaqProvider);
  container.bind<IEventsProvider>(SIEventsProvider).to(ApiEventsProvider);
  container.bind<IPageDataProvider>(SIPageDataProvider).to(ApiPageDataProvider);
  container.bind<ICourseProvider>(SICourseProvider).to(ApiCourseProvider);
  container
    .bind<IInitializationProvider>(SIInitializationProvider)
    .to(ApiInitializationProvider);
  container.bind<IProfileProvider>(SIProfileProvider).to(ApiProfileProvider);
  container.bind<IOptionsProvider>(SIOptionsProvider).to(ApiOptionsProvider);

  // mocks
  container.bind<ILocaleProvider>(SILocaleProvider).to(MockLocaleProvider);
  container
    .bind<INotificationsProvider>(SINotificationsProvider)
    .to(MockNotificationsProvider);

  container.bind<ISearchProvider>(SISearchProvider).to(MockSearchProvider);
};

export default injectObjects;
