import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import React, { useCallback, useContext, useState } from 'react';

import { LectionDto, QuestionDto } from 'infrastructure/models/courses/common';
import CourseTestingFinish from './CourseTestingFinish';
import CourseTestingProgress, {
  AnswersHistoryModel,
} from './CourseTestingProgress';
import CourseTestingStart from './CourseTestingStart';

import styles from './CourseTesting.module.sass';

export interface TestingTimeModel {
  start: number | null;
  finish: number | null;
}

enum TestingSteps {
  START,
  TESTING,
  FINISHED,
}

const CourseTesting: React.FC = () => {
  const { currentLection } = useContext(CourseLectionsContext);
  const { name } = currentLection!;
  const [step, setStep] = useState<TestingSteps>(TestingSteps.START);
  const [stats, setStats] = useState<AnswersHistoryModel | null>(null);
  const [testingTime, setTestingTime] = useState<TestingTimeModel>({
    start: null,
    finish: null,
  });

  const handleStart = useCallback(() => {
    setTestingTime({ ...testingTime, start: Date.now() });
    setStep(TestingSteps.TESTING);
  }, [testingTime]);

  const handleFinish = useCallback(
    (answerStats: AnswersHistoryModel) => {
      setTestingTime({ ...testingTime, finish: Date.now() });
      setStats(answerStats);
      setStep(TestingSteps.FINISHED);
    },
    [testingTime]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline}>
        Контрольне тестування з модуля «{name}»
      </div>
      {step === TestingSteps.START && (
        <CourseTestingStart onStart={handleStart} />
      )}
      {step === TestingSteps.TESTING && (
        <CourseTestingProgress onFinish={handleFinish} />
      )}
      {step === TestingSteps.FINISHED && (
        <CourseTestingFinish stats={stats!} time={testingTime} />
      )}
    </div>
  );
};

export default CourseTesting;
