import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../Button';
import {Link} from 'react-router-dom'
import Notifications from './blocks/Notifications';
import bell from '../../../assets/images/bell.png';
import {clns} from '../../../helpers/strings';
import {isAuthorized} from '../../../store/modules/auth/selectors';
import {logOut} from '../../../store/modules/auth/actions';
import logout from '../../../assets/images/logout.png';
import {setModal} from '../../../store/modules/ui/actions';
import styles from './UserActions.module.sass';

interface UserActionsProps {
  className?: string;
  mobile?: boolean;
}

const UserActions: React.FC<UserActionsProps> = ({className, mobile}) => {
  const dispatch = useDispatch();
  const authorized = useSelector(isAuthorized);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [unread, setUnread] = useState<boolean>(false);

  useEffect(() => {
    if (isOpened) {
      setUnread(false);
    }
  }, [isOpened]);

  return (
    <div className={clns([styles.userActions, className])}>
      {authorized ? (
        <>
        <Link to='/account' style={{marginRight: '10px'}} onClick={() => dispatch(setModal('menu', false))}>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="35" height="35" viewBox="0 0 24 24" stroke-width="1.5" stroke="#03A9F4" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="7" r="4" />
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>
        </Link>
          <div
            className={clns([
              styles.userActions__iconWrapper,
              unread && styles.userActions__iconWrapper_new,
            ])}
          >
            <img
              src={bell}
              alt={''}
              className={styles.userActions__icon}
              onClick={() => setOpened(!isOpened)}
            />
            <Notifications
              className={clns([
                styles.userActions__notifications,
                isOpened && styles.userActions__notifications_opened,
              ])}
              onClose={() => setOpened(false)}
              onNew={() => setUnread(true)}
              // @ts-ignore
              isOpened={isOpened}
            />
          </div>
          <div className={styles.userActions__iconWrapper}>
            <img
              src={logout}
              alt={''}
              className={styles.userActions__icon}
              onClick={() => dispatch(logOut())}
            />
          </div>
        </>
      ) : (
        <>
          <Button
            color={mobile ? 'blue' : 'black'}
            withBorders={!!mobile}
            onClick={() => dispatch(setModal('login', true))}
            className={styles.userActions__button}
          >
            Вхід
          </Button>
          <Button
            color={'red'}
            withBorders
            onClick={() => dispatch(setModal('signUp', true))}
            className={styles.userActions__button}
          >
            Реєстрація
          </Button>
        </>
      )}
    </div>
  );
};

export default UserActions;
