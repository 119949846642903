import range from 'lodash/range';
import React, { useEffect, useRef } from 'react';

import withPlaceholder from 'components/hocs/withPlaceholder';
import { clns } from 'helpers/strings';

import styles from './HTMLContent.module.sass';

interface HTMLContentProps {
  content: string;
  className?: string;
}

const HTMLContent: React.FC<HTMLContentProps> = ({ content, className }) => {
  const container = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (container.current) {
      const handler = (e: MouseEvent) => {
        e.preventDefault();
        const tagName = (e.target as any).tagName;

        if (tagName === 'A') {
          const win = window.open((e.target as any).href, '_blank');
          if (win) {
            win.focus();
          }
        }
      };
      container.current!.addEventListener('click', handler);
      return () => container.current!.removeEventListener('click', handler);
    }
  }, [container.current]);

  return (
    <div
      className={clns([styles.htmlContent, className])}
      dangerouslySetInnerHTML={{ __html: content }}
      ref={container}
    />
  );
};

const placeholder = () => (
  <HTMLContent
    content={[
      ...range(6).map(
        () => `<div class="${styles.htmlContent__linePlaceholder}"></div>`
      ),
      `<div class="${styles.htmlContent__imagePlaceholder}"></div>`,
      ...range(20).map(
        () => `<div class="${styles.htmlContent__linePlaceholder}"></div>`
      ),
    ].join('')}
  />
);

export default withPlaceholder(placeholder, HTMLContent);
