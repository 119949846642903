import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import PageData, { PageBlocks } from '../../models/pages/PageData';
import { Page } from './blocks/Page';
import IPageDataProvider from './IPageDataProvider';

@injectable()
export default class ApiPageDataProvider implements IPageDataProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;

  public async get(slug: string): Promise<PageData> {
    const data = await this.apiClient.request('pages', 'getPage', slug);
    const page = new Page();
    return page.serialize(data as Record<string, any>) as PageData;
  }

  public getBlock(name: string): Promise<PageBlocks> {
    return this.apiClient.request('pages', 'getBlock', name);
  }
}
