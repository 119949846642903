import ICourseProvider, {
  SICourseProvider,
} from 'infrastructure/providers/CourseProvider/ICourseProvider';
import ItemInfo, {ItemInfoLayoutType} from 'components/shared/ItemInfo';
import React, {useContext, useEffect} from 'react';
import {getLocation, push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';

import {AppState} from 'store/reducer';
import Button from 'components/shared/Button';
import ContentPage from 'components/shared/ContentPage';
import CountBlock from 'components/shared/CountBlock';
import CoursePreviewDto from 'infrastructure/models/courses/CoursePreviewDto';
import HTMLContent from 'components/shared/HTMLContent';
import {Location} from 'history';
import SeoContext from 'contexts/SeoContext';
import TopBlock from 'components/shared/TopBlock';
import container from '../../../../ioc';
import {match} from 'react-router';
import moment from 'moment';
import styles from 'components/pages/Courses/CoursePreview/CoursePreview.module.sass';
import useAlerts from 'hooks/useAlerts';
import useGetBy from 'hooks/useGetBy';

const languages: Record<string, string> = {
  ua: 'Українська',
  ru: 'Російська',
  en: 'Англійська',
};

const layout: ItemInfoLayoutType = {
  reward: {label: 'Бали'},
  type: {label: 'Тип курсу'},
  language: {label: 'Мова'},
  duration: {label: 'Тривалість'},
};

interface TrainingDetailProps {
  match: match<{slug: string}>;
}

const CoursePreview: React.FC<TrainingDetailProps> = ({
  match: {
    params: {slug},
  },
}) => {
  const {value} = useGetBy<CoursePreviewDto>(SICourseProvider, 'getPreview', [
    slug,
  ]);
  const {pathname} = useSelector<AppState, Location>(getLocation);
  const {setSeo} = useContext(SeoContext);
  const {fireWarning} = useAlerts();
  const dispatch = useDispatch();
  const apply = () => {
    container
      .get<ICourseProvider>(SICourseProvider)
      .add(value?.id!)
      .then(() => dispatch(push(pathname + '/testing')))
      .catch(() =>
        fireWarning('Для проходження тестування вам  необхідно увійти')
      );
  };

  useEffect(() => {
    if (value) {
      setSeo({
        title: value.seo?.title || value.title,
        description: value.seo?.description || value.description,
        image: value.image,
        type: 'product',
      });
    }
  }, [value]);

  return (
    value && (
      <ContentPage backLink={{link: '/courses', name: 'Курси'}}>
        <TopBlock
          headline={value.title}
          image={value.image}
          buttons={[
            {
              label: 'Пройти курс',
              filled: true,
              className: styles.topButton,
              onClick: apply,
            },
          ]}
        >
          {/* @ts-ignore */}
          <ItemInfo
            data={[
              {
                reward: value.reward,
                type: 'Відео-лекція',
                language: languages[value.language],
                duration: `${value.duration} хв`,
              },
            ]}
            layout={layout}
          />
        </TopBlock>
        <CountBlock
          currentNumber={1}
          quantity={4}
          headline={'Про курс'}
          text={value.about}
          textFull
        />
        <CountBlock currentNumber={2} quantity={4} headline={'Детальніше'}>
          <HTMLContent content={value.description} />
        </CountBlock>
        <CountBlock currentNumber={3} quantity={4} headline={'Автор курса'}>
          <div className={styles.author}>
            <div className={styles.authorImage}>
              <img src={value?.author.image} alt={''} />
            </div>
            <div className={styles.authorDetails}>
              <div className={styles.authorName}>{value?.author.name}</div>
              <div className={styles.authorRank}>{value?.author.rank}</div>
            </div>
          </div>
        </CountBlock>
        <CountBlock currentNumber={4} quantity={4} headline={'Почати зараз!'}>
          {value.lastWords && (
            <p className={styles.bottomText}>{value.lastWords}</p>
          )}
          <Button color='red' filled onClick={apply}>
            Пройти курс
          </Button>
        </CountBlock>
      </ContentPage>
    )
  );
};

export default CoursePreview;
