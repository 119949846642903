import ProfileDto from '../../../infrastructure/models/profile/ProfileDto';
import { ProfileActionTypes } from './constants';

export interface ProfileState {
  info?: ProfileDto;
}

const initialState = {};

const profileReducer = (state: ProfileState = initialState, action: any) => {
  if (action.type === ProfileActionTypes.SET) {
    return { info: action.payload.profile };
  }
  if (action.type === ProfileActionTypes.SET_AVATAR) {
    return { info: { ...state.info, avatar: action.payload } };
  }
  return state;
};

export default profileReducer;
