import Button, {ButtonProps} from 'components/shared/Button';
import React, {useCallback, useContext} from 'react';
import {SchemaDataType, SchemaErrorType} from '@project-m/schemas/dist/types';

import BaseSchema from '@project-m/schemas';
import FormContext from 'contexts/FormContext';
import Schema from '@project-m/schemas';
import {clns} from 'helpers/strings';
import {noErrors} from '@project-m/schemas/dist/utils/schemas';
import styles from './Form.module.sass';
import useAlerts from 'hooks/useAlerts';

interface FormComponentsProps {
  className?: string;
}

interface FormProps<T = any> extends FormComponentsProps {
  schema: BaseSchema<T>;
  value: T;
  errors: SchemaErrorType;
  children: any;
  onChange: (data: SchemaDataType<T>, dataId: number) => void;
  onSubmit?: () => void;
  dataId?: number;
}

interface ConnectedFormProps
  extends Omit<FormProps, 'schema' | 'value' | 'errors' | 'onChange' | 'onSubmit'> {
  name: string;
}

interface FormColProps extends FormComponentsProps {
  width?: number;
}

function Form<T = any>({
  schema,
  value,
  errors,
  children,
  dataId = 0,
  onChange,
  onSubmit,
}: FormProps<T>) {
  const {fireError} = useAlerts();
  const handleChange = useCallback(
    (name: string, incomeValue: any) => {
      const updatedValue = {...value, [name]: incomeValue};
      onChange(schema.updateField({value: updatedValue, errors}, name), dataId);
    },
    [value, errors, onChange]
  );

  const handleUpdate = useCallback(
    (name: string, data: SchemaDataType<any>) => {
      onChange(
        {
          value: {...value, [name]: data.value},
          errors: {...errors, [name]: data.errors},
        },
        dataId
      );
    },
    [onChange, value, errors]
  );

  const handleSubmit = useCallback(() => {
    const newErrors = schema.validate(value);

    if (onSubmit && noErrors(newErrors)) {
      return onSubmit();
    }
    fireError('Перевірте правильність заповнення полів');
    onChange({value, errors: newErrors}, dataId);
  }, [onChange, value, fireError]);

  return (
    <FormContext.Provider
      value={{
        value,
        errors,
        onChange: handleChange,
        onUpdate: handleUpdate,
        onSubmit: handleSubmit,
        fields: schema.fields,
      }}
    >
      <div className={styles.form}>{children}</div>
    </FormContext.Provider>
  );
}

const FormCol: React.FC<FormColProps> = ({children, width}) => {
  return (
    <div
      className={clns([styles.col])}
      style={{...(width && {width: `${width}%`}), position: 'relative'}}
    >
      {children}
    </div>
  );
};

const FormRow: React.FC<FormComponentsProps> = ({children}) => {
  return <div className={clns([styles.row])}>{children}</div>;
};

const FormButton: React.FC<Omit<ButtonProps, 'onClick'>> = ({children, className, ...props}) => {
  const {onSubmit} = useContext(FormContext);
  return (
    <Button
      color={'blue'}
      filled
      {...props}
      className={clns([styles.button, className])}
      onClick={onSubmit}
    >
      {children || 'Зберегти'}
    </Button>
  );
};

const FormConnected: React.FC<ConnectedFormProps> = ({name, children}) => {
  const {value, errors, fields, onUpdate} = useContext(FormContext);

  const handleChange = useCallback(
    (data: SchemaDataType<any>) => {
      onUpdate(name, data);
    },
    [onUpdate, value, errors]
  );

  return (
    <Form
      schema={fields[name] as Schema}
      value={value[name]}
      errors={errors ? (errors![name] as SchemaErrorType) : null}
      onChange={handleChange}
    >
      {children}
    </Form>
  );
};

Form.Button = FormButton;
Form.Col = FormCol;
Form.Row = FormRow;
Form.Connected = FormConnected;

export default Form;
