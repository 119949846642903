import React from 'react';
import LoadingContext from '../../../../../contexts/LoadingContext';
import useLoadMore from '../../../../../hooks/useLoadMore';
import NewsListItemDto from '../../../../../infrastructure/models/news/NewsListItemDto';
import IProfileProvider, {
  SIProfileProvider,
} from '../../../../../infrastructure/providers/ProfileProvider/IProfileProvider';
import container from '../../../../../ioc';

import ListView from '../../../../shared/ListView';
import NewsItem from '../../../../shared/NewsItem';

import styles from './Liked.module.sass';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const Liked = () => {
  const { data: news, canLoadMore, loadMore, loading, empty } = useLoadMore<
    NewsListItemDto
  >(() => provider().getNews());

  return (
    <LoadingContext.Provider value={{ loading }}>
      <ListView
        className={styles.liked}
        canLoadMore={canLoadMore}
        onMore={loadMore}
        loading={loading}
        empty={empty}
      >
        {news.map(newsItem => (
          <NewsItem newsItem={newsItem} key={newsItem.id} />
        ))}
      </ListView>
    </LoadingContext.Provider>
  );
};

export default Liked;
