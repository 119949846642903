import React from 'react';
import styles from './CourseDocs.module.sass';

interface CourseSlidesProps {
  url: string;
}

const CourseDocs: React.FC<CourseSlidesProps> = ({url}) => {
  console.log(url)
  const splitted = url.split('/'); 
  const youtubeId = splitted[splitted.length - 1]

  if(url.includes('youtube')) return <div className={styles.wrapper}>
  <iframe
    //@ts-ignore
    src={url}
    frameBorder='0'
    width='960'
    height='569'
    allowFullScreen={true}
  />
  <iframe width="560" title='x' height="500px" src={`https://www.youtube.com/live_chat?v=${youtubeId}&embed_domain=${window.location.hostname}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
</div>

if(url.slice(-3) === 'doc' || url.slice(-3) === 'ptx' || url.slice(-4) === 'docx') return (
  <div className={styles.wrapper}>
    <iframe src={`https://docs.google.com/viewer?url=${url}&embedded=true`} 
      frameBorder='0'
      width='960'
      height='569'
    >
    </iframe>
  </div>
)

if(url.slice(-3) === 'pdf') return (
  <div className={styles.wrapper}>
    <iframe
    src={`http://docs.google.com/gview?url=${url}&embedded=true`}
    title='title'
    frameBorder='0'
    width='960'
    height='569'
    ></iframe>
  </div>
)

return <div className={styles.wrapper}>
  <iframe src={url} 
    frameBorder='0'
    width='960'
    height='569'
  >
  </iframe>
</div>
};

export default CourseDocs;

// return (
//   <div className={styles.wrapper}>
//     <iframe
//       //@ts-ignore
//       src={url}
//       frameBorder='0'
//       width='960'
//       height='569'
//       allowFullScreen={true}
//     />
//     {url.includes('youtube') &&
//     <iframe width="560" title='x' height="500px" src={`https://www.youtube.com/live_chat?v=${youtubeId}&embed_domain=${window.location.hostname}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
//     }

//   </div>
// );