import Id from '@project-m/core/entities/common/Id';
import CourseListItemDto from '../../models/courses/CourseListItemDto';
import EventListItemDto from '../../models/events/EventListItemDto';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import ProfileDto from '../../models/profile/ProfileDto';

// TODO: fill it and move somewhere
export interface SertificateDto {
  id: Id;
  [x: string]: any;
}

// TODO: same
export interface StandartDto {
  id: Id;
  [x: string]: any;
}

export interface UserSettingsDto {
  main: Record<string, any>;
}

export interface FileUploadDto {
  file: File;
}

export type AvatarUploadDto = FileUploadDto;

export interface AvatarResponseDto {
  avatarUrl: string;
}

// maybe theese two ^ must be list item dto's

export interface FileUploadResponseDto {
  id: Id;
  url: string;
}

export interface CreateCertificateDto {
  name: string;
  date: Date;
  points: number;
  certificate: Id;
}

export const SIProfileProvider = Symbol.for('IProfileProvider');

export default interface IProfileProvider {
  getUserInfo(): Promise<ProfileDto>;
  getCourses(): AsyncGenerator<CourseListItemDto[]>;
  getEvents(): AsyncGenerator<EventListItemDto[]>;
  getNews(): AsyncGenerator<NewsListItemDto[]>;
  getSertificates(): AsyncGenerator<SertificateDto[]>;
  getStandarts(): AsyncGenerator<StandartDto[]>;
  getSettings(): Promise<UserSettingsDto>;
  saveSettings(category: string, data: Record<string, any>): Promise<void>;
  uploadAvatar(data: AvatarUploadDto): Promise<AvatarResponseDto>;
  uploadCertificate(file: FileUploadDto): Promise<FileUploadResponseDto>;
  saveCertificate(data: CreateCertificateDto): Promise<void>;
}
