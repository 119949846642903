import { InitActionTypes } from './constants';
import { InitAction } from './types';

export interface InitState {
  done: boolean;
}

const initialState = {
  done: false,
};

const initReducer = (state: InitState = initialState, action: InitAction) => {
  if (action.type === InitActionTypes.DONE) {
    return {
      done: true,
    };
  }
  return state;
};

export default initReducer;
