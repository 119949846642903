import SeoContext from 'contexts/SeoContext';
import React, { useContext, useEffect, useState } from 'react';

import AskForm from 'components/shared/AskForm';
import ContentPage from 'components/shared/ContentPage';
import CourseItem from 'components/shared/CourseItem';
import MoreBlock from 'components/shared/MoreBlock';
import Question from 'components/shared/Question';
import useLastCourses from 'hooks/useLastCourses';
import FaqDto from 'infrastructure/models/faq/FaqDto';
import IFaqProvider, {
  SIFaqProvider,
} from 'infrastructure/providers/FaqProvider/IFaqProvider';
import container from 'ioc';

import styles from './FAQ.module.sass';

const provider = () => container.get<IFaqProvider>(SIFaqProvider);

const FAQ = () => {
  const [questions, setQuestions] = useState<FaqDto[]>([]);
  const courses = useLastCourses();
  const { setSeo } = useContext(SeoContext);

  useEffect(() => {
    setSeo({ title: 'FAQ' });
  }, []);

  useEffect(() => {
    provider()
      .getAll()
      .then(data => setQuestions(data));
  }, []);

  return (
    <ContentPage headline={'FAQ'}>
      <div className={styles.faq__questions}>
        {questions.map(q => (
          <Question question={q.question} answer={q.answer} key={q.id} />
        ))}
      </div>
      <AskForm />
      <MoreBlock headline={'Популярні курси'}>
        {courses.map(training => (
          <CourseItem training={training} key={training.id} />
        ))}
      </MoreBlock>
    </ContentPage>
  );
};

export default FAQ;
