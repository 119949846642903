import AuthActionTypes from './constants';

export const logIn = (email: string, password: string) => ({
  type: AuthActionTypes.LOG_IN,
  payload: {
    email,
    password,
  },
});

export const logInDone = () => ({
  type: AuthActionTypes.LOG_IN_DONE,
});

export const logOut = () => ({
  type: AuthActionTypes.LOG_OUT,
});
