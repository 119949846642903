import {
  CourseFilters,
  CourseType,
  LectionDto,
} from '../../models/courses/common';
import ICourseProvider, {
  AddResult,
  AddResultStatus,
  PreparedCoursesFilters,
} from './ICourseProvider';

import CourseListItemDto from '../../models/courses/CourseListItemDto';
import CoursePreviewDto from '../../models/courses/CoursePreviewDto';
import CourseTrainingDto from '../../models/courses/CourseTrainingDto';
import Id from '@project-m/core/entities/common/Id';
import ListResponse from 'infrastructure/models/common/ListResponse';
import author from 'assets/images/author.png';
import {injectable} from 'inversify';
import range from 'lodash/range';
import {sleep} from 'helpers/aio';
import training_image from 'assets/images/training_image.png';

const data: CourseListItemDto[] = range(10).map((id) => ({
  id: id.toString(),
  slug: 'some-training',
  title: 'ВТЭ: оценка риска в больнице и вне ее',
  description:
    'Учебный курс, посвященный критериям диагностики и профилактики венозной тромбемболии',
  image: training_image,
  author: {name: 'Joseph A. Caprini', image: author},
  duration: '40:00',
  language: 'Русский',
  points: '2',
  format: [CourseType.AUDIO],
}));

const trainingData = {
  id: '1',
  name: 'Міжнародні стандарти надання гінекологічної допомоги',

  lections: range(3).map((id) => ({
    id: id.toString(),
    title: `title ${id}`,
    duration: `11:0${id}`,
    type: CourseType.AUDIO,
    videoWatched: false,
    testComplete: false,
  })),
};

const currentLection: any = {
  id: '1',
  // contentUrl: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
  // contentUrl:
  //   'https://docs.google.com/document/d/e/2PACX-1vS71qH0oOIeoloQGO3zcglMk77fJP3OQQcMSks5iWOCo2rC7iBLyI7quUpZxzUu15r5AkGdyAkAvTSE/pub?embedded=true',
  // contentUrl:
  //   'https://docs.google.com/presentation/d/e/2PACX-1vTR3bRJG4as7GBvzb_F7ktVAbnwEykR1hCS7C4SMxe8ucJEryd7eRLdEkjSXWCzYxbWkV2GDq5WvVmp/embed?start=false&loop=false&delayms=3000',
  contentUrl:
    'https://interactive-examples.mdn.mozilla.net/media/examples/t-rex-roar.mp3',
  contentType: 'audio',
  name:
    'Контрольне тестування з модуля «Правові відносини у вітчизняному законодавстві»',
  videoWatched: false,
  testComplete: false,
  testDuration: 600000,
  retryCount: 3,
  lectionReward: 2,
  type: CourseType.AUDIO,
  questions: range(2).map((qid) => ({
    id: qid.toString(),
    text: `question ${qid + 1}`,
    complete: false,
    answers: range(3).map((aid) => ({
      id: aid.toString(),
      text: `answer ${qid + 1}-${aid + 1}`,
    })),
  })),
};

@injectable()
export default class MockCourseProvider implements ICourseProvider {
  public async answer(
    courseId: Id,
    lectionId: Id,
    questionId: Id,
    answerId: Id
  ): Promise<boolean> {
    await sleep(1000);
    return answerId !== '2';
  }

  public async getLection(hz: string, id: string): Promise<any> {
    return id === '0'
      ? currentLection
      : {
          ...currentLection,
          contentUrl: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
          contentType: 'video',
        };
  }

  public async getList(
    filters: PreparedCoursesFilters
  ): Promise<ListResponse<CourseListItemDto>> {
    return {list: data, pages: 3};
  }

  public async getPreview(slug: string): Promise<CoursePreviewDto> {
    return {author: {}} as CoursePreviewDto;
  }

  public async getTraining(slug: string): Promise<any> {
    return trainingData;
  }

  public async add(id: Id): Promise<AddResult> {
    return {status: AddResultStatus.SUCCESS};
  }

  public async testComplete(courseId: Id, lectionId: Id): Promise<void> {
    return;
  }

  public async videoWatched(courseId: Id, lectionId: Id): Promise<void> {
    return;
  }
}
