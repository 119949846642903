import 'moment/locale/ru';

import INotificationsProvider, {
  SINotificationsProvider,
} from '../../../../../infrastructure/providers/NotificationsProvider/INotificationsProvider';
import React, {useEffect, useRef, useState} from 'react';

import {NotificationDto} from '../../../../../infrastructure/models/notifications/NotificationDto';
import axios from 'axios';
import {clns} from '../../../../../helpers/strings';
import close_icon from '../../../../../assets/images/close_icon.png';
import container from '../../../../../ioc';
import moment from 'moment';
import {profileSelector} from 'store/modules/profile/selectors';
import send_icon from '../../../../../assets/images/send.png';
import styles from './Notifications.module.sass';
import {useSelector} from 'react-redux';

interface NotificationProps {
  className?: string;
  onClose?: () => void;
  onNew: () => void;
}

const provider = () =>
  container.get<INotificationsProvider>(SINotificationsProvider);

const AdminURL = 'http://cme-cpd.com.ua:1337/admin';

const Notifications: React.FC<NotificationProps> = ({
  className,
  onClose,
  onNew,
  //@ts-ignore
  isOpened,
}) => {
  const scrollable = useRef<null | HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [received, setReceived] = useState<NotificationDto | null>(null);
  const [msg, setMsg] = useState('');
  const [isStarted, setStarted] = useState(false);
  const [messagesList, setList] = useState([]);
  const [chats, setChats] = useState([]);
  const [isMsg, setIsMsg] = useState(true);

  const user = useSelector(profileSelector);

  useEffect(() => {
    const generator = provider().getAll();
    generator.next().then((data) => setNotifications(data.value));
  }, []);

  useEffect(() => {
    provider().on('new', (notification) => setReceived(notification));
  }, []);

  useEffect(() => {
    if (received) {
      onNew();
      setReceived(null);
      setNotifications([...notifications, received]);
    }
  }, [received, notifications]);

  useEffect(() => {
    const el = scrollable.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, []);

  const startChat = async () => {
    try {
      const res = await axios.post(`${AdminURL}/api/createChat`, {
        //@ts-ignore
        userId: user?.userId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getChatInfo() {
      try {
        const chats = await axios.get(`${AdminURL}/api/getChats`);
        setChats(chats.data.chats);
        // @ts-ignore
        if (!chats.data.chats.some((c: any) => c.userId === user?.userId)) {
          startChat();
        } else {
          if (user) {
            const messages = await axios.post(`${AdminURL}/api/getMsg`, {
              //@ts-ignore
              userId: user?.userId,
            });
            setList(messages.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    getChatInfo();
  }, [user]);

  useEffect(() => {
    isOpened ? setIsMsg(false) : console.log('removed');
  }, [isOpened]);

  const sentMsg = async () => {
    try {
      const res = await axios.post(`${AdminURL}/api/sendMsg`, {
        //@ts-ignore
        userId: user.userId,
        message: msg,
      });

      if (res.status === 200) {
        console.log('sent');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSeen = async (id: any, date: string) => {
    try {
      await axios.post(`${AdminURL}/api/setSeen`, {id, date});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {messagesList.some(
        (msg: any) => msg.seen === null && msg.userId === 9999
      ) &&
        isMsg && (
          <div
            style={{
              position: 'absolute',
              height: '10px',
              width: '10px',
              background: '#FC6668',
              borderRadius: '50%',
              top: '-3px',
              left: '10px',
              fontSize: '8px',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {/* @ts-ignore */}
            {messagesList.filter((msg) => msg.seen === null).length}
          </div>
        )}

      <div className={clns([styles.notifications, className])}>
        <div className={styles.notifications__top}>
          <div className={styles.notifications__headline}>Повідомлення</div>
          {onClose && (
            <img
              onClick={onClose}
              src={close_icon}
              alt={''}
              className={styles.notifications__close}
            />
          )}
        </div>
        <div className={styles.notifications__body} ref={scrollable}>
          <div className={styles.notifications__scrollable}>
            {messagesList.map((msg: any) => {
              isOpened &&
                msg.seen === null &&
                updateSeen(msg.id, moment(Date.now()).format('LLL'));

              return (
                <div
                  key={msg.id}
                  style={{
                    background: '#fff',
                    //@ts-ignore
                    textAlign: msg.userId !== 9999 ? 'right' : 'left',
                    marginTop: '10px',
                    borderRadius: '5px',
                    padding: '5px',
                  }}
                >
                  {msg.userId !== 9999 ? (
                    <h4
                      style={{
                        marginBottom: '3px',
                        fontWeight: 400,
                        fontSize: '20px',
                      }}
                    >
                      Ви
                    </h4>
                  ) : (
                    <h4
                      style={{
                        marginBottom: '3px',
                        fontWeight: 400,
                        fontSize: '20px',
                      }}
                    >
                      Адмін
                    </h4>
                  )}
                  {msg.message}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={styles.notifications__bottom}
          style={{padding: '0 10px'}}
        >
          <input
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            type='text'
            style={{
              fontSize: '18px',
              borderRadius: '5px',
              borderColor: '#292D3E',
              height: '60%',
              width: '300px',
            }}
          />
          <button
            onClick={() => {
              //@ts-ignore
              setList([...messagesList, {userId: user.userId, message: msg}]);
              sentMsg();
              setMsg('');
            }}
            style={{
              border: 'none',
              height: '40px',
              width: '40px',
              background: '#1E78ED',
              cursor: 'pointer',
              borderRadius: '5px',
            }}
          >
            <img src={send_icon} alt='send_icon' />
          </button>
        </div>
      </div>
    </>
  );
};

export default Notifications;
