import React from 'react';

import styles from './InfoBlock.module.sass';

interface InfoBlockProps {
  headline: string;
  list: string[][];
}

const InfoBlock: React.FC<InfoBlockProps> = ({ headline, list }) => {
  return (
    <div className={styles.infoBlock}>
      <div className={styles.infoBlock__headline}>{headline}</div>
      <div className={styles.infoBlock__separator} />
      <div className={styles.infoBlock__content}>
        {list.map(([label, value]) => (
          <div className={styles.infoBlock__line} key={label}>
            <div className={styles.infoBlock__label}>{label}</div>
            <div className={styles.infoBlock__value}>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBlock;
