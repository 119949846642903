import CourseListItemDto from '../../../infrastructure/models/courses/CourseListItemDto';
import ListItem from '../ListItem';
import React from 'react';
import audio from '../../../assets/images/audio.png';
import {clns} from '../../../helpers/strings';
import docs from '../../../assets/images/docs.png';
import slides from '../../../assets/images/slideshow.png';
import styles from './TrainingItem.module.sass';
import video from '../../../assets/images/video.png';

const types: Record<string, string> = {
  audio,
  video,
  slides,
  docs,
};

const langs: Record<string, string> = {
  ua: 'Українська',
  ru: 'Російська',
  en: 'Англійська',
};

interface TrainingItemProps {
  training: CourseListItemDto;
  className?: string;
}

const CourseItem: React.FC<TrainingItemProps> = ({training, className}) => {
  const {
    slug,
    title,
    description,
    image,
    duration,
    language,
    points,
    format,
    author,
  } = training;

  return (
    <ListItem
      link={`/courses/${slug}`}
      className={clns([styles.trainingItem, className])}
    >
      <div className={styles.trainingItem__top}>
        <img src={image} alt={title} className={styles.trainingItem__image} />
      </div>
      <div className={styles.trainingItem__content}>
        <div className={styles.trainingItem__headline}>{title}</div>
        <div className={styles.trainingItem__text}>{description}</div>
        <div className={styles.trainingItem__author}>
          <img
            src={author.image}
            alt={author.name}
            className={styles.trainingItem__author_pic}
          />
          <div className={styles.trainingItem__author_name}>
            Автор: {author.name}
          </div>
        </div>
        <div className={styles.trainingItem__separator} />
        <div className={styles.trainingItem__bottom}>
          <div className={styles.trainingItem__column}>
            <div className={styles.trainingItem__label}>Час</div>
            <div className={styles.trainingItem__value}>{duration} хв</div>
          </div>
          <div className={styles.trainingItem__column}>
            <div className={styles.trainingItem__label}>Мова</div>
            <div className={styles.trainingItem__value}>{langs[language]}</div>
          </div>
          <div className={styles.trainingItem__column}>
            <div className={styles.trainingItem__label}>Бали</div>
            <div className={styles.trainingItem__value}>{points}</div>
          </div>
        </div>
        <div className={styles.trainingItem__separator} />
        <div className={styles.trainingItem__type}>
          <div className={styles.trainingItem__type_label}>Формат</div>
          <div className={styles.trainingItem__type_value}>
            {format
              .filter((value, i, arr) => arr.indexOf(value) === i)
              .map((type) => (
                <img
                  key={type}
                  src={types[type]}
                  alt={'type'}
                  className={styles.trainingItem__type_icon}
                />
              ))}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default CourseItem;
