import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';

const AddCertificateSchema = Schema.create({
  name: Fields.any().required(),
  date: Fields.date().required(),
  points: Fields.number().required(),
  certificate: Fields.files(false, 'application/pdf,image/*').required(),
});

export default AddCertificateSchema;
