import { injectable } from 'inversify';
import IInitializationProvider from './IInitializationProvider';

@injectable()
export default class MockInitializationProvider
  implements IInitializationProvider {
  public init(): Promise<void> {
    return new Promise(r => setTimeout(r, 100));
  }
}
