import { getLocation, replace } from 'connected-react-router';
import { makeSearch, parseSearch } from 'helpers/location';
import { Location } from 'history';
import omit from 'lodash/omit';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { coursesActions, coursesSelectors } from 'store/modules/courses';
import { eventsActions, eventsSelectors } from 'store/modules/events';
import { newsActions, newsSelectors } from 'store/modules/news';
import { AppState } from 'store/reducer';
import { ListActions, ListSelectors } from 'store/types';

export enum ListModules {
  NEWS = 'news',
  EVENTS = 'events',
  COURSES = 'courses',
}

const listsActions: Record<ListModules, ListActions<any>> = {
  [ListModules.NEWS]: newsActions,
  [ListModules.EVENTS]: eventsActions,
  [ListModules.COURSES]: coursesActions,
};

const listsSelectors: Record<ListModules, ListSelectors<any>> = {
  [ListModules.NEWS]: newsSelectors,
  [ListModules.EVENTS]: eventsSelectors,
  [ListModules.COURSES]: coursesSelectors,
};

export default function useList(module: ListModules) {
  const dispatch = useDispatch();

  const actions = listsActions[module];
  const selectors = listsSelectors[module];

  const { search } = useSelector<AppState, Location>(getLocation);
  const parsedSearch = useMemo(() => parseSearch(search), [search]);
  const list = useSelector(selectors.getList);
  const page = useSelector(selectors.getPage);
  const pages = useSelector(selectors.getPages);
  const loading = useSelector(selectors.getLoading);
  const filters = useSelector(selectors.getFilters);

  const goToPage = useCallback(
    (newPage: number) => {
      dispatch(actions.setPage(newPage));
      dispatch(actions.getList());
      dispatch(
        replace({
          search: makeSearch(
            newPage === 1
              ? omit(parsedSearch, 'page')
              : { ...parsedSearch, page: newPage }
          ),
        })
      );
    },
    [parsedSearch]
  );

  const setFilters = useCallback(
    (newFilters: Record<string, any>) => {
      dispatch(actions.setFilters(newFilters));
      dispatch(actions.getList());
      dispatch(
        replace({
          search: makeSearch({
            page: page === 1 ? undefined : page,
            ...newFilters,
          }),
        })
      );
    },
    [parsedSearch, page]
  );

  useEffect(() => {
    const { page: urlPage = 1, ...urlFilters } = parsedSearch;

    dispatch(actions.setFilters(urlFilters));

    if (urlPage !== page) {
      dispatch(actions.setPage(Number(urlPage)));
    }

    dispatch(actions.getList());
  }, []);

  return { list, page, pages, loading, filters, goToPage, setFilters };
}
