import {
  academicStatus,
  countries,
  degreeSpecialization,
  educationType,
  jobSpecializations,
  languageLevel,
  scienceDegree,
  studiedLanguages,
  universities,
  year,
} from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const UniversitiesSchema = Schema.create({
  country: Fields.choice(dataJSON.countries || countries).required(),
  university: Fields.choice(dataJSON.universities || universities).required(),
  specialization: Fields.choice(dataJSON.jobSpecializations || jobSpecializations).required(),
  docSpecialization: Fields.choice(dataJSON.degreeSpecialization || degreeSpecialization).required(),
  diploma: Fields.any(),
  startYear: Fields.choice(year()).required(),
  endYear: Fields.choice(year(true)).required(),
  educationType: Fields.choice(dataJSON.educationType || educationType).required(),
});

const ScienceDegreeSchema = Schema.create({
  degree: Fields.choice(dataJSON.scienceDegree || scienceDegree).required(),
  protectionDate: Fields.date().required(),
  protectionPlace: Fields.choice(dataJSON.countries || countries).required(),
  dateOfIssue: Fields.date().required(),
  thesisTopic: Fields.text().required(),
  diploma: Fields.any().required(),
  specialization: Fields.choice(dataJSON.degreeSpecialization || degreeSpecialization).required(),
});

const AcademicStatusSchema = Schema.create({
  status: Fields.choice(dataJSON.academicStatus || academicStatus).required(),
});

const ForeignLanguagesSchema = Schema.create({
  language: Fields.choice(dataJSON.studiedLanguages || studiedLanguages).required(),
  level: Fields.choice(dataJSON.languageLevel || languageLevel).required(),
});

export const HigherEducationSchema = Schema.create({
  universities: Fields.array(UniversitiesSchema),
  scienceDegree: Fields.array(ScienceDegreeSchema),
  academicStatus: Fields.array(AcademicStatusSchema),
  languages: Fields.array(ForeignLanguagesSchema),
});
