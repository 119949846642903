import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';
import { CourseFilters } from 'infrastructure/models/courses/common';
import IOptionsProvider, {
  SIOptionsProvider,
} from 'infrastructure/providers/OptionsProvider/IOptionsProvider';
import container from 'ioc';

const provider = () => container.get<IOptionsProvider>(SIOptionsProvider);

const CoursesFiltersSchema = Schema.create<CourseFilters>({
  occupation: Fields.choice([], () => provider().getSpecialities()),
  type: Fields.choice([
    ['audio', 'Аудіо'],
    ['video', 'Відео'],
    ['slides', 'Презентація'],
  ]),
});

export default CoursesFiltersSchema;
