import ICourseProvider, {
  SICourseProvider,
} from 'infrastructure/providers/CourseProvider/ICourseProvider';
import container from 'ioc';
import createListModule from 'store/helpers/createListModule';

const provider = () => container.get<ICourseProvider>(SICourseProvider);

const {
  actionTypes: coursesActionTypes,
  actions: coursesActions,
  reducer: coursesReducer,
  handlers: coursesHandlers,
  selectors: coursesSelectors,
} = createListModule('courses', provider);

export {
  coursesActionTypes,
  coursesActions,
  coursesReducer,
  coursesHandlers,
  coursesSelectors,
};
