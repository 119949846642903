import { AfterMiddlewareCallback } from 'store/types';

const locationChangeHandler: AfterMiddlewareCallback = () => {
  window.scrollTo({ left: 0, top: 0 });
};

const routerHandlers = {
  '@@router/LOCATION_CHANGE': locationChangeHandler,
};

export default routerHandlers;
