import React from 'react';
import { Link } from 'react-router-dom';

import { clns } from '../../../../../helpers/strings';

import search_icon from '../../../../../assets/images/search_icon.png';
import styles from './Search.module.sass';

interface SearchProps {
  className?: string;
}

const Search: React.FC<SearchProps> = ({ className }) => {
  return (
    <Link to={'/search'} className={clns([styles.search, className])}>
      <img
        alt={'search icon'}
        src={search_icon}
        className={styles.search__icon}
      />
    </Link>
  );
};

export default Search;
