import IProfileProvider, {
  SIProfileProvider,
  SertificateDto,
} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from 'components/shared/Button';
import Certificate from './Certificate';
import CustomCertificate from './CustomCertificate';
import RoundButton from 'components/shared/RoundButton';
import axios from 'axios';
import container from 'ioc';
import {profileSelector} from 'store/modules/profile/selectors';
import {setModal} from 'store/modules/ui/actions';
import styles from './Certificates.module.sass';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const Certificates = () => {
  const user = useSelector(profileSelector);
  const [certificates, setCertificates] = useState<SertificateDto[]>([]);
  const [custom, setCustom] = useState([]);
  const dispatch = useDispatch();

  console.log(user);

  const addCertificate = useCallback(() => {
    dispatch(setModal('addCertificate', true));
  }, []);

  useEffect(() => {
    provider()
      .getSertificates()
      .next()
      .then((x) => setCertificates(x.value || []));
  }, []);

  useEffect(() => {
    const getCertificates = async () => {
      try {
        const res = await axios.get(
          `http://cme-cpd.com.ua:1337/admin/api/getCustomCert`
        );
        setCustom(
          res.data.filter((cert: any) => {
            console.log(cert);
            const id = cert.link.slice(0, 2);
            //@ts-ignore
            if (id == user.userId) return cert;
          })
        );
      } catch (error) {
        console.log(error, 'certificates');
      }
    };
    if (user !== undefined) {
      getCertificates();
    }
  }, [user]);

  return (
    <div className={styles.certificates}>
      <Button filled className={styles.button} onClick={addCertificate}>
        Додати сертифікат
      </Button>
      {certificates.map((certificate) => (
        <Certificate certificate={certificate} key={certificate.id} />
      ))}
      {custom.map((cert: string, i: number) => (
        //@ts-ignore
        <CustomCertificate key={i} cert={cert}></CustomCertificate>
      ))}
    </div>
  );
};

export default Certificates;
