import ICourseProvider, {
  SICourseProvider,
} from 'infrastructure/providers/CourseProvider/ICourseProvider';
import {useCallback, useEffect, useReducer} from 'react';

import CourseTrainingDto from 'infrastructure/models/courses/CourseTrainingDto';
import {Id} from '@project-m/core';
import {LectionDto} from 'infrastructure/models/courses/common';
import {PayloadAction} from 'store/types';
import container from 'ioc';
import findIndex from 'lodash/findIndex';
import {isAuthorized} from 'store/modules/auth/selectors';
import {useSelector} from 'react-redux';

enum ActionTypes {
  SET_COURSE = 'Set course',
  SET_CURRENT_LECTION = 'Set current lection',
  SET_COURSE_LOADING = 'Set course loading',
  SET_LECTION_LOADING = 'Set lection loading',
  SET_CONTENT_WATCHED = 'Set watched',
  SET_TEST_PASSED = 'Set test passed',
  SET_STEP = 'Set step',
  SET_FINISHED = 'Set finished',
}

export enum StepTypes {
  WATCHING,
  TESTING,
}

const setCourse = (payload: CourseTrainingDto) => ({
  type: ActionTypes.SET_COURSE,
  payload,
});

const setCurrentLection = (payload: any) => ({
  type: ActionTypes.SET_CURRENT_LECTION,
  payload,
});

const setCourseLoading = (payload: boolean) => ({
  type: ActionTypes.SET_COURSE_LOADING,
  payload,
});

const setLectionLoading = (payload: boolean) => ({
  type: ActionTypes.SET_LECTION_LOADING,
  payload,
});

const setContentWatched = (payload: Id) => ({
  type: ActionTypes.SET_CONTENT_WATCHED,
  payload,
});

const setTestPassed = (payload: Id) => ({
  type: ActionTypes.SET_TEST_PASSED,
  payload,
});

const setStep = (payload: StepTypes) => ({
  type: ActionTypes.SET_STEP,
  payload,
});

const setFinished = (payload: boolean) => ({
  type: ActionTypes.SET_FINISHED,
  payload,
});

interface CourseState {
  course: CourseTrainingDto | null;
  currentLection: any | null;
  courseLoading: boolean;
  lectionLoading: boolean;
  finished: boolean;
  step: StepTypes;
  last: boolean;
}

const initialState: CourseState = {
  course: null,
  currentLection: null,
  courseLoading: false,
  lectionLoading: false,
  step: StepTypes.WATCHING,
  finished: false,
  last: false,
};

function reducer(
  state: CourseState = initialState,
  action: PayloadAction
): CourseState {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.SET_COURSE:
      return {...state, course: payload, courseLoading: false};
    case ActionTypes.SET_CURRENT_LECTION:
      return {
        ...state,
        currentLection: payload,
        lectionLoading: false,
        last:
          findIndex(state.course!.lections, ({id}) => id === payload.id) ===
          state.course!.lections.length - 1,
      };
    case ActionTypes.SET_COURSE_LOADING:
      return {...state, courseLoading: payload};
    case ActionTypes.SET_LECTION_LOADING:
      return {...state, lectionLoading: payload};
    case ActionTypes.SET_CONTENT_WATCHED:
      return {
        ...state,
        course: {
          ...state.course!,
          lections: state.course!.lections.map((lection) =>
            lection.id === payload ? {...lection, videoWatched: true} : lection
          ),
        },
        currentLection: {...state.currentLection!, videoWatched: true},
      };
    case ActionTypes.SET_TEST_PASSED:
      return {
        ...state,
        course: {
          ...state.course!,
          lections: state.course!.lections.map((lection) =>
            lection.id === payload ? {...lection, testComplete: true} : lection
          ),
        },
        currentLection: {...state.currentLection!, testComplete: true},
      };
    case ActionTypes.SET_STEP:
      return {...state, step: payload};
    case ActionTypes.SET_FINISHED:
      return {...state, finished: payload};
  }

  return state;
}

const provider = () => container.get<ICourseProvider>(SICourseProvider);

export default function useCourse(slug: string) {
  const authorized = useSelector(isAuthorized);
  const [
    {course, currentLection, lectionLoading, step, finished, last},
    dispatch,
  ] = useReducer(reducer, initialState);

  const setLection = useCallback(
    (id: Id) => {
      if (course) {
        const newLection = course?.lections.find(
          ({id: lectionId}) => lectionId === id
        );

        if (newLection) {
          if (
            currentLection &&
            newLection &&
            currentLection.id === newLection.id
          ) {
            return;
          }

          dispatch(setLectionLoading(true));

          provider()
            .getLection(course.id, newLection.id)
            .then((response) => {
              dispatch(setCurrentLection(response));
            });
        }
      }
    },
    [course, currentLection]
  );

  const handleNext = useCallback(() => {
    if (course && currentLection) {
      const currentLectionIndex = findIndex(
        course.lections,
        ({id}) => id === currentLection.id
      );

      if (currentLectionIndex === course.lections.length - 1) {
        dispatch(setFinished(true));
      } else {
        setLection(course.lections[currentLectionIndex + 1].id);
      }
    }
  }, [course, currentLection, setLection]);

  const handleContentWatched = useCallback(() => {
    if (course && currentLection) {
      provider()
        .videoWatched(course.id, currentLection.id)
        .then(() => dispatch(setContentWatched(currentLection.id)));
    }
  }, [course, currentLection]);

  const handleTestPassed = useCallback(() => {
    if (course && currentLection) {
      provider()
        .testComplete(course.id, currentLection.id)
        .then(() => dispatch(setTestPassed(currentLection.id)));
    }
  }, [course, currentLection]);

  const handleStepChange = useCallback((nextStep: StepTypes) => {
    dispatch(setStep(nextStep));
  }, []);

  useEffect(() => {
    if (authorized) {
      provider()
        .getTraining(slug)
        .then((response) => {
          dispatch(setCourse(response));
        });
    }
  }, [authorized, slug]);

  useEffect(() => {
    if (
      course &&
      course.lections.length &&
      !currentLection &&
      !lectionLoading
    ) {
      setLection(course.lections[0].id);
    }
  }, [course, currentLection, lectionLoading, setLection]);

  return {
    course,
    currentLection,
    setLection,
    handleContentWatched,
    handleTestPassed,
    handleStepChange,
    step,
    handleNext,
    finished,
    last,
  };
}
