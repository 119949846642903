import '../assets/styles/reset.sass';
import 'moment/locale/ru';

import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router';

import Footer from './base/Footer';
import Header from './base/Header';
import Loader from 'components/base/Loader';
import MainSettingsSchema from '../schemas/MainSettingsSchema';
import Modals from './base/Modals';
import NotFound from './errors/NotFound';
import ROUTES from '../routes';
import axios from 'axios';
//@ts-ignore
import io from 'socket.io-client';
import moment from 'moment';
import {profileSelector} from 'store/modules/profile/selectors';
import {useSelector} from 'react-redux';
import useUserSettings from '../hooks/useUserSettings';
import {v4} from 'uuid';

const socket = io('http://cme-cpd.com.ua:1337');

const sendBdMail = async (id: string) => {
  try {
    const res = await axios.post(
      'http://cme-cpd.com.ua:1337/admin/api/sendBdTemplate',
      {id}
    );
    //@ts-ignore
  } catch (error) {
    console.log(error);
  }
};

const App = () => {
  const user = useSelector(profileSelector);
  //@ts-ignore
  const [isSend] = useState(JSON.parse(localStorage.getItem('isExpired')));

  const {value} = useUserSettings('main', MainSettingsSchema);
  useEffect(() => {
    //@ts-ignore
    localStorage.setItem('userId', JSON.stringify(user?.userId));
    //@ts-ignore
    const birthDay = moment(value?.dateOfBirth).format('MMM Do');
    const today = moment(Date.now()).format('MMM Do');

    //@ts-ignore
    if (birthDay === today) {
      if (!isSend) {
        if (user !== undefined) {
          //@ts-ignore
          localStorage.setItem('isExpired', JSON.stringify('true'));
          //@ts-ignore
          sendBdMail(user?.userId);
        }
      }
    }

    //@ts-ignore
    // if (birthDay !== today) localStorage.removeItem('isExpired');
  }, [value, user, isSend]);

  useEffect(() => {
    socket.emit('newUser', {userId: v4()});
  }, []);

  return (
    <>
      <Header />
      <Switch>
        {ROUTES.map((route) => (
          <Route
            path={route.url}
            exact={route.exact}
            component={route.component}
            key={route.url}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
      <Footer />
      <Modals />
      <Loader />
    </>
  );
};

export default App;
