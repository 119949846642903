import { injectable } from 'inversify';
import FaqDto from '../../models/faq/FaqDto';
import Feedback from '../../models/faq/Feedback';
import IFaqProvider from './IFaqProvider';

@injectable()
export default class MockFaqProvider implements IFaqProvider {
  public async makeFeedback(feedback: Feedback): Promise<void> {
    return;
  }
  public async getAll(): Promise<FaqDto[]> {
    return [];
  }
}
