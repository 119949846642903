import React, { useCallback, useContext, useEffect, useState } from 'react';

import AskForm from 'components/shared/AskForm';
import ContentPage from 'components/shared/ContentPage';
import IconsRow from 'components/shared/IconsRow';
import MoreBlock from 'components/shared/MoreBlock';
import NewsItem from 'components/shared/NewsItem';
import SeoContext from 'contexts/SeoContext';
import { dropEmpty } from 'helpers/arrays';
import useLastNews from 'hooks/useLastNews';
import IPageDataProvider, {
  SIPageDataProvider,
} from 'infrastructure/providers/PageDataProvider/IPageDataProvider';
import container from 'ioc';

import envelope from 'assets/images/envelope.png';
import phone from 'assets/images/phone.png';
import where from 'assets/images/where.png';

const Contacts: React.FC = () => {
  const [data, setData] = useState<Record<string, any>>({});
  const { setSeo } = useContext(SeoContext);
  const provider = useCallback(
    () => container.get<IPageDataProvider>(SIPageDataProvider),
    []
  );
  const news = useLastNews();

  useEffect(() => {
    provider()
      .get('/contacts')
      .then(response => {
        setSeo({ title: 'Контакти' });
        setData(response.blocks[0]);
      })
      // tslint:disable-next-line:no-console
      .catch(console.log);
  }, []);

  return (
    <ContentPage headline={'Контакти'}>
      <IconsRow
        items={dropEmpty([
          data.address && { icon: where, text: data.address },
          data.phone && { icon: phone, text: data.phone },
          data.email && { icon: envelope, text: data.email },
        ])}
        noBg
      />
      <AskForm />
      <MoreBlock headline={'Останні новини'}>
        {news.map(item => (
          <NewsItem newsItem={item} key={item.id} />
        ))}
      </MoreBlock>
    </ContentPage>
  );
};

export default Contacts;
