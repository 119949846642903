import noop from 'lodash/noop';
import React from 'react';

import { clns } from 'helpers/strings';

import styles from './RoundButton.module.sass';

interface RoundButtonProps {
  icon: string;
  onClick?: () => void;
  className?: string;
}

const RoundButton: React.FC<RoundButtonProps> = ({
  icon,
  onClick,
  className,
}) => {
  return (
    <div
      className={clns([styles.roundButton, className])}
      onClick={onClick || noop}
    >
      <img src={icon} alt={''} />
    </div>
  );
};

export default RoundButton;
