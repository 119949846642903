import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../store/modules/ui/actions';
import Error from '../../shared/Error';

const NotAuthorized = () => {
  const dispatch = useDispatch();

  return (
    <Error
      headline={':('}
      text={'Для перегляду цієї сторінки вам необхідно увійти'}
      buttons={[
        {
          label: 'Увійти',
          onClick: () => dispatch(setModal('login', true)),
          filled: true,
          color: 'lightBlue',
        },
      ]}
    />
  );
};

export default NotAuthorized;
