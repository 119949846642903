import {
  MISTypes,
  city,
  district,
  jobPositions,
  jobSpecializations,
  qualificationCategories,
  region,
  year
} from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const PlacesOfWorkSchema = Schema.create({
  name: Fields.any().required(),
  region: Fields.choice(dataJSON.region || region).required(),
  district: Fields.choice(dataJSON.district || district).required(),
  city: Fields.choice(dataJSON.city || city).required(),
  street: Fields.any(),
  building: Fields.any(),
  position: Fields.choice(dataJSON.jobPositions || jobPositions).required(),
  specialization: Fields.choice(dataJSON.jobSpecializations || jobSpecializations).required(),
  startYear: Fields.choice(year()).required(),
  endYear: Fields.choice(year(true)).required(),
  mainJob: Fields.bool(),
});

const MISUsageSchema = Schema.create({
  name: Fields.choice(dataJSON.MISTypes || MISTypes).required(),
});

const FreelanceSchema = Schema.create({
  specialization: Fields.choice(dataJSON.jobSpecializations).required(),
  startDate: Fields.date().required(),
  endDate: Fields.date(),
  dueNow: Fields.bool(),
});

const ProfessionalActivitySchema = Schema.create({
  startYear: Fields.choice(year()).required(),
  qualificationCategory: Fields.choice(dataJSON.qualificationCategories || qualificationCategories).required(),
  placesOfWork: Fields.array(PlacesOfWorkSchema),
  misUsage: Fields.array(MISUsageSchema),
  freelance: Fields.array(FreelanceSchema),
});

export default ProfessionalActivitySchema;
