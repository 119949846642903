import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../../store/modules/ui/actions';

import Button from '../../../../shared/Button';

import styles from './ActionsBlock.module.sass';

interface ActionBlockProps {
  text: string;
  buttonText: string;
}

const ActionsBlock = React.memo<ActionBlockProps>(({ text, buttonText }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.actionsBlock}>
      <div className={styles.actionsBlock__column}>
        <div className={styles.actionsBlock__text}>{text}</div>
        <Button
          color={'red'}
          filled
          className={styles.actionsBlock__button}
          onClick={() => dispatch(setModal('signUp', true))}
        >
          {buttonText}
        </Button>
      </div>
      {/*<div className={styles.actionsBlock__column}>*/}
      {/*  <div className={styles.actionsBlock__text}>*/}
      {/*    Если Вы хотите качественно, быстро и удобно проходить обучение в*/}
      {/*    формате СМЕ/CPD*/}
      {/*  </div>*/}
      {/*  <Button*/}
      {/*    color={'red'}*/}
      {/*    withBorders*/}
      {/*    className={styles.actionsBlock__button}*/}
      {/*  >*/}
      {/*    хочу стать лектором*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
});

export default ActionsBlock;
