import { clns } from 'helpers/strings';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLoader } from 'store/modules/ui/selectors';

import styles from './Loader.module.sass';

const Loader: React.FC = () => {
  const visible = useSelector(getLoader('global'));

  return (
    <div className={clns([styles.loader, visible && styles.loader_visible])} />
  );
};

export default Loader;
