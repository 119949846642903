import Form from 'components/forms/Form';
import DateInput from 'components/forms/Widgets/DateInput';
import Input from 'components/forms/Widgets/Input';
import List from 'components/forms/Widgets/List';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';
import React from 'react';
import EducationalActivitiesSchema from 'schemas/EducationalActivitiesSchema';

const EducationActivitiesSettings = () => {
  const { value, errors, onSave, onChange } = useUserSettings(
    'teaching',
    EducationalActivitiesSchema
  );

  return (
    <Form
      schema={EducationalActivitiesSchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >
      <List.Connected
        name={'scientificPedagogicalActivity'}
        label={'Науково-педагогічна діяльність'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'name'}
              label={'Назва навчального закладу'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'chair'} label={'Назва кафедри'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'position'} label={'Посада'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'startYear'} label={'Рік початку'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'endYear'} label={'Рік закінчення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'conductedLecturesForMedics'}
        label={'Проведення лекцій для лікарів та медсестер'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'name'} label={'Назва лекції'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'place'} label={'Місце проведення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'hours'} label={'Кількість годин'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'date'} label={'Дата проведення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'conductedLecturesForPeople'}
        label={'Проведення лекцій для громадян'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'name'} label={'Назва лекції'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'place'} label={'Місце проведення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'hours'} label={'Кількість годин'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'date'} label={'Дата проведення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'publications'}
        label={'Виступ та публікація в засобах масової інформації'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'name'}
              label={'Назва виступу або публікації'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <DateInput.Connected name={'date'} label={'Дата проведення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'leadership'}
        label={'Керівництво групою інтернів на базі стажування'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'place'}
              label={'Місце проведення стажування'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected
              name={'peopleCount'}
              label={'Кількість інтернів'}
            />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'hours'} label={'Кількість годин'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата закінчення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <Form.Button />
    </Form>
  );
};

export default EducationActivitiesSettings;
