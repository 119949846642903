import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, Dispatch, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authHandlers from 'store/modules/auth/middleware';
import { coursesHandlers } from 'store/modules/courses';
import { eventsHandlers } from 'store/modules/events';
import initHandlers from 'store/modules/init/middleware';
import { newsHandlers } from 'store/modules/news';
import profileHandlers from 'store/modules/profile/middleware';
import routerHandlers from 'store/modules/router/middleware';
import signUpHandlers from 'store/modules/signUp/middleware';
import { uiHandlers } from 'store/modules/ui';
import { userHandlers } from 'store/modules/user';
import {
  AfterMiddlewareCallback,
  InjectMiddlewareCallback,
  PayloadAction,
  StoreApi,
} from 'store/types';

const afterHandlers: Record<string, AfterMiddlewareCallback> = {
  ...initHandlers,
  ...authHandlers,
  ...signUpHandlers,
  ...routerHandlers,
  ...profileHandlers,
  ...newsHandlers,
  ...eventsHandlers,
  ...coursesHandlers,
  ...userHandlers,
};

const injectHandlers: Record<string, InjectMiddlewareCallback> = {
  ...uiHandlers,
};

function afterMiddleware(store: StoreApi) {
  return (next: Dispatch) => (action: PayloadAction) => {
    next(action);

    if (action.type in afterHandlers) {
      afterHandlers[action.type](store, action);
    }
  };
}

function injectMiddleware(store: StoreApi) {
  return (next: Dispatch) => (action: PayloadAction) => {
    if (action.type in injectHandlers) {
      injectHandlers[action.type](store, action, next);
    } else {
      next(action);
    }
  };
}

export default function createMiddleware(history: History): StoreEnhancer {
  const middleware = applyMiddleware(
    routerMiddleware(history),
    afterMiddleware,
    injectMiddleware
  );

  return process.env.NODE_ENV === 'production'
    ? compose(middleware)
    : composeWithDevTools(middleware);
}
