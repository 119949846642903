import Id from '@project-m/core/entities/common/Id';
import React, { useCallback, useState } from 'react';

import { clns } from 'helpers/strings';
import { LectionListItemDto } from 'infrastructure/models/courses/common';

import chevron from 'assets/images/button.svg';
import disabled_icon from 'assets/images/disabled.svg';
import done from 'assets/images/done.svg';
import play from 'assets/images/play-button.svg';
import styles from './CourseLectionItem.module.sass';

interface CourseLectionListItemProps extends LectionListItemDto {
  disabled: boolean;
  active: boolean;
  onChoose: (id: Id) => void;
}

const CourseLectionListItem: React.FC<CourseLectionListItemProps> = ({
  title,
  videoWatched,
  id,
  testComplete,
  disabled,
  onChoose,
  active,
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const handleChoose = useCallback(() => !disabled && onChoose(id), [
    onChoose,
    id,
    disabled,
  ]);

  return (
    <div
      className={clns([
        styles.wrapper,
        opened && styles.wrapperOpened,
        disabled && styles.wrapperDisabled,
        active && styles.active,
      ])}
    >
      <div className={styles.top}>
        <img
          className={styles.icon}
          src={disabled ? disabled_icon : play}
          alt={'icon'}
          onClick={handleChoose}
        />
        <div className={styles.name} onClick={handleChoose}>
          {title}
        </div>
        <div className={styles.done}>
          {testComplete && videoWatched && (
            <img className={styles.doneIcon} alt={''} src={done} />
          )}
        </div>
        <img
          className={styles.open}
          alt={'open'}
          src={chevron}
          onClick={() => !disabled && setOpened(!opened)}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.label}>Перегляд лекції</div>
          <div className={styles.done}>
            {videoWatched && (
              <img className={styles.doneIcon} alt={''} src={done} />
            )}
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.label}>Тестування</div>
          <div className={styles.done}>
            {testComplete && (
              <img className={styles.doneIcon} alt={''} src={done} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLectionListItem;
