import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import CourseListItemDto from '../../models/courses/CourseListItemDto';
import EventListItemDto from '../../models/events/EventListItemDto';
import NewsListItemDto from '../../models/news/NewsListItemDto';
import ProfileDto from '../../models/profile/ProfileDto';
import { serializeListItem } from '../NewsProvider/ApiNewsProvider';
import IProfileProvider, {
  AvatarResponseDto,
  AvatarUploadDto,
  CreateCertificateDto,
  FileUploadDto,
  FileUploadResponseDto,
  SertificateDto,
  StandartDto,
  UserSettingsDto,
} from './IProfileProvider';

const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:1337/app';

@injectable()
export class ApiProfileProvider implements IProfileProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  public getCourses(): AsyncGenerator<CourseListItemDto[]> {
    return this.generator('profile', 'getCourses');
  }

  public getEvents(): AsyncGenerator<EventListItemDto[]> {
    return this.generator('profile', 'getEvents');
  }

  public async *getNews(): AsyncGenerator<NewsListItemDto[]> {
    const filters = {
      page: 1,
      count: 9,
    };
    while (true) {
      const result: any[] = await this.apiClient.request(
        'profile',
        'getNews',
        filters
      );
      if (!result.length) {
        return;
      }
      yield result.map(serializeListItem);
      filters.page += 1;
    }
  }

  public getSertificates(): AsyncGenerator<SertificateDto[]> {
    return this.generator('profile', 'getCertificates');
  }

  public async *getStandarts(): AsyncGenerator<StandartDto[]> {
    return [];
  }

  public getUserInfo(): Promise<ProfileDto> {
    return this.apiClient.request('profile', 'getUserInfo', null);
  }

  public async getSettings(): Promise<UserSettingsDto> {
    return this.apiClient.request('profile', 'getSettings', null);
  }

  public async saveSettings(
    category: string,
    data: Record<string, any>
  ): Promise<void> {
    return this.apiClient.request('profile', 'saveSettings', {
      category,
      data,
    });
  }

  public async uploadAvatar(data: AvatarUploadDto): Promise<AvatarResponseDto> {
    const result = await upload<any>(`${API_URL}/avatar`, data);
    return { avatarUrl: result.result.url };
  }

  public async uploadCertificate(
    file: FileUploadDto
  ): Promise<FileUploadResponseDto> {
    const result = await upload<{ result: FileUploadResponseDto }>(
      `${API_URL}/file`,
      file
    );
    return result.result;
  }
  public saveCertificate(data: CreateCertificateDto): Promise<void> {
    return this.apiClient.request('profile', 'saveCertificate', data);
  }

  private async *generator<T>(
    scope: string,
    method: string
  ): AsyncGenerator<T[]> {
    const filters = {
      page: 1,
      count: 9,
    };
    while (true) {
      const result: T[] = await this.apiClient.request(scope, method, filters);
      if (!result.length) {
        return;
      }
      yield result;
      filters.page += 1;
    }
  }
}

const getAuthTokens = () => {
  const json = localStorage.getItem('auth');
  if (json) {
    return JSON.parse(json);
  }
};

async function upload<T>(url: string, data: FileUploadDto): Promise<T> {
  const form = new FormData();
  form.append('file', data.file);
  const req = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAuthTokens().access}`,
    },
    body: form,
  });
  const result = await req.json();
  if (req.status !== 200) {
    throw new Error(result.error);
  }
  return result;
}
