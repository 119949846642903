import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import CourseListItemDto from 'infrastructure/models/courses/CourseListItemDto';
import EventListItemDto from 'infrastructure/models/events/EventListItemDto';
import NewsListItemDto from 'infrastructure/models/news/NewsListItemDto';
import { combineReducers, Reducer } from 'redux';
import { coursesReducer } from 'store/modules/courses';
import { eventsReducer } from 'store/modules/events';
import { newsReducer } from 'store/modules/news';
import { UiState } from 'store/modules/ui/types';
import { UserState } from 'store/modules/user/reducer';

import auth, { AuthState } from 'store/modules/auth/reducer';
import init, { InitState } from 'store/modules/init/reducer';
import lang, { LangState } from 'store/modules/lang/reducer';
import profile, { ProfileState } from 'store/modules/profile/reducer';
import signUp, { SignUpState } from 'store/modules/signUp/reducer';
import ui from 'store/modules/ui/reducer';
import { user } from 'store/modules/user';
import { ListState } from 'store/types';

export interface AppState {
  router: RouterState;
  ui: UiState;
  lang: LangState;
  init: InitState;
  auth: AuthState;
  signUp: SignUpState;
  news: ListState<NewsListItemDto>;
  events: ListState<EventListItemDto>;
  courses: ListState<CourseListItemDto>;
  profile: ProfileState;
  user: UserState;
}

export default function createRootReducer(history: History): Reducer {
  return combineReducers<AppState>({
    router: connectRouter(history),
    ui,
    lang,
    init,
    auth,
    signUp,
    news: newsReducer,
    events: eventsReducer,
    courses: coursesReducer,
    profile,
    user,
  });
}
