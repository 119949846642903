import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import { clns } from 'helpers/strings';
import React, { useCallback } from 'react';

import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';

import styles from './Input.module.sass';

interface InputProps extends WidgetDataProps {
  label: string;
  type?: string;
  required?: boolean;
  className?: string;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  onChange,
  value,
  error,
  type,
  required,
  className,
  label,
  placeholder,
}) => {
  const handleChange = useCallback(
    ({
      target: { value: currentValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(currentValue);
    },
    [onChange]
  );

  return (
    <WidgetWrapper
      label={label}
      error={error}
      required={required}
      className={className}
    >
      <input
        className={clns([styles.input, error && styles.error])}
        onChange={handleChange}
        onBlur={handleChange}
        value={value}
        placeholder={placeholder || label || ''}
        type={type || 'text'}
      />
    </WidgetWrapper>
  );
};

export default withFormConnected<InputProps>(Input);
