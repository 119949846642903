import { useEffect, useState } from 'react';
import NewsListItemDto from '../infrastructure/models/news/NewsListItemDto';
import INewsProvider, {
  SINewsProvider,
} from '../infrastructure/providers/NewsProvider/INewsProvider';
import container from '../ioc';

const provider = () => container.get<INewsProvider>(SINewsProvider);

export default function useLastNews(): NewsListItemDto[] {
  const [news, setNews] = useState<NewsListItemDto[]>([]);

  useEffect(() => {
    provider()
      .getList({ page: 1, count: 3 })
      .then(({ list }) => setNews(list));
  }, []);

  return news;
}
