import { inject, injectable } from 'inversify';
import IApiClient, { SIApiClient } from '../../ApiClient/IApiClient';
import IOptionsProvider, { Options } from './IOptionsProvider';

@injectable()
export default class ApiOptionsProvider implements IOptionsProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  constructor() {}

  public getCategories(): Promise<Options> {
    return this.apiClient.request('options', 'getCategories', null);
  }

  public getCities(): Promise<Options> {
    return this.apiClient.request('options', 'getCities', null);
  }

  public getSpecialities(): Promise<Options> {
    return this.apiClient.request('options', 'getSpecialities', null);
  }
}
