import Schema from '@project-m/schemas';
import Fields from '@project-m/schemas/dist/fields';
import { DependingTypes } from '@project-m/schemas/dist/types';

const UpdatePasswordSchema = Schema.create({
  password: Fields.password().required(),
  repeatPassword: Fields.password()
    .required()
    .depending('password', DependingTypes.EQUALS, 'Парлі не співпадають'),
});

export default UpdatePasswordSchema;
