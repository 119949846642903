import { UserActions } from 'store/modules/user';
import { StoreApi } from 'store/types';
import IProfileProvider, {
  SIProfileProvider,
} from '../../../infrastructure/providers/ProfileProvider/IProfileProvider';
import container from '../../../ioc';
import { setProfile } from './actions';
import { ProfileActionTypes } from './constants';
import { LoadProfileAction } from './types';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const loadHandler = (store: StoreApi, action: LoadProfileAction) => {
  provider()
    .getUserInfo()
    .then(data => store.dispatch(setProfile(data)))
    // tslint:disable-next-line:no-console
    .catch(e => console.log(e.message));

  provider()
    .getSettings()
    .then(data => store.dispatch(UserActions.setData(data)))
    // tslint:disable-next-line:no-console
    .catch(e => console.log(e.message));
};

const profileHandlers = {
  [ProfileActionTypes.LOAD]: loadHandler,
};

export default profileHandlers;
