import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NewsListItemDto from '../../../infrastructure/models/news/NewsListItemDto';
import INewsProvider, {
  SINewsProvider,
} from '../../../infrastructure/providers/NewsProvider/INewsProvider';
import container from '../../../ioc';
import { isAuthorized } from '../../../store/modules/auth/selectors';

import ListItem from '../ListItem';

import { clns } from '../../../helpers/strings';

import styles from './NewsItem.module.sass';

export interface NewsItemProps {
  newsItem: NewsListItemDto;
  className?: string;
}

const provider = () => container.get<INewsProvider>(SINewsProvider);

const NewsItem: React.FC<NewsItemProps> = ({ newsItem, className }) => {
  const { image, name, createdAt, slug, id, liked } = newsItem;
  const [localLiked, setLocalLiked] = useState<boolean>(liked);
  const authorized = useSelector(isAuthorized);

  return (
    <ListItem
      link={`/news/${slug}`}
      className={clns([styles.newsItem, className])}
      action={
        authorized
          ? () => {
              provider()
                .like(id, !localLiked)
                .then(() => setLocalLiked(!localLiked));
            }
          : undefined
      }
      filled={localLiked}
    >
      <div className={styles.newsItem__top}>
        <img src={image} alt={name} className={styles.newsItem__image} />
      </div>
      <div className={styles.newsItem__content}>
        <div className={styles.newsItem__headline}>{name}</div>
        <div className={styles.newsItem__separator} />
        <div className={styles.newsItem__date}>{createdAt}</div>
      </div>
    </ListItem>
  );
};

export default NewsItem;
