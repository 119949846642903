import IOptionsProvider, {
  SIOptionsProvider,
} from 'infrastructure/providers/OptionsProvider/IOptionsProvider';
import {countries, genderChoices, jobSpecializations} from 'constants/data';

import Fields from '@project-m/schemas/dist/fields';
import Schema from '@project-m/schemas';
import container from 'ioc';

// @ts-ignore
const dataJSON = JSON.parse(localStorage.getItem('data'));

const provider = () => container.get<IOptionsProvider>(SIOptionsProvider);

const MainSettingsSchema = Schema.create({
  name: Fields.text().required(),
  email: Fields.any(),
  password: Fields.password(),
  surname: Fields.text().required(),
  patronymic: Fields.text().required(),
  gender: Fields.choice(dataJSON.genderChoices || genderChoices).required(),
  dateOfBirth: Fields.date().required(),
  specialization: Fields.choice([], () => provider().getSpecialities()),
  country: Fields.choice(dataJSON.countries || countries),
  city: Fields.choice([], () => provider().getCities()),
});

export default MainSettingsSchema;
