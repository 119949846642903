import { UserActionTypes } from 'store/modules/user/index';
import { PayloadAction } from 'store/types';

export interface UserState {
  main: Record<string, any>;
  education: Record<string, any>;
  postgraduate: Record<string, any>;
  experience: Record<string, any>;
  teaching: Record<string, any>;
  innovative: Record<string, any>;
}

const initialState: UserState = {
  main: {},
  education: {},
  postgraduate: {},
  experience: {},
  teaching: {},
  innovative: {},
};

export default function user(
  state: UserState = initialState,
  action: PayloadAction
): UserState {
  if (action.type === UserActionTypes.SET_DATA) {
    return { ...state, ...action.payload };
  }

  return state;
}
