import { AnswersHistoryModel } from 'components/pages/Courses/CourseLections/blocks/CourseTesting/CourseTestingProgress';
import { TestingTimeModel } from 'components/pages/Courses/CourseLections/blocks/CourseTesting/index';
import CourseLectionsContext from 'components/pages/Courses/CourseLections/CourseLectionsContext';
import { StepTypes } from 'components/pages/Courses/CourseLections/state';
import Button from 'components/shared/Button';
import ProgressCircle from 'components/shared/ProgressCircle';
import { timeDifference } from 'helpers/time';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import styles from './CourseTesting.module.sass';

interface CourseTestingFinishProps {
  stats: AnswersHistoryModel;
  time: TestingTimeModel;
}

const CourseTestingFinish: React.FC<CourseTestingFinishProps> = ({
  stats,
  time,
}) => {
  const { handleNext, handleTestPassed, handleStepChange, last } = useContext(
    CourseLectionsContext
  );
  const rightAnswers = useMemo(
    () => Object.values(stats).filter(item => item.status).length,
    [stats]
  );
  const allAnswers = useMemo(() => Object.values(stats).length, [stats]);
  const percentage = (rightAnswers * 100) / allAnswers;
  const isPassed = percentage >= 70;
  const buttonText = isPassed
    ? last
      ? 'Закінчити курс'
      : 'Наступна лекція'
    : 'Пройти курс ще раз';

  useEffect(() => {
    if (isPassed) {
      handleTestPassed();
    }
  }, [percentage]);

  const handleButtonClick = useCallback(() => {
    if (isPassed) {
      handleNext();
    } else {
      handleStepChange(StepTypes.WATCHING);
    }
  }, [percentage]);

  return (
    <>
      <div className={styles.main}>Ваш результат</div>
      <div className={styles.content}>
        <div className={styles.stats}>
          <ProgressCircle percentage={percentage} withNumber />
          <div className={styles.statsList}>
            <div className={styles.statsItem}>
              Правильних відповідей: <span>{rightAnswers}</span>
            </div>
            <div className={styles.statsItem}>
              Неправильних відповідей: <span>{allAnswers - rightAnswers}</span>
            </div>
            <div className={styles.statsItem}>
              Час проходження:{' '}
              <span>{timeDifference(time.start!, time.finish!)}</span>
            </div>
          </div>
        </div>
      </div>
      <Button filled color={'lightBlue'} onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </>
  );
};

export default CourseTestingFinish;
