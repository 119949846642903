import { clns } from 'helpers/strings';
import IProfileProvider, {
  SIProfileProvider,
} from 'infrastructure/providers/ProfileProvider/IProfileProvider';
import container from 'ioc';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAvatar } from 'store/modules/profile/actions';
import { avatarSelector } from 'store/modules/profile/selectors';
import styles from '../User.module.sass';

const provider = () => container.get<IProfileProvider>(SIProfileProvider);

const AvatarField: React.FC = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const avatar = useSelector(avatarSelector);

  const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    provider()
      .uploadAvatar({ file: e.target.files![0] })
      .then(data => dispatch(setAvatar(data.avatarUrl)))
      .finally(() => setUploading(false));
  }, []);

  return (
    <label className={styles.user__left}>
      <input type={'file'} onChange={handleUpload} />
      <div className={clns([styles.overlay, uploading && styles.uploading])}>
        {uploading ? <div className={styles.loader} /> : '+'}
      </div>
      <img src={avatar} alt={'user logo'} className={styles.user__pic} />
    </label>
  );
};

export default AvatarField;
