import { SchemaDataType } from '@project-m/schemas/dist/types';
import Form from 'components/forms/Form';
import Input from 'components/forms/Widgets/Input';
import Select from 'components/forms/Widgets/Select';
import { CourseFilters } from 'infrastructure/models/courses/common';
import React, { useCallback } from 'react';
import CoursesFiltersSchema from 'schemas/CoursesFiltersSchema';

interface FiltersProps {
  filters: CourseFilters;
  setFilters: (filters: CourseFilters) => void;
}

const Filters: React.FC<FiltersProps> = ({ filters, setFilters }) => {
  const handleChange = useCallback(
    (data: SchemaDataType<CourseFilters>) => {
      setFilters(CoursesFiltersSchema.clearDefaultValues(data.value));
    },
    [setFilters]
  );
  return (
    <Form
      schema={CoursesFiltersSchema}
      value={filters}
      errors={null}
      onChange={handleChange}
    >
      <Form.Row>
        <Form.Col>
          <Select.Connected name={'occupation'} label={'Спеціальність'} />
        </Form.Col>
        <Form.Col>
          <Select.Connected name={'type'} label={'Тип лекції'} />
        </Form.Col>
      </Form.Row>
    </Form>
  );
};

export default Filters;
