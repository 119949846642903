import { History } from 'history';
import { createStore as _createStore, Store } from 'redux';
import createMiddleware from 'store/middleware';
import createRootReducer from 'store/reducer';

export default function createStore(
  initialState = {},
  history: History
): Store {
  return _createStore(
    createRootReducer(history),
    initialState,
    createMiddleware(history)
  );
}
