import GoToEventSchema, {GoToEventValueModel} from 'schemas/GoToEventSchema';
import IEventsProvider, {
  SIEventsProvider,
} from 'infrastructure/providers/EventsProvider/IEventsProvider';
import React, {useCallback, useState} from 'react';

import {EventDateModel} from 'infrastructure/models/events/EventDto';
import Form from 'components/forms/Form';
import {Id} from '@project-m/core';
import Input from 'components/forms/Widgets/Input';
import {SchemaDataType} from '@project-m/schemas/dist/types';
import Select from 'components/forms/Widgets/Select';
import container from 'ioc';

const provider = () => container.get<IEventsProvider>(SIEventsProvider);

interface RegisterFormProps {
  id: any;
  dates: EventDateModel[];
  handleRegistered: (param: boolean) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  id,
  dates,
  handleRegistered,
}) => {
  const [formData, setFormData] = useState<any>({
    value: {
      cityId: '',
      email: '',
      id: Math.floor(Math.random() * 9000),
      name: '',
      phone: '',
      surname: '',
    },
    errors: null,
  });

  const handleSubmit = useCallback(() => {
    console.log('-->', formData.value);
    provider()
      .register(id, formData.value)
      .then(() => handleRegistered(true));
  }, [id, formData]);

  const handleSelect = useCallback(
    (placeId: number) => {
      console.log(placeId, formData.value);
      setFormData({
        errors: formData.errors,
        value: {...formData.value, cityId: placeId},
      });
    },
    [formData]
  );

  return (
    <Form
      schema={GoToEventSchema}
      value={formData.value}
      errors={formData.errors}
      onChange={setFormData}
      onSubmit={handleSubmit}
    >
      <Form.Row>
        <Form.Col>
          <Input.Connected name={'name'} label={"Ім'я"} />
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'surname'} label={'Прізвище'} />
        </Form.Col>
        <Form.Col>
          <Select
            label={'Місто'}
            value={formData.value.cityId}
            error={formData.errors && formData.errors.cityId}
            onChange={handleSelect}
            choices={dates.map((date) => [date.place_id, date.place])}
            required={true}
          />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Input.Connected name={'email'} label={'Email'} />
        </Form.Col>
        <Form.Col>
          <Input.Connected name={'phone'} label={'Телефон'} />
        </Form.Col>
      </Form.Row>
      <Form.Button />
    </Form>
  );
};

export default RegisterForm;
