import IApiClient, {SIApiClient} from '../../ApiClient/IApiClient';
import IAuthNotifiable, {SIAuthNotifiable} from './IAuthNotifiable';
import {inject, injectable} from 'inversify';

import IUserProvider from './IUserProvider';
import RegistrationModel from '@project-m/core/entities/auth/RegistrationModel';
import axios from 'axios';

interface AuthResponse {
  access: string;
  reset: string;
}

const sendEmail = async (email: any) => {
  try {
    const res = await axios.post(
      `http://cme-cpd.com.ua:1337/admin/api/mailWithRegistr`,
      {email}
    );

    console.log('welcome!');
  } catch (error) {
    console.log(error);
  }
};

@injectable()
export default class ApiUserProvider implements IUserProvider {
  @inject(SIApiClient) private apiClient!: IApiClient;
  @inject(SIAuthNotifiable) private authObserver!: IAuthNotifiable;
  public async logOut(): Promise<void> {
    this.removeAuthTokens();
    this.authObserver.notify(false);
  }

  public async login(email: string, password: string): Promise<void> {
    const result: AuthResponse = await this.apiClient.request(
      'users',
      'login',
      {
        email,
        password,
      }
    );
    this.saveAuthTokens(result);
    this.authObserver.notify(true);
  }

  public async register(model: any): Promise<void> {
    sendEmail(model.email);
    const result: AuthResponse = await this.apiClient.request(
      'users',
      'register',
      {
        ...model,
      }
    );

    this.saveAuthTokens(result);
    this.authObserver.notify(true);
  }

  public async resetPassword(email: string): Promise<void> {
    await this.apiClient.request('users', 'resetPassword', email);
  }

  public async continuePasswordReset(
    token: string,
    password: string
  ): Promise<void> {
    await this.apiClient.request('users', 'continuePasswordReset', {
      token,
      password,
    });
  }

  private saveAuthTokens(tokens: AuthResponse) {
    window.localStorage.setItem('auth', JSON.stringify(tokens));
  }

  private removeAuthTokens() {
    window.localStorage.setItem('auth', '');
  }
}
