import React from 'react';
import { clns } from '../../../helpers/strings';
import ContentBlock, { ContentBlockProps } from '../ContentBlock';
import HTMLContent from '../HTMLContent';

import styles from './CountBlock.module.sass';

interface CountBlockProps extends ContentBlockProps {
  currentNumber: number;
  quantity: number;
  dark?: boolean;
}

const CountBlock: React.FC<CountBlockProps> = ({
  currentNumber,
  quantity,
  dark,
  ...rest
}) => {
  return (
    <div className={clns([styles.countBlock, dark && styles.countBlock_dark])}>
      <div className={styles.countBlock__left}>
        <div className={styles.countBlock__counter}>
          {currentNumber}/{quantity}
        </div>
      </div>
      <div className={styles.countBlock__right}>
        <ContentBlock {...rest} />
      </div>
    </div>
  );
};

export default CountBlock;
