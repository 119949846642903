import { PayloadAction } from 'store/types';
import { SignUpValueModel } from '../../../schemas/SignUpSchema';
import signUpActionTypes from './constants';

export interface SignUpState {
  step: number;
  data: SignUpValueModel | null;
}

const initialState = {
  step: 0,
  data: null,
};

export default function signUp(
  state: SignUpState = initialState,
  action: PayloadAction
): SignUpState {
  switch (action.type) {
    case signUpActionTypes.CHANGE_STEP:
      return { ...state, step: action.payload };
    case signUpActionTypes.UPDATE_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
