import React from 'react';

import { clns } from '../../../helpers/strings';

import styles from './IconInfo.module.sass';

interface IconInfoProps {
  direction: 'row' | 'column';
  icon: string;
  text: string;
  className?: string;
}

const IconInfo: React.FC<IconInfoProps> = ({
  direction,
  icon,
  text,
  className,
}) => {
  return (
    <div
      className={clns([
        styles.iconInfo,
        styles[`iconInfo_${direction}`],
        className,
      ])}
    >
      <div className={styles.iconInfo__icon}>
        <img src={icon} alt={'icon'} className={styles.iconInfo__image} />
      </div>
      <div className={styles.iconInfo__text}>{text}</div>
    </div>
  );
};

export default IconInfo;
