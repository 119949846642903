import { clns } from 'helpers/strings';
import range from 'lodash/range';
import React, { useCallback } from 'react';

import styles from './Pagination.module.sass';

interface PaginationProps {
  page: number;
  pages: number;
  goToPage: (page: number) => void;
}

interface PaginationItemProps {
  page: number;
  active: boolean;
  goToPage: (page: number) => void;
  disabled?: boolean;
}

const PaginationItem: React.FC<PaginationItemProps> = ({
  page,
  active,
  goToPage,
  children,
  disabled,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled && !active) {
      goToPage(page);
    }
  }, [page, disabled, active, goToPage]);
  return (
    <div
      className={clns([
        styles.item,
        active && styles.active,
        disabled && styles.disabled,
      ])}
      onClick={handleClick}
    >
      {children || page}
    </div>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  pages,
  page: currentPage,
  goToPage,
}) => {
  return (
    <div className={styles.wrapper}>
      <PaginationItem
        page={currentPage - 1}
        active={false}
        goToPage={goToPage}
        disabled={currentPage === 1}
      >
        {'<'}
      </PaginationItem>
      {range(pages).map(page => {
        const actualPage = page + 1;
        return (
          <PaginationItem
            page={actualPage}
            active={currentPage === actualPage}
            goToPage={goToPage}
            key={actualPage}
          />
        );
      })}
      <PaginationItem
        page={currentPage + 1}
        active={false}
        goToPage={goToPage}
        disabled={currentPage === pages}
      >
        {'>'}
      </PaginationItem>
    </div>
  );
};

export default Pagination;
