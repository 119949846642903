import {
  ArrayField,
  BaseField,
  ChoiceField,
  DateRangeField,
  FilesField,
  NumberRangeField,
} from '@project-m/schemas/dist/fields';
import { FieldError } from '@project-m/schemas/dist/types';
import { SchemaDataType } from '@project-m/schemas/dist/types';
import React, { useCallback, useMemo } from 'react';

import FormContext from 'contexts/FormContext';
import { ComponentType, useContext } from 'react';

export interface WidgetDataProps<T = any> {
  value: T;
  error: FieldError;
  onChange: (value: T) => void;
  onUpdate?: (value: SchemaDataType<T>) => void;
  required?: boolean;
}

interface FormWrapperProps {
  name: string;
}

type KeysToExclude = keyof WidgetDataProps | 'choices' | 'schema' | 'range';
type ConnectedComponent<T> = React.FC<
  Omit<T, KeysToExclude> & FormWrapperProps
>;
type IConnected<T> = React.FC<WidgetDataProps & T> & {
  Connected: ConnectedComponent<T>;
};

export default function withFormConnected<T>(
  Component: ComponentType<WidgetDataProps & T>
) {
  const Connected: ConnectedComponent<T> = ({ name, ...props }) => {
    const { value, errors, fields, onChange, onUpdate } = useContext(
      FormContext
    );
    const field = fields[name] as BaseField;
    const additionalProps = useMemo(() => {
      if (field instanceof ChoiceField) {
        return { choices: field.getChoices.bind(field) };
      }

      if (field instanceof ArrayField) {
        return { schema: field.child };
      }

      if (field instanceof DateRangeField) {
        return {
          range: {
            from: (field as DateRangeField).from,
            to: (field as DateRangeField).to,
          },
        };
      }

      if (field instanceof NumberRangeField) {
        return {
          range: { min: field.min, max: field.max },
        };
      }

      if (field instanceof FilesField) {
        return {
          multiple: field.multi,
          accept: field.accept,
        };
      }

      return {};
    }, []);

    const handleChange = useCallback(
      (currentValue: any) => {
        onChange(name, currentValue);
      },
      [onChange]
    );

    const handleUpdate = useCallback(
      (data: SchemaDataType<T>) => {
        onUpdate(name, data);
      },
      [onUpdate]
    );

    return (
      // @ts-ignore
      <Component
        {...props}
        {...additionalProps}
        value={value[name]}
        error={errors && (errors[name] as FieldError)}
        onChange={handleChange}
        onUpdate={handleUpdate}
        required={field.isRequired}
      />
    );
  };

  const Wrapped: IConnected<T> = Component as IConnected<T>;
  Wrapped.Connected = Connected;

  return Wrapped;
}
