import WidgetWrapper from 'components/forms/Widgets/WidgetWrapper';
import withFormConnected, {
  WidgetDataProps,
} from 'components/hocs/withFormConnected';
import React, { useCallback, useEffect, useState } from 'react';
import { Range as InputRange } from 'react-range';

import styles from './NumberRange.module.sass';

interface RangeProps extends WidgetDataProps {
  range: { min: number; max: number };
  label: string;
}

const NumberRange: React.FC<RangeProps> = ({
  range: { min, max },
  value,
  required,
  error,
  onChange,
  label,
}) => {
  const [localValue, setLocalValue] = useState(value || { min, max });

  const handleChange = useCallback(
    (values: number[]) => setLocalValue({ min: values[0], max: values[1] }),
    []
  );

  const handleFinalChange = useCallback(
    (values: number[]) => onChange({ min: values[0], max: values[1] }),
    [onChange]
  );

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <WidgetWrapper label={label} error={error} required={required}>
      <div className={styles.range__slider}>
        <InputRange
          max={max}
          min={min}
          onChange={handleChange}
          onFinalChange={handleFinalChange}
          values={[localValue.min, localValue.max]}
          renderThumb={({ props: { style, ...props } }) => (
            <div {...props} className={styles.range__thumb} style={style} />
          )}
          renderTrack={({ props: { style, ...props }, children }) => (
            <div {...props} className={styles.range__track} style={style}>
              {children}
            </div>
          )}
        />
      </div>
      <div className={styles.range__labels}>
        <div className={styles.range__label}>{min}</div>
        <div className={styles.range__label}>
          {localValue.min} - {localValue.max}
        </div>
        <div className={styles.range__label}>{max}</div>
      </div>
    </WidgetWrapper>
  );
};

export default withFormConnected(NumberRange);
