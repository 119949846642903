import DateInput from 'components/forms/Widgets/DateInput';
import Form from 'components/forms/Form';
import InformalEducationSchema from 'schemas/InformalEducationSchema';
import Input from 'components/forms/Widgets/Input';
import List from 'components/forms/Widgets/List';
import React from 'react';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';

const PostgraduateSettings = () => {
  const { value, errors, onChange, onSave } = useUserSettings(
    'postgraduate',
    InformalEducationSchema
  );

  return (
    <Form
      schema={InformalEducationSchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >

    <List.Connected
        name={'certificate'}
        label={'Сертификат Спеціаліста'}
        addMoreText={''}
      >
        <Form.Row>
        <Form.Col>
            <DateInput.Connected name={'beginDate'} label={'Отримав'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Від'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дійсний до'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'dayCount'} label={'Номер'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'specialization'} label={'Основна спеціалізація'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'country'} label={'Країна проведення'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>

      <List.Connected
        name={'postgraduate'}
        label={'Післядипломна освіта'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected
              name={'type'}
              label={'Форма післядипломного навчання'}
            />
          </Form.Col>
          <Form.Col width={(100 / 3) * 2}>
            <Input.Connected name={'name'} label={'Назва заходу'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'place'} label={'Місце проведення'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дата закінчення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'country'} label={'Країна проведення'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'dayCount'} label={'Тривалість в днях'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'category'} label={'Категорія заходу'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'trainings'}
        label={'Тренінги та тематичні навчання'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected
              name={'type'}
              label={'Форма післядипломного навчання'}
            />
          </Form.Col>
          <Form.Col width={(100 / 3) * 2}>
            <Input.Connected name={'name'} label={'Назва заходу'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'place'} label={'Місце проведення'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дата закінчення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'country'} label={'Країна проведення'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'dayCount'} label={'Тривалість в днях'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'category'} label={'Категорія заходу'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'remote'}
        label={'Дистанційна форма навчання'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'name'} label={'Назва навчального курсу'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'resourceName'}
              label={'Назва навчального ресурсу'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дата закінчення'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'hours'} label={'Кількість годин'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'points'} label={'Кількість балів'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'language'} label={'Мова курсу'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'conference'}
        label={'Наукові конференції, симпозіуми, з’їзди, конгреси'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={50}>
            <Input.Connected name={'name'} label={'Назва заходу'} />
          </Form.Col>
          <Form.Col width={50}>
            <Input.Connected name={'reportTitle'} label={'Назва доповіді'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input.Connected name={'organizer'} label={'Назва заходу'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected
              name={'participationType'}
              label={'Форма участі'}
            />
          </Form.Col>
          <Form.Col>
            <Select.Connected name={'type'} label={'Тип заходу'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'city'} label={'Місто проведення'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'hours'} label={'Кількість годин'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'startDate'} label={'Дата початку'} />
          </Form.Col>
          <Form.Col>
            <DateInput.Connected name={'endDate'} label={'Дата закінчення'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'category'} label={'Категорія заходу'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'points'} label={'Кількість балів'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'publications'}
        label={'Публікації в наукових виданнях'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={50}>
            <Input.Connected
              name={'description'}
              label={'Бібліографічний опис видання'}
            />
          </Form.Col>
          <Form.Col width={50 / 3}>
            <Select.Connected name={'type'} label={'Тип видання'} />
          </Form.Col>
          <Form.Col width={50 / 3}>
            <Select.Connected name={'language'} label={'Мова публікації'} />
          </Form.Col>
          <Form.Col width={50 / 3}>
            <Select.Connected name={'year'} label={'Рік публікації'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <Form.Button />
    </Form>
  );
};

export default PostgraduateSettings;
