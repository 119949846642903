import { Container } from 'inversify';
import 'reflect-metadata';
import injectApi from './api';
import injectMock from './mock';

const container = new Container();

switch (process.env.REACT_APP_CORE_ENV) {
  case 'firebase':
    // tslint:disable-next-line:no-console
    console.error('Firebase api is not supported already');
    break;
  case 'api':
    injectApi(container);
    break;
  default:
    injectMock(container);
    break;
}

export default container;
