import ListResponse from 'infrastructure/models/common/ListResponse';
import { AppState } from 'store/reducer';
import {
  AfterMiddlewareCallback,
  ListActions,
  ListSelectors,
  ListState,
  PayloadAction,
} from 'store/types';

interface ProviderFilters {
  page: number;
  count: number;
  [x: string]: any;
}

interface ProviderType<T> {
  getList(filters: ProviderFilters): Promise<ListResponse<T>>;
}

type ProviderCreator<T> = () => ProviderType<T>;

export default function createListModule<T = any, F = Record<string, any>>(
  name: string,
  provider: ProviderCreator<T>
) {
  const DISPLAY_NAME = name.toUpperCase();
  const actionTypes = {
    GET_LIST: `[${DISPLAY_NAME}] Get list`,
    SET_LIST: `[${DISPLAY_NAME}] Set list`,
    SET_PAGE: `[${DISPLAY_NAME}] Set page`,
    SET_PAGES: `[${DISPLAY_NAME}] Set pages`,
    SET_FILTERS: `[${DISPLAY_NAME}] Set filters`,
    SET_LOADING: `[${DISPLAY_NAME}] Set loading`,
  };

  const actions: ListActions<T> = {
    getList: () => ({ type: actionTypes.GET_LIST }),
    setList: (payload: T[]) => ({ type: actionTypes.SET_LIST, payload }),
    setPage: (payload: number) => ({ type: actionTypes.SET_PAGE, payload }),
    setPages: (payload: number) => ({ type: actionTypes.SET_PAGES, payload }),
    setFilters: (payload: F) => ({
      type: actionTypes.SET_FILTERS,
      payload,
    }),
    setLoading: (payload: boolean) => ({
      type: actionTypes.SET_LOADING,
      payload,
    }),
  };

  const initialState: ListState<T, F> = {
    list: [],
    filters: {} as F,
    page: 1,
    pages: 1,
    count: 9,
    loading: false,
  };

  const reducer = (
    state: ListState<T, F> = initialState,
    action: PayloadAction
  ): ListState<T, F> => {
    switch (action.type) {
      case actionTypes.SET_LIST:
        return { ...state, list: action.payload };
      case actionTypes.SET_PAGE:
        return { ...state, page: action.payload };
      case actionTypes.SET_PAGES:
        return { ...state, pages: action.payload };
      case actionTypes.SET_FILTERS:
        return { ...state, filters: action.payload };
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.payload };
    }

    return state;
  };

  const selectors: ListSelectors<T> = {
    // @ts-ignore
    getList: (state: AppState): T[] => state[name].list,
    // @ts-ignore
    getFilters: (state: AppState): F => state[name].filters,
    // @ts-ignore
    getLoading: (state: AppState): boolean => state[name].loading,
    // @ts-ignore
    getPage: (state: AppState): number => state[name].page,
    // @ts-ignore
    getPages: (state: AppState): number => state[name].pages,
  };

  const getListHandler: AfterMiddlewareCallback = store => {
    // @ts-ignore
    const { page, count, filters } = store.getState()[name] as ListState<T, F>;

    store.dispatch(actions.setLoading(true));

    provider()
      .getList({ page, count, ...filters })
      .then(data => {
        store.dispatch(actions.setPages(data.pages));
        store.dispatch(actions.setList(data.list));
      })
      .finally(() => store.dispatch(actions.setLoading(false)));
  };

  const handlers = {
    [actionTypes.GET_LIST]: getListHandler,
  };

  return { actionTypes, actions, reducer, selectors, handlers };
}
