import ContextProviders from 'components/providers';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components';
import initialize from './initialize';
import * as serviceWorker from './serviceWorker';

initialize().then(({ store, history }) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ContextProviders>
          <App />
        </ContextProviders>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );

  serviceWorker.unregister();
});
