import Form from 'components/forms/Form';
import DateInput from 'components/forms/Widgets/DateInput';
import Input from 'components/forms/Widgets/Input';
import List from 'components/forms/Widgets/List';
import Select from 'components/forms/Widgets/Select';
import useUserSettings from 'hooks/useUserSettings';
import React from 'react';
import ScientificAndInnovativeWorkSchema from 'schemas/ScientificAndInnovativeWorkSchema';

const ScientificAndInnovativeWorkSettings = () => {
  const { value, errors, onChange, onSave } = useUserSettings(
    'innovative',
    ScientificAndInnovativeWorkSchema
  );
  return (
    <Form
      schema={ScientificAndInnovativeWorkSchema}
      value={value}
      errors={errors}
      onChange={onChange}
      onSubmit={onSave}
    >
      <List.Connected
        name={'honoraryTitles'}
        label={'Отримання почесного звання, премії, грамоти'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Select.Connected
              name={'name'}
              label={'Назва почесного звання, премії, грамоти'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <DateInput.Connected name={'date'} label={'Дата отримання'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'patents'}
        label={'Отримання патенту на фаховий винахід або корисну модель'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'name'} label={'Назва патенту'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected name={'authors'} label={'Автори'} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'type'} label={'Тип'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Input.Connected name={'number'} label={'Номер патенту'} />
          </Form.Col>
          <Form.Col>
            <Input.Connected
              name={'bulletinNumber'}
              label={'Номер бюлетеня про видачу патенту'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <DateInput.Connected name={'date'} label={'Дата публікації'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'textbooks'}
        label={'Видання фахового підручника, навчального посібника, монографії'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'name'}
              label={'Бібліографічний опис видання'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'type'} label={'Тип видання'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected name={'language'} label={'Мова публікації'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'recommendations'}
        label={
          'Видання галузевих методичних рекомендацій, інформаційного листка, раціоналізаторської пропозиції'
        }
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col width={100}>
            <Input.Connected
              name={'name'}
              label={'Бібліографічний опис видання'}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'type'} label={'Тип видання'} />
          </Form.Col>
          <Form.Col>
            <Select.Connected name={'language'} label={'Мова публікації'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <List.Connected
        name={'organizations'}
        label={'Членство в організаціях'}
        addMoreText={'Додати'}
      >
        <Form.Row>
          <Form.Col>
            <Select.Connected name={'type'} label={'Тип організації'} />
          </Form.Col>
          <Form.Col>
            <Input.Connected name={'name'} label={'Назва організації'} />
          </Form.Col>
          <Form.Col width={100 / 3 / 2}>
            <Select.Connected name={'startYear'} label={'Рік вступу'} />
          </Form.Col>
        </Form.Row>
      </List.Connected>
      <Form.Button />
    </Form>
  );
};

export default ScientificAndInnovativeWorkSettings;
