import { useCallback, useEffect, useMemo, useState } from 'react';
import useDidUpdate from './useDidUpdate';

function payloadOrEmptyArray<T = any>(arr: T[]): T[] {
  return Array.isArray(arr) ? arr : [];
}

// tslint:disable-next-line:class-name
export interface useLoadMoreReturnType<T, F = any> {
  data: T;
  canLoadMore: boolean;
  loading: boolean;
  loadingMore: boolean;
  empty: boolean;
  filters?: F;
  setFilters?(filters: F): void;
  loadMore(): void;
}

export default function useLoadMore<T>(
  source: () => AsyncGenerator<T[]>,
  sourceDependency: any = '',
  preloaded: T[] = []
): useLoadMoreReturnType<T[]> {
  const generator = useMemo(source, [sourceDependency]);
  const [data, setData] = useState<T[]>(preloaded);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const initialLoading = () => {
    setLoading(true);

    generator
      .next()
      .then(result => {
        setData(payloadOrEmptyArray(result.value));
      })
      .finally(() => setLoading(false));
  };

  const loadMore = useCallback(() => {
    setLoadingMore(true);

    generator
      .next()
      .then(result => {
        if (result.done) {
          setCanLoadMore(false);
        } else {
          setData([...data, ...payloadOrEmptyArray(result.value)]);
        }
      })
      .finally(() => setLoadingMore(false));
  }, [generator, data]);

  useEffect(() => {
    if (!preloaded.length) {
      initialLoading();
    }
  }, []);

  useDidUpdate(() => {
    initialLoading();
  }, [generator]);

  return {
    data,
    canLoadMore,
    loading,
    loadingMore,
    empty: !loading && !data.length,
    loadMore,
  };
}
