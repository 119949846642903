import IUserProvider, {
  SIUserProvider,
} from '../../../infrastructure/providers/UserProvider/IUserProvider';
import {setLoader, setModal} from '../ui/actions';

import RegistrationModel from '@project-m/core/entities/auth/RegistrationModel';
import {SignUpAction} from './types';
import SignUpActionTypes from './constants';
import {StoreApi} from 'store/types';
import container from '../../../ioc';
import {loadProfile} from 'store/modules/profile/actions';
import {push} from 'connected-react-router';
import {signUpDone} from './actions';

const provider = () => container.get<IUserProvider>(SIUserProvider);

const signUp = (store: StoreApi, action: SignUpAction) => {
  store.dispatch(setLoader('global', true));
  const state = store.getState().signUp.data!;
  const model: RegistrationModel = {
    firstName: state.name,
    lastName: state.surname,
    patronymic: state.patronymic,
    email: state.email,
    password: state.password,
    passwordRepeat: state.repeatPassword,
    university: state.university,
    diploma: state.diploma,
    universityEndDate: new Date(state.endYear),
    specialization: state.occupation,
    gender: state.gender,
    city: (state.city as any) as number,
    phone: state.phone,
  };
  provider()
    .register(model)
    .then(() => {
      store.dispatch(signUpDone());
      store.dispatch(setModal('signUp', false));
      store.dispatch(push('/'));
      store.dispatch(loadProfile());
    })
    .catch((e) => alert(e.message))
    .finally(() => store.dispatch(setLoader('global', false)));
};

const signUpHandlers = {
  [SignUpActionTypes.SING_UP]: signUp,
};

export default signUpHandlers;
